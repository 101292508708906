import { exitFormElement } from './misc-objects';

export function createTreatmentElements(instructionObj) {
  const formElementsArray = [];
  const returnTrue = () => true;
  const headObj = {
    formName: instructionObj.formName,
    headingText: instructionObj.heading,
    fieldText: [],
    labelText: [],
    notifyDoctor: true,
    elementType: 'checkbox',
    creationTest: instructionObj.creationTest ? instructionObj.creationTest : returnTrue,
  };
  instructionObj.fields.forEach((field) => {
    headObj.fieldText.push(`${headObj.formName} treatments.${field.treatment}.used`);
    // Create & add labels
    let label = field.treatment;
    if (field.extraText) label = `${label} ${field.extraText}`;
    headObj.labelText.push(label);
    // Decide which fields & labels are generated
    const followObjs = [
      {
        headingText: `Did you have any side effects with ${field.treatment}?`,
        fieldText: `${headObj.formName} treatments.${field.treatment}.side effects`,
        options: [true, false],
        elementType: 'radio',
        creationTest: (formInputs) => formInputs[`${headObj.formName} treatments.${field.treatment}.used`],
      },
      {
        headingText: 'What side effects did you experience?',
        fieldText: `${headObj.formName} treatments.${field.treatment}.side effects details`,
        elementType: 'basic',
        maxRows: 3,
        creationTest: (formInputs) => formInputs[`${headObj.formName} treatments.${field.treatment}.side effects`],
      },
      {
        headingText: `Was ${field.treatment} effective?`,
        fieldText: `${headObj.formName} treatments.${field.treatment}.was effective`,
        options: [true, false],
        elementType: 'radio',
        creationTest: (formInputs) => formInputs[`${headObj.formName} treatments.${field.treatment}.used`],
      },
    ];
    formElementsArray.push(...followObjs);
  });
  const tailObjs = [
    exitFormElement(
      'because these treatments have been ineffective',
      (formInputs) => {
        // This is a hacky solution but whatever, I'll optimise later
        let conditionCount = 0;
        instructionObj.fields.forEach((field) => {
          if (
            formInputs[`${headObj.formName} treatments.${field.treatment}.used`] !== true ||
            (formInputs[`${headObj.formName} treatments.${field.treatment}.used`] &&
              formInputs[`${headObj.formName} treatments.${field.treatment}.was effective`])
          )
            conditionCount += 1;
        });
        return conditionCount === 0;
      },
      `patient already tried treatments (${headObj.labelText.join(', ')}) and said they were not effective`,
    ),
    {
      headingText: 'Have you tried any other treatments?',
      fieldText: `${headObj.formName}.tried other treatments`,
      options: [true, false],
      elementType: 'radio',
      creationTest: instructionObj.creationTest ? instructionObj.creationTest : returnTrue,
    },
    {
      headingText: `${headObj.formName} treatments`,
      subheadingText: "Please add any treatments you've tried",
      fields: {
        prepender: `${headObj.formName} treatments.`,
        slimTextOne: '.used',
        radioOne: '.was effective',
        radioTwo: '.side effects',
        longTextOne: '.side effects details',
      },
      capitalizeHeading: true,
      elementType: 'treatments tried',
      creationTest: (formInputs) => formInputs[`${headObj.formName}.tried other treatments`],
    },
  ];
  formElementsArray.push(...tailObjs);
  formElementsArray.unshift(headObj);
  return formElementsArray;
}

export function createConditionElements(instructionObj) {
  const formElementsArray = [];
  const returnTrue = () => true;
  const headObj = {
    headingText: instructionObj.heading,
    fieldText: [],
    labelText: [],
    selectOne: false,
    notifyDoctor: true,
    elementType: 'checkbox',
    creationTest: instructionObj.creationTest ? instructionObj.creationTest : returnTrue,
  };
  instructionObj.fields.forEach((field) => {
    let followObj = {};
    headObj.labelText.push(field);
    const fieldString = `${instructionObj.appenders[0]}.${field}.`;
    const headObjField = fieldString + instructionObj.appenders[1];
    headObj.fieldText.push(headObjField);
    if (instructionObj.rejectionText) {
      const activityLog = `due to ${field}}`;
      followObj = exitFormElement(
        `${instructionObj.rejectionText[0]} ${field} ${instructionObj.rejectionText[1]}`,
        (formInputs) => formInputs[headObjField],
        activityLog,
      );
    } else {
      followObj = {
        headingText: `Please give details about your ${field}`,
        // This looks weird but is correct
        fieldText: `${fieldString}details`,
        elementType: 'basic',
        maxRows: 3,
        creationTest: (formInputs) => formInputs[headObjField],
      };
    }
    formElementsArray.push(followObj);
  });
  formElementsArray.unshift(headObj);
  return formElementsArray;
}
