import React from 'react';
import { Typography } from '@mui/material';
import { enforceAgeLimits } from '../../../functions/date-conversion';

export function basicInfoElements(userType) {
  return [
    {
      headingText: "Great work! Now before we forget, what's your first name",
      fieldText: 'basic info.first name',
      elementType: 'basic',
      creationTest: (formInputs) =>
        !formInputs['basic info.first form completed'] || userType === 'doctor' || userType === 'nurse',
    },
    {
      headingText: 'And your last name?',
      fieldText: 'basic info.last name',
      elementType: 'basic',
      creationTest: (formInputs) =>
        !formInputs['basic info.first form completed'] || userType === 'doctor' || userType === 'nurse',
    },
    {
      headingText: 'And your sex at birth',
      fieldText: 'basic info.sex',
      options: ['Male', 'Female'],
      elementType: 'radio',
      creationTest: (formInputs) =>
        !formInputs['basic info.first form completed'] || userType === 'doctor' || userType === 'nurse',
    },
  ];
}

export function loginElements(user) {
  return [
    {
      headingText: 'Pre-Approval Complete',
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Based on your answers so far we can prescribe treatment for you.
          <br />
          <br />
          For a doctor to review your answers you&apos;ll need to create an account and complete a few questions about
          your medical history.
          <br />
          <br />
          Once you create an account your progress will be saved.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: () => !user,
    },
    {
      elementType: 'login',
      creationTest: () => !user,
    },
  ];
}

export function birthDateFormElements(userType) {
  return [
    {
      headingText: "Now what's your date of birth?",
      fieldText: 'basic info.dob',
      elementType: 'date',
      creationTest: (formInputs) =>
        !formInputs['basic info.first form completed'] || userType === 'doctor' || userType === 'nurse',
    },
    {
      fieldText: (
        <Typography variant="body1" align="center" gutterBottom>
          We&apos;re sorry, you must be over 18 years old to use Candor.
          <br />
          <br />
          We recommend discussing your situation with your regular GP.
        </Typography>
      ),
      elementType: 'exit',
      creationTest: (formInputs) => enforceAgeLimits(formInputs['basic info.dob']) === 'under limit',
      activityLog: 'basic information - under age limit',
    },
  ];
}

export function ageLimitExitFormElement(ageLimit) {
  return {
    fieldText: (
      <Typography variant="body1" align="center" gutterBottom>
        We&apos;re sorry, unfortunately you are over the age limit to use Candor for this particular treatment.
        <br />
        <br />
        We recommend discussing your situation with your regular GP.
      </Typography>
    ),
    elementType: 'exit',
    creationTest: (formInputs) => enforceAgeLimits(formInputs['basic info.dob'], ageLimit) === 'over limit',
    activityLog: 'patient is over age limit',
  };
}

export function pregnancyFormElement() {
  return {
    headingText: 'Are you currently pregnant or breastfeeding?',
    fieldText: 'general health.pregnant or breastfeeding',
    options: [true, false],
    elementType: 'radio',
    creationTest: (formInputs) => formInputs['basic info.sex'] === 'Female',
  };
}

export function pregnancyPlanningFormElement() {
  return {
    headingText: 'Are you planning on a pregnancy in the next 3 months?',
    fieldText: 'general health.planning pregnancy soon',
    options: [true, false],
    elementType: 'radio',
    creationTest: (formInputs) => formInputs['basic info.sex'] === 'Female',
  };
}

export function heightAndWeightFormElements() {
  return [
    {
      headingText: "What's your height in centimeters?",
      fieldText: 'general health.height',
      elementType: 'basic',
      creationTest: () => true,
    },
    {
      headingText: "What's your weight in kilograms?",
      fieldText: 'general health.weight',
      elementType: 'basic',
      creationTest: () => true,
    },
  ];
}

export function exitFormElement(reason, creationTest, activityLog) {
  return {
    fieldText: (
      <Typography variant="body1" align="center" gutterBottom>
        We&apos;re sorry, {reason} we&apos;re unable to provide a solution for you at this time.
        <br />
        <br />
        We recommend discussing your situation with your regular GP.
      </Typography>
    ),
    elementType: 'exit',
    creationTest,
    activityLog,
  };
}

export function createCardsConditionElements(instructionObj) {
  const formElementsArray = [];
  const returnTrue = () => true;
  const capitalize = (str) => {
    const splitStr = str.toLowerCase().split(' ');
    /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  };
  const headObj = {
    headingText: instructionObj.heading,
    fieldText: [],
    labelText: [],
    selectOne: false,
    notifyDoctor: true,
    elementType: 'checkbox',
    creationTest: instructionObj.creationTest ? instructionObj.creationTest : returnTrue,
  };
  instructionObj.fields.forEach((field) => {
    let followObj = {};
    headObj.labelText.push(capitalize(field));
    const fieldString = `${instructionObj.appenders[0]}.${field}.`;
    const headObjField = fieldString + instructionObj.appenders[1];
    headObj.fieldText.push(headObjField);
    let elementType;
    let fieldText = [];
    if (field === 'medicare card') {
      elementType = 'medicare card';
      fieldText = [
        `${fieldString}firstName`,
        `${fieldString}lastName`,
        `${fieldString}number`,
        `${fieldString}irn`,
        `${fieldString}expiry`,
        `${fieldString}using`,
      ];
    } else if (field === 'veterans card') {
      elementType = 'veterans card';
      fieldText = [
        `${fieldString}firstName`,
        `${fieldString}lastName`,
        `${fieldString}fileNumber`,
        `${fieldString}expiry`,
        `${fieldString}using`,
      ];
    } else {
      elementType = 'health card';
      fieldText = [
        `${fieldString}firstName`,
        `${fieldString}lastName`,
        `${fieldString}address1`,
        `${fieldString}address2`,
        `${fieldString}crn`,
        `${fieldString}startDate`,
        `${fieldString}expiry`,
        `${fieldString}using`,
      ];
    }
    followObj = {
      headingText: `Your ${capitalize(field)} Information`,
      // This looks weird but is correct
      elementType,
      concessionCardType: `${field}`,
      fieldText,
      creationTest: (formInputs) => formInputs[headObjField],
    };
    formElementsArray.push(followObj);
  });
  formElementsArray.unshift(headObj);
  return formElementsArray;
}

export function finalFormElements(formName) {
  return [
    {
      headingText: 'Medical Conditions',
      subheadingText: 'Please list any current medical conditions below',
      fieldText: 'medical conditions',
      inputs: ['condition', 'diagnosed'],
      elementType: 'expandable',
      creationTest: () => true,
    },
    {
      headingText: 'If you have any medication allergies please list them below',
      fieldText: 'general health.medication allergies',
      elementType: 'basic',
      maxRows: 3,
      skippable: true,
      notifyDoctor: true,
      creationTest: () => true,
    },
    {
      headingText: 'Treatments & Medications',
      subheadingText: "Please list any treatments or medications you're currently using",
      fieldText: 'used for',
      inputs: ['treatment', 'currently using'],
      elementType: 'expandable',
      creationTest: () => true,
    },
    {
      headingText: 'If you are taking any vitamins or supplements please list them below',
      fieldText: 'general health.vitamins or supplements',
      elementType: 'basic',
      maxRows: 3,
      skippable: true,
      notifyDoctor: true,
      creationTest: () => true,
    },
    {
      headingText: 'Is there anything else you would like your Candor GP to know?',
      fieldText: `${formName}.other notes for gp`,
      options: [true, false],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'Please give details',
      fieldText: `${formName}.other notes for gp details`,
      elementType: 'basic',
      maxRows: 3,
      creationTest: (formInputs) => formInputs[`${formName}.other notes for gp`],
    },
    ...createCardsConditionElements({
      heading: 'Do you have any of the following?',
      fields: [
        'medicare card',
        'health care card',
        'veterans card',
        'pensioner concession card',
        'commonwealth senior health card',
      ],
      appenders: ['concession cards', 'using'],
      creationTest: () => true,
    }),
    {
      headingText: 'Your Contact Information',
      elementType: 'shipping',
      creationTest: (formInputs) => !formInputs['basic info.first form completed'],
    },
    {
      elementType: 'submit',
      creationTest: () => true,
    },
    {
      elementType: 'payment',
      creationTest: () => true,
    },
  ];
}
