import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, List, ListItem, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { collection, getDocs, query, where } from '@firebase/firestore';
import PropTypes from 'prop-types';

import { db } from '../../firebase-config';
import { useDocument } from '../../hooks/useDocument';
import { capitalizeFirstLetterOfEachWord, formatCamelCase } from '../../utils/constants';

const DEBOUNCE_TIME = 1500;
const DEFAULT_STOCK = { inStock: [], outOfStock: [] };

const PREFIX = 'PharmacyStockPreview';

const classes = {
  box: `${PREFIX}-box`,
  listItem: `${PREFIX}-listItem`,
  circularHolder: `${PREFIX}-circularHolder`,
};

const StyledPaper = styled(Paper)(() => ({
  padding: 32,
  gap: 8,
  display: 'flex',
  flexDirection: 'column',

  [`& .${classes.box}`]: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    marginBottom: 32,
  },
  [`& .${classes.listItem}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
  },
  [`& .${classes.circularHolder}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
  },
}));

// Taken from existing functionality, will refactor if necessary
const filterBySearchInput = ({ searchInput = '', catalogueItems = [], healthCategory }) =>
  catalogueItems
    .filter(
      ({ id, name, item, form }) =>
        `${name} ${item} ${id}`.toLowerCase().includes(searchInput.toLowerCase()) && form === healthCategory,
    )
    .sort((a, b) => a.name.localeCompare(b.name));

const StockHeading = ({ heading, shouldDisplay }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <Typography variant="h6" textAlign="center" mt={2} mb={1}>
      {heading}
    </Typography>
  );
};

const CatalogueItem = ({ catalogueItem, pharmacyId }) => {
  const label = `${catalogueItem.name} - ${catalogueItem.item}`;
  const stock = catalogueItem.stock[pharmacyId];
  return (
    <ListItem divider className={classes.listItem}>
      <Typography key={catalogueItem.id} variant="body1">
        {label}
      </Typography>
      <Typography color={stock > 0 ? 'primary' : 'secondary'} fontWeight={500}>
        {stock}
      </Typography>
    </ListItem>
  );
};

const CatalogueItemList = ({ filteredCatalogue, pharmacyId, stockCategory, headingLabel }) => {
  if (!filteredCatalogue[stockCategory].length) {
    return <Typography textAlign="center">No Results Found</Typography>;
  }

  return (
    <>
      <StockHeading heading={headingLabel} shouldDisplay={filteredCatalogue[stockCategory].length > 0} />
      <List dense>
        {filteredCatalogue[stockCategory].map((catalogueItem) => (
          <CatalogueItem catalogueItem={catalogueItem} key={catalogueItem.id} pharmacyId={pharmacyId} />
        ))}
      </List>
    </>
  );
};

const NoItemsAvailable = ({ shouldDisplay }) => {
  if (!shouldDisplay) {
    return null;
  }

  return (
    <Typography variant="body1" mt={2} textAlign="center">
      No Items Available For This Pharmacy
    </Typography>
  );
};

const StockDisplay = ({ hasLoaded, pharmacyId, stockCategory, filteredCatalogue }) => {
  if (!hasLoaded) {
    return (
      <Box className={classes.circularHolder}>
        <CircularProgress />
        <Typography>Loading Catalogue</Typography>
      </Box>
    );
  }

  return (
    <>
      <CatalogueItemList
        filteredCatalogue={filteredCatalogue}
        pharmacyId={pharmacyId}
        stockCategory={stockCategory}
        headingLabel={capitalizeFirstLetterOfEachWord(formatCamelCase(stockCategory))}
      />

      <NoItemsAvailable
        shouldDisplay={filteredCatalogue.inStock.length === 0 && filteredCatalogue.outOfStock.length === 0}
      />
    </>
  );
};

const PharmacyStockPreview = () => {
  const { pharmacyId } = useParams();
  const [searchParams] = useSearchParams();
  const healthCategory = searchParams.get('healthCategory');

  const [pharmacyDocData, pharmacyDocHasLoaded] = useDocument(`/orders/${pharmacyId}`);

  const [retrievedCatalogue, setRetrievedCatalogue] = useState(DEFAULT_STOCK);
  const [filteredCatalogue, setFilteredCatalogue] = useState(DEFAULT_STOCK);

  const [searchInput, setSearchInput] = useState('');
  const [stockCategory, setStockCategory] = useState('inStock');
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(false);
    const debounceTimeout = setTimeout(() => {
      const { inStock, outOfStock } = retrievedCatalogue;
      if (inStock.length === 0 && outOfStock.length === 0) {
        return;
      }

      setFilteredCatalogue({
        inStock: filterBySearchInput({ searchInput, healthCategory, catalogueItems: inStock }),
        outOfStock: filterBySearchInput({ searchInput, healthCategory, catalogueItems: outOfStock }),
      });

      setHasLoaded(true);
    }, DEBOUNCE_TIME);
    return () => clearTimeout(debounceTimeout);
  }, [healthCategory, retrievedCatalogue, searchInput]);

  useEffect(() => {
    setHasLoaded(false);

    const fetchCatalogue = async () => {
      const tempInStockCatalogue = (
        await getDocs(query(collection(db, 'catalogue'), where(`stock.${pharmacyId}`, '>', 0)))
      ).docs.map((doc) => doc.data());

      const tempOutOfStockCatalogue = (
        await getDocs(query(collection(db, 'catalogue'), where(`stock.${pharmacyId}`, '<=', 0)))
      ).docs.map((doc) => doc.data());

      setRetrievedCatalogue({ inStock: tempInStockCatalogue, outOfStock: tempOutOfStockCatalogue });
    };

    if (pharmacyDocHasLoaded) {
      fetchCatalogue();
    }
  }, [healthCategory, pharmacyDocHasLoaded, pharmacyId]);

  return (
    <StyledPaper>
      <Typography variant="h5">Pharmacy Stock for {capitalizeFirstLetterOfEachWord(healthCategory)}</Typography>
      <Typography variant="body1">
        {pharmacyDocData.name} - {pharmacyDocData.email}
      </Typography>
      <Typography variant="body1" color="primary">
        Pharmacy ID: {pharmacyId}
      </Typography>
      <Box className={classes.box}>
        <TextField
          placeholder="Search Catalogue"
          size="small"
          fullWidth
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Select size="small" value={stockCategory} onChange={(e) => setStockCategory(e.target.value)}>
          <MenuItem value="inStock">In Stock</MenuItem>
          <MenuItem value="outOfStock">Out of Stock</MenuItem>
        </Select>
      </Box>

      <StockDisplay
        hasLoaded={hasLoaded}
        pharmacyId={pharmacyId}
        stockCategory={stockCategory}
        filteredCatalogue={filteredCatalogue}
      />
    </StyledPaper>
  );
};

StockHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  shouldDisplay: PropTypes.bool.isRequired,
};

CatalogueItem.propTypes = {
  catalogueItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
    form: PropTypes.string.isRequired,
    stock: PropTypes.objectOf(PropTypes.number).isRequired,
  }).isRequired,
  pharmacyId: PropTypes.string.isRequired,
};

NoItemsAvailable.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
};

CatalogueItemList.propTypes = {
  filteredCatalogue: PropTypes.shape({
    inStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    outOfStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  pharmacyId: PropTypes.string.isRequired,
  stockCategory: PropTypes.string.isRequired,
  headingLabel: PropTypes.string.isRequired,
};

StockDisplay.propTypes = {
  hasLoaded: PropTypes.bool.isRequired,
  pharmacyId: PropTypes.string.isRequired,
  stockCategory: PropTypes.string.isRequired,
  filteredCatalogue: PropTypes.shape({
    inStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    outOfStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
};

export default PharmacyStockPreview;
