import React from 'react';
import { Grid, Typography } from '@mui/material';

const PhentermineTopiramateInformation = () => (
  <Grid item>
    <Typography variant="h6">Phentermine/Topiramate</Typography>
    <br />
    <Typography variant="body1">
      Phentermine/Topiramate is an oral medication used to assist with weight loss.
    </Typography>
    <br />
    <Typography variant="h6">Warnings</Typography>
    <br />
    <Typography variant="body1">
      Birth defects (cleft lip/cleft palate). If you take Phentermine/Topiramate during pregnancy, your baby has a
      higher risk for birth defects called cleft lip and cleft palate. These defects can begin early in pregnancy, even
      before you know you are pregnant. Patients who are pregnant must not take Phentermine/Topiramate. Patients who can
      become pregnant should use effective contraception consistently while taking Phentermine/Topiramate. Talk to your
      doctor about how to prevent pregnancy.
      <br />
      <br />
      Do not use Phentermine/Topiramate if you are taking certain medicines called monoamine oxidase inhibitors (MAOIs)
      or have taken MAOIs in the past 14 days
      <br />
      <br />
      Do not use Phentermine/Topiramate if you have glaucoma.
      <br />
      <br />
      Do not use Phentermine/Topiramate if you have thyroid conditions.
      <br />
      <br />
      Do not drink alcohol while using Phentermine/Topiramate as it may result in dizziness and light-headedness
    </Typography>
    <br />
    <Typography variant="h6">Serious side effects</Typography>
    <br />
    <Typography variant="body1">
      Phentermine/topiramate may cause serious side effects including:
      <br />
      <br />- Increases in heart rate. If you experience, while at rest, a racing or pounding feeling in your chest
      lasting several minutes when taking Phentermine/Topiramate please talk to your doctor. If you experience chest
      pain please seek emergency medical attention.
      <br />- Reversible visual field defects have been reported in patients using Phentermine/Topiramate Visual loss
      and increased eye pressure may occur (secondary angle closure glaucoma). This can lead to permanent vision loss if
      not treated. Please seek emergency medical attention if you experience symptoms.
      <br />- Suicidal thoughts or actions. Topiramate may cause you to have suicidal thoughts or actions. If this
      occurs please stop your treatment and talk with your doctor. If you are at acute risk please seek emergency
      medical attention.
    </Typography>
    <br />
    <Typography variant="h6">Common side effects</Typography>
    <br />
    <Typography variant="body1">
      The most common side effects of Phentermine/topiramate include numbness or tingling in the hands, arms, feet, or
      face (paraesthesia); dizziness; changes in the way foods taste or loss of taste (dysgeusia); trouble sleeping
      (insomnia); constipation; and dry mouth.
      <br />
      <br />
      Less common side effects include:
      <br />
      <br /> - Mood changes and trouble sleeping
      <br /> - Concentration, memory, and speech difficulties
      <br /> - Metabolic acidosis Low blood sugar (hypoglycemia)
      <br /> - Renal (kidney) stones Low blood pressure
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Take Phentermine/Topiramate capsules exactly as prescribed by your doctor.
      <br />
      <br />
      Phentermine/Topiramate can be taken with or without food. If you miss a dose of Qsymia, wait until the next
      morning to take your usual dose. Do not double your dose.
      <br />
      <br />
      For the first 2 weeks of treatment: Take 1 Phentermine/Topiramate 3.75 mg/23 mg capsule each morning
      <br />
      <br />
      From week 3 onwards: Take 1 Phentermine/Topiramate 7.5 mg/46 mg capsule each morning.
      <br />
      <br />
      Treatment is usually continued for a maximum of 12 weeks.
    </Typography>
  </Grid>
);

export default PhentermineTopiramateInformation;
