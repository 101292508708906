import React from 'react';
import { Grid, Typography } from '@mui/material';

const PessariesInfo = () => (
  <Grid item>
    <Typography variant="h6">Using medicinal cannabis inhalers</Typography>
    <br />
    <Typography variant="body1">
      Medicinal cannabis inhalers may be deal for patients who want a rapid-acting medication in a discreet format that
      does not require smoking or vaping. Each inhale provided a metered accurate dose of treatment.
    </Typography>
    <br />
    <Typography variant="h6">How to use a medicinal cannabis inhaler</Typography>
    <br />
    <Typography variant="body1">
      1. Shake the inhaler for 3-5 seconds and exhale fully
      <br />
      2. Close your lips around the mouthpiece of the inhaler
      <br />
      3. Start to inhale as you depress the device. Continue inhaling as you fully depress the device and release
      <br />
      4. Hold the inhalation for 5-10 seconds then exhale
      <br />
      <br />
      Using medicinal cannabis inhalers may result in coughing and throat irritation.
      <br />
      <br />
      Titration is important, especially for new patients. Dosing schedules differ for each patient and should be
      discussed with your doctor. Doses should be increased gradually over time and the effect fully assessed before
      continuing to increase. Note that consuming too much cannabis can result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default PessariesInfo;
