import PropTypes from 'prop-types';

export const GEN_PATIENT_INFO_PROP_TYPES = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dob: PropTypes.string,
  doctor: PropTypes.string,
  type: PropTypes.string,
  isDoctor: PropTypes.bool,
  isPharmacy: PropTypes.bool,
  isSupplier: PropTypes.bool,
  blockedPharmacies: PropTypes.arrayOf(PropTypes.string),
  mediRecords: PropTypes.shape({
    id: PropTypes.string,
    lastDataSync: PropTypes.string,
  }),
  optionalFlag: PropTypes.string,
  // Add more properties here
};

export const CHECKUP = 'checkup';
export const ALL_PATIENTS = 'all patients';
export const NAME = 'name';
export const FIRST_NAME = 'firstName';
export const LAST_NAME = 'lastName';
export const EMAIL = 'email';
export const DOB = 'dob';
export const DOCTOR = 'doctor';
export const UNASSIGNED = 'unassigned';
export const USER_ID = 'userId';

export const NO_NAME = 'No name given';

export const SEARCH_WITH_INPUT_FIELDS = [NAME, FIRST_NAME, LAST_NAME, EMAIL, DOB, DOCTOR, USER_ID];
