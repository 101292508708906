import React from 'react';
import { Grid, Typography } from '@mui/material';

const SildenafilInformation = () => (
  <Grid item>
    <Typography variant="h6">Warning</Typography>
    <br />
    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
      Please note this medication can have serious and potentially fatal side effects if taken inappropriately or
      combined with certain other medications or substances.
    </Typography>
    <br />
    <Typography variant="h6">About Sildenafil</Typography>
    <br />
    <Typography variant="body1">
      Sildenafil is part of a group of medications known as PDE5 inhibitors. They work to increase and maintain
      erections by increasing blood flow to the tissues in the penis and relaxing the smooth muscle. When combined with
      sexual stimulation this then leads to an erection.
      <br />
      <br />
      To work correctly these medications need to be taken at least 30 minutes prior to sexual activity. It may take 3-4
      uses before it can be determined if the medication is effective for you or not. If you are unsure if you are
      taking the correct dose or type of medication please contact your Candor GP to discuss.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Sildenafil is taken around 1 hour prior to sexual activity. If taken with food it may take longer to work. The
      best effects are achieved within 4 hours but may last up to 12.
      <br />
      <br />
      Sexual stimulation is required to achieve an erection.
      <br />
      <br />
      Generally the starting dose is effective for most people. However if you find it isn’t working well enough for you
      or you’re experiencing side effects please discuss with your Candor GP and we can help find the right dose for
      you. Ideally an erection should be maintained for no more than 1 hour (see Priapism below).
      <br />
      <br />
      PDE5 inhibitors work well for the majority of men with erectile dysfunction, however those with diabetes, or who
      have had prostate surgery or experience severe erectile dysfunction may find them less effective. It is possible
      that changing the dose or form of medication may improve the effects.
    </Typography>
    <br />
    <Typography variant="h6">Side effects?</Typography>
    <br />
    <Typography variant="body1">
      Sildenafil is a commonly used medication and side effects are generally mild. Some side effects can be serious and
      medical attention should be sought immediately if experienced. It is important to be aware of any potential
      adverse effects before using.
    </Typography>
    <br />
    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
      Please note this medication can have serious and potentially fatal side effects if taken inappropriately or
      combined with certain other medications or substances.
    </Typography>
    <br />
    <Typography variant="body1">
      Common (more than 1%)
      <br />
      - Headache
      <br />
      - Dizziness
      <br />
      - Flushing
      <br />
      - Dyspepsia
      <br />
      - Nasal congestion/rhinitis
      <br />
      - Blurred vision
      <br />
      - Light sensitivity
      <br />
      - Blue-green colour tinge
      <br />
      - Muscle or back ache
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Priapism (see below)
      <br />
      - Vision loss due to NAION (see below)
      <br />
      - Migraine
      <br />
      - Seizures
      <br />
      - Sudden hearing loss
      <br />
      - Transient amnesia
      <br />
      - Hypersensitivity
      <br />
      <br />
      Please note further detailed information on Sildenafil including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
      <br />
      <br />
      Priapism
      <br />
      <br />
      Priapism is a prolonged erection in the absence of sexual stimulation. It can continue for hours and is often
      painful. Certain people including those with sickle cell anaemia are more likely to experience priapism.
      <br />
      <br />
      Emergency medical treatment is required if the erection lasts more than 4 hours. At Candor we recommend seeking
      medical advice from your GP or presenting to the emergency department if an erection lasts more than 2 hours or is
      painful.
      <br />
      <br />
      NAION
      <br />
      <br />
      While rare, vision loss due to NAION (Non-Arteritic Ischaemic Optic Neuropathy) has been reported with Sildenafil
      use. If you experience any visual changes stop taking Sildenafil and get medical attention immediately.
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
    </Typography>
    <br />
    <Typography variant="h6">Precautions - Nitrate use</Typography>
    <br />
    <Typography variant="body1">
      Do not use Sildenafil if you already take nitrates as the combination can cause a serious drop in blood pressure
      that may be fatal. This can occur even if used on different days.
      <br />
      <br />
      Examples of nitrates include nitric oxide, glyceryl trinitrate (GTN), isosorbide mononitrate, isosorbide
      dinitrate, sodium nitroprusside and amyl nitrate (eg. poppers). If you are uncertain please check with your Candor
      or regular GP before use.
    </Typography>
    <br />
    <Typography variant="h6">Precautions - Cardiovascular conditions</Typography>
    <br />
    <Typography variant="body1">
      Caution is recommended if you experience any cardiovascular conditions including angina, hypertension, stroke or
      heart failure. Use of Sildenafil increases the workload on the cardiovascular system, equivalent to mild-moderate
      exercise. It is recommended that you discuss use with your regular GP before using.
      <br />
      <br />
      Taking Sildenafil may lower your blood pressure and you may experience dizziness or fainting particularly if you
      already use medication to lower your blood pressure.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Sildenafil when pregnant or breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Candor Medical does not prescribe Sildenafil to female patients.</Typography>
  </Grid>
);

export default SildenafilInformation;
