import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Button, CircularProgress, Box, Modal } from '@mui/material';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';

import ModalCard from '../../layout/modal-card';

const PREFIX = 'FileUploadPresentation';

const classes = {
  paper: `${PREFIX}-paper`,
  gridItem: `${PREFIX}-gridItem`,
  grid: `${PREFIX}-grid`,
  image: `${PREFIX}-image`,
  formImage: `${PREFIX}-formImage`,
  input: `${PREFIX}-input`,
  cardholder: `${PREFIX}-cardholder`,
  box: `${PREFIX}-box`,
  changeFileButton: `${PREFIX}-changeFileButton`,
  buttonIcon: `${PREFIX}-buttonIcon`,
  successIcon: `${PREFIX}-successIcon`,
  smallImage: `${PREFIX}-smallImage`,
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.paper}`]: {
    padding: '20px',
    width: '90%',
    maxWidth: '500px',
    margin: 20,
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },

  [`& .${classes.grid}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.image}`]: {
    width: 300,
    height: 150,
  },

  [`& .${classes.formImage}`]: {
    width: 300,
  },

  [`& .${classes.input}`]: {
    display: 'none',
  },

  [`& .${classes.cardholder}`]: {
    width: 200,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  [`&.${classes.box}`]: {
    borderColor: '#E6E6E6',
    marginBottom: 20,
    borderRadius: 8,
  },

  [`& .${classes.changeFileButton}`]: {
    marginTop: 20,
  },

  [`& .${classes.buttonIcon}`]: {
    margin: '0px 0px 2px 5px',
  },

  [`& .${classes.successIcon}`]: {
    color: '#fff',
    backgroundColor: '#2AAFBB',
    borderRadius: '10px',
    padding: 5,
    margin: 10,
  },

  [`& .${classes.smallImage}`]: {
    marginBottom: 15,
    maxWidth: '400px',
  },
}));

const FileUploadPresentation = (props) => {
  const {
    currentFormObject,
    handleModalClose,
    modalOpen,
    handleChange,
    url,
    progress,
    loading,
    formContext,
    uploadedFileName,
  } = props;
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <StyledBox className={classes.box}>
      {url && !loading && (
        <Grid item xs={12} align="center">
          {currentFormObject.fileType === 'image/*' && (
            <img src={url} alt="indetification" className={formContext ? classes.formImage : classes.image} />
          )}
          {currentFormObject.fileType !== 'image/*' && (
            <>
              {!imgLoaded && <PlaylistAddCheckRoundedIcon className={classes.successIcon} fontSize="large" />}
              <img
                style={imgLoaded ? {} : { display: 'none' }}
                src={url}
                onLoad={() => setImgLoaded(true)}
                className={classes.smallImage}
                alt="Capsule Products"
              />
              <br />
              <Typography variant="body1" align="center">
                {uploadedFileName || 'File Uploaded'}
              </Typography>
            </>
          )}
          <br />
          <label htmlFor={currentFormObject.reference}>
            <input
              onChange={handleChange}
              accept={currentFormObject.fileType}
              className={classes.input}
              id={currentFormObject.reference}
              multiple
              type="file"
            />
            <Button className={classes.changeFileButton} variant="outlined" component="span" color="primary">
              Change file
            </Button>
          </label>
        </Grid>
      )}
      {loading && <CircularProgress variant="determinate" value={progress} />}
      {!url && !loading && (
        <Grid item xs={12} align="center">
          <br />
          <label htmlFor={currentFormObject.reference}>
            <input
              onChange={handleChange}
              accept={currentFormObject.fileType}
              className={classes.input}
              id={currentFormObject.reference}
              multiple
              type="file"
            />
            <Button
              size="large"
              startIcon={<FileUploadRoundedIcon className={classes.buttonIcon} color="primary" fontSize="large" />}
              variant="outlined"
              component="span"
              color="primary"
            >
              {currentFormObject.buttonText}
            </Button>
          </label>
        </Grid>
      )}
      <Modal open={modalOpen}>
        <>
          <ModalCard
            text="Upload error occured"
            subtext="Please contact support@candor.org"
            handleModalClose={handleModalClose}
            loadingComponent={false}
          />
        </>
      </Modal>
    </StyledBox>
  );
};

export default FileUploadPresentation;
