import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { getDoc, doc, writeBatch, deleteField } from 'firebase/firestore';

import { AuthContext } from '../../auth-context';
import { db } from '../../firebase-config';
import PaymentDetails from './misc/payment-details';
import PaymentContext from './PaymentContext';
import { getCatalogueId } from '../../utils/constants';
import PaymentMaintenance from './misc/PaymentMaintenance';
import PaymentProcessorContainer from './misc/PaymentProcessorContainer';
import { getPaymentProcessor } from './paymentHelpers';
import CustomPaymentContainer from './misc/CustomPaymentContainer';
import { processFieldData } from '../../utils/formHelpers';

const excludedTypes = ['consult', 'booking fee', 'substitution fee'];

const styles = {
  paymentContainerGrid: {
    textAlign: 'center',
    borderRadius: '5px',
    maxHeight: '95dvh',
    width: '100%',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 600px)': {
      maxHeight: '100%',
    },
  },
  paper: {
    width: '100%',
    maxWidth: '1300px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },
};

const PaymentContainer = ({
  selectedTreatments,
  formName,
  scriptMode,
  type,
  description = 'No description provided',
  setCollectingCardInfo,
  formatShippingDetails,
  pharmacyConsentRead,
  updatePreferredPharmacy,
  isPickup,
  isPharmacyPending,
  pharmacySelectionMode,
  reference,
}) => {
  const { user } = useContext(AuthContext);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [checkoutData, setCheckoutData] = useState({});
  const [shippingAlert, setShippingAlert] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [showPaymentMethods, setShowPaymentMethods] = useState(false);
  const [termsRead, setTermsRead] = useState(false);
  const [treatmentsToRead, setTreatmentsToRead] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [treatmentTranslator, setTreatmentTranslator] = useState({});
  const [paymentProcessor, setPaymentProcessor] = useState('');
  const [isPromoLoading, setIsPromoLoading] = useState(false);

  const handleTermsRead = useCallback(() => {
    const shippingDetails = formatShippingDetails();

    if (termsRead) {
      setTermsRead(false);
      // Make sure all shipping details have been entered
      return;
    }

    if (!scriptMode && Object.values(shippingDetails || {}).includes('')) {
      setShippingAlert('Shipping Details Missing');
      // Check that street address contains both a number and a street name
      return;
    }

    if (!scriptMode && !shippingDetails['shipping.street address']?.match(/\d/)) {
      setShippingAlert('Street Address Missing Number');
      return;
    }

    if (!scriptMode && !shippingDetails['shipping.street address']?.match(/[a-zA-Z]/)) {
      setShippingAlert('Street Address Missing Street Name');
      return;
    }
    setTermsRead(true);
    setShippingAlert('');
  }, [formatShippingDetails, scriptMode, termsRead]);

  const handlePaymentButton = useCallback(async () => {
    if (isPharmacyPending) {
      window.alert(
        'Please select a pharmacy or set the dispatch pharmacy mode to "Auto" instead of "Manual Selection".',
      );
      return;
    }

    if (!excludedTypes.includes(type)) {
      if (scriptMode) {
        updatePreferredPharmacy();
      }
      // Lock the shipping info fields
      setCollectingCardInfo(true);
      // Add current shipping info the the update object
      const dataUpdate = formatShippingDetails();
      const generalInfoDataUpdate = formatShippingDetails(true);
      const batch = writeBatch(db);

      const acceptedAgreementsObj = treatments.reduce((acc, treatment) => {
        const agreement = formName === 'medicinal cannabis' ? treatment.dosageForm : treatment.ingredients;
        return {
          ...acc,
          [`acceptedAgreements.${agreement}`]: Date.now(),
        };
      }, {});

      // Remmove the next pickup and next delivery fields so novatti will run the pharmacySelector instead
      const isPharmacySelectionAutomatic = pharmacySelectionMode === 'automatic';
      const patientUpdates = {
        ...dataUpdate,
        ...((isPharmacySelectionAutomatic || isPickup) && { nextDelivery: deleteField() }), // If the user is picking up, remove the next delivery field
        ...((isPharmacySelectionAutomatic || !isPickup) && { nextPickup: deleteField() }), // If the user is getting delivery, remove the next pickup field
      };

      const sanitizedAcceptedAgreements = processFieldData(acceptedAgreementsObj);

      batch.update(doc(db, 'patients', user.uid), ...sanitizedAcceptedAgreements);
      batch.update(doc(db, 'patients', user.uid), patientUpdates);
      batch.update(doc(db, 'patients', user.uid, 'general', 'information'), generalInfoDataUpdate);
      await batch.commit();
    }

    const tempPaymentProcessor = await getPaymentProcessor();

    setPaymentProcessor(tempPaymentProcessor);
    setIsCheckoutLoading(true);
    setShowPaymentMethods(true);
  }, [
    formName,
    isPharmacyPending,
    isPickup,
    pharmacySelectionMode,
    scriptMode,
    treatments,
    type,
    updatePreferredPharmacy,
    user,
    formatShippingDetails,
    setCollectingCardInfo,
  ]);

  const getTreatmentTranslator = useCallback(async () => {
    if (!selectedTreatments.length) {
      return;
    }
    const promises = selectedTreatments.map(async (value) => {
      const treatment = await getDoc(doc(db, 'catalogue', getCatalogueId(value)));
      return treatment.data();
    });

    const patientData = (await getDoc(doc(db, 'patients', user?.uid)))?.data() || {};
    const result = await Promise.all(promises);
    const toRead = result.filter(
      (item) =>
        !Object.keys(patientData?.acceptedAgreements || {}).includes(
          formName === 'medicinal cannabis' ? item.dosageForm : item.ingredients,
        ),
    );
    const tempTreatmentTranslator = {};
    result.forEach((item) => {
      tempTreatmentTranslator[item.stripePrice] = item;
    });

    if (!toRead.length) {
      setTermsRead(true);
    }

    setTreatments(result);
    setTreatmentsToRead(toRead.map((item) => item.stripePrice));
    setTreatmentTranslator(tempTreatmentTranslator);
  }, [selectedTreatments, user, formName]);

  const paymentContextValue = useMemo(
    () => ({
      promoCode,
      treatmentsToRead,
      selectedTreatments,
      handlePaymentButton,
      isCheckoutLoading,
      termsRead,
      description,
      checkoutData,
      setCheckoutData,
      formName,
      scriptMode,
      handleTermsRead,
      shippingAlert,
      setPromoCode,
      pharmacyConsentRead,
      isPickup,
      isPharmacyPending,
      showPaymentMethods,
      setShowPaymentMethods,
      paymentProcessor,
      setPaymentProcessor,
      treatmentTranslator,
      reference,
      isPromoLoading,
      setIsPromoLoading,
    }),
    [
      treatmentsToRead,
      selectedTreatments,
      handlePaymentButton,
      isCheckoutLoading,
      termsRead,
      description,
      checkoutData,
      setCheckoutData,
      formName,
      scriptMode,
      handleTermsRead,
      shippingAlert,
      promoCode,
      setPromoCode,
      pharmacyConsentRead,
      isPickup,
      isPharmacyPending,
      showPaymentMethods,
      setShowPaymentMethods,
      paymentProcessor,
      setPaymentProcessor,
      treatmentTranslator,
      reference,
      isPromoLoading,
      setIsPromoLoading,
    ],
  );

  useEffect(() => {
    if (!reference) {
      getTreatmentTranslator();
    }
  }, [getTreatmentTranslator, reference]);

  return (
    <PaymentContext.Provider value={paymentContextValue}>
      <Grid sx={styles.paymentContainerGrid} container>
        <Paper sx={styles.paper}>
          {reference ? (
            <CustomPaymentContainer />
          ) : (
            <Grid container>
              <PaymentDetails />
              <PaymentProcessorContainer />
            </Grid>
          )}
        </Paper>
      </Grid>
      <PaymentMaintenance />
    </PaymentContext.Provider>
  );
};

PaymentContainer.propTypes = {
  selectedTreatments: PropTypes.arrayOf(PropTypes.string),
  formName: PropTypes.string,
  scriptMode: PropTypes.bool,
  type: PropTypes.string,
  description: PropTypes.string,
  setCollectingCardInfo: PropTypes.func,
  formatShippingDetails: PropTypes.func,
  pharmacyConsentRead: PropTypes.bool,
  updatePreferredPharmacy: PropTypes.func,
  isPickup: PropTypes.bool,
  isPharmacyPending: PropTypes.bool,
  pharmacySelectionMode: PropTypes.string,
  reference: PropTypes.string,
};

PaymentContainer.defaultProps = {
  selectedTreatments: [],
  formName: '',
  scriptMode: false,
  type: '',
  description: '',
  setCollectingCardInfo: () => {},
  formatShippingDetails: () => {},
  pharmacyConsentRead: false,
  updatePreferredPharmacy: () => {},
  isPickup: false,
  isPharmacyPending: false,
  pharmacySelectionMode: '',
  reference: '',
};

export default PaymentContainer;
