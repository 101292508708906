import React from 'react';
import { Grid, Typography } from '@mui/material';

const FamciclovirInformation = () => (
  <Grid item>
    <Typography variant="h6">About Famciclovir</Typography>
    <br />
    <Typography variant="body1">
      Famciclovir is an antiviral medication used to treat Herpes Simplex Virus (HSV). HSV 1 and HSV 2 cause cold sores
      and genital herpes.
      <br />
      <br />
      Famciclovir prevents the virus from replicating and can help minimise symptoms or prevent attacks. It is important
      to know Famciclovir does not cure HSV, but it does make outbreaks much less severe.
      <br />
      <br />
      Famciclovir also helps decrease how likely you are to transmit HSV to other people. While it helps prevent
      transmission we still recommend you take precautions to avoid spreading HSV, in particular, avoiding intimate
      contact when you have an outbreak.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Dosage of Famciclovir depends on if you are treating cold sores or genital herpes, and if you are treating an
      outbreak or aiming to prevent outbreaks. While the same medication is used for all the above, the timing and
      dosage varies. See your personalised prescription for this information.
      <br />
      <br />
      If you would like to change this please discuss with your Candor GP.
      <br />
      <br />
      If treating an outbreak it is most effective to take Famciclovir as soon as you notice symptoms. This will prevent
      the virus from replicating much more effectively.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Famciclovir is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      <br />
      - Headache
      <br />
      - Vomiting
      <br />
      - Nausea
      <br />
      - Diarrhoea
      <br />
      <br />
      Infrequent (0.1 - 1%)
      <br />
      <br />
      - Agitation
      <br />
      - Vertigo
      <br />
      - Confusion
      <br />
      - Dizziness
      <br />
      - Renal impairment
      <br />
      - Sore throat
      <br />
      - Arthralgia
      <br />
      - Constipation
      <br />
      - Rash
      <br />
      - Abdominal pain
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      <br />
      - Seizures
      <br />
      - Anorexia
      <br />
      - Fatigue
      <br />
      - Hepatitis
      <br />
      - Neutropenia
      <br />
      - Leucopenia
      <br />
      - Anaphylaxis
      <br />
      - Stevens-Johnson syndrome
      <br />
      - Toxic epidermal necrolysis
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Famciclovir including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Famciclovir when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Possibly. There are better alternatives available when pregnant and it’s best to discuss these with your regular
      GP to ensure the safest treatment.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Famciclovir when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default FamciclovirInformation;
