import React, { useState, useEffect, useContext } from 'react';
import { doc, updateDoc, writeBatch, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { isCandor } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';
import createEmailObj from '../../../../functions/create-email-obj';
import CheckboxFormElementPresentation from '../presentation-elements/checkbox-form-element-presentation';

const CheckboxFormElement = (props) => {
  const { currentFormObject, handlePrev, setFormInputs, handleNext, formInputs, index, formName, simpleView } = props;

  const { user, userType } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const [checkedOptions, setCheckedOptions] = useState({});

  const handleChange = (event) => {
    const obj = event.target.checked ? { [event.target.name]: true } : { [event.target.name]: false };
    setCheckedOptions({ ...checkedOptions, ...obj });
  };

  const handleInput = async () => {
    setIsLoading(true);
    const optionsToUpdate = {};
    let updateRequired = false;
    Object.entries(checkedOptions).forEach((entry) => {
      const [key, val] = entry;
      // Only write to the database if it's a new entry that's true or an existing entry that's false
      if (formInputs[key] !== val && (formInputs[key] !== undefined || val !== false)) {
        optionsToUpdate[key] = val;
        updateRequired = true;
      }
    });
    if (updateRequired) {
      setFormInputs({ ...formInputs, ...optionsToUpdate });
      if (user) {
        const obj = optionsToUpdate;
        if (!isCandor(userType)) obj[`forms.${formName}.step`] = index;
        // Let the doctor know a treatment has changed
        if (formInputs['basic info.first form completed'] && currentFormObject.notifyDoctor) {
          const batch = writeBatch(db);
          let date = Date.now();
          Object.entries(optionsToUpdate).forEach((entry) => {
            const [key, val] = entry;
            const verbArray = val ? ['added', 'to'] : ['removed', 'from'];
            const arrayText = key.split('.');
            batch.set(doc(collection(db, 'patients', user.uid, 'activity')), {
              createdAt: date,
              generalData: false,
              author: 'System',
              text: `Patient ${verbArray[0]} ${arrayText[0]} ${verbArray[1]} their ${arrayText[1]} section`,
            });
            date += 1;
          });
          const dataObj = {
            patientName: `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`,
            email: user.email,
            updatedData: 'Patient updated some important details',
          };
          const mailRef1 = doc(collection(db, 'mail'));
          batch.set(mailRef1, createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
          batch.update(doc(db, 'patients', user.uid, 'general', 'information'), obj);
          await batch.commit();
        } else {
          updateDoc(doc(db, 'patients', user.uid, 'general', 'information'), obj);
        }
      }
    }
    setIsLoading(false);
    handleNext();
  };

  useEffect(() => {
    const obj = {};
    currentFormObject.fieldText.forEach((field) => {
      if (formInputs[field] === true) {
        obj[field] = true;
      } else {
        obj[field] = false;
      }
    });
    setCheckedOptions(obj);
  }, [currentFormObject, formInputs]);

  return (
    <CheckboxFormElementPresentation
      isLoading={isLoading}
      simpleView={simpleView}
      currentFormObject={currentFormObject}
      handlePrev={handlePrev}
      handleInput={handleInput}
      handleChange={handleChange}
      formInputs={formInputs}
      checkedOptions={checkedOptions}
    />
  );
};

export default CheckboxFormElement;
