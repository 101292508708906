import React, { useState, useRef, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { storage } from '../../../firebase-config';
import FileUploaderPresentation from './file-uploader-presentation';

const FileUploader = (props) => {
  const { currentFormObject, formContext, heading, url, setUrl } = props;

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');

  const isMounted = useRef(false);

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleUpload = async (file) => {
    const uploadTask = uploadBytesResumable(
      ref(
        storage,
        `gs://${import.meta.env.VITE_APP_FIREBASE_CONFIG_STORAGE_BUCKET_UNSCANNED}/${currentFormObject.storagePath}`,
      ),
      file,
    );
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const currentProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(currentProgress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        // Put 4 seconds delay to ensure file is uploaded to existing storage before attempting to get download url.
        setTimeout(() => {
          getDownloadURL(
            ref(
              storage,
              `gs://${import.meta.env.VITE_APP_FIREBASE_CONFIG_STORAGE_BUCKET}/${currentFormObject.storagePath}`,
            ),
          )
            .then((newUrl) => {
              if (newUrl) {
                setUploadedFileName(file.name);
                setUrl(newUrl);
                setLoading(false);
              }
            })
            .catch((err) => {
              // If file does not exist or is harmful, set loading to false and show an alert.
              window.alert('File upload failed.');
              setLoading(false);
            });
        }, 4000);
      },
    );
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setLoading(true);
      handleUpload(e.target.files[0]);
    }
  };

  useEffect(() => {
    isMounted.current = true;
    async function getData() {
      try {
        const foundUrl = await getDownloadURL(ref(storage, currentFormObject.storagePath));
        if (isMounted.current) setUrl(foundUrl);
        return 'success';
      } catch (error) {
        return error;
      }
    }
    getData();
  }, [currentFormObject.storagePath, setUrl]);

  return (
    <FileUploaderPresentation
      uploadedFileName={uploadedFileName}
      heading={heading}
      formContext={formContext}
      currentFormObject={currentFormObject}
      modalOpen={modalOpen}
      handleModalClose={handleModalClose}
      handleChange={handleChange}
      url={url}
      progress={progress}
      loading={loading}
    />
  );
};

export default FileUploader;
