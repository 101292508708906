const terpeneTranslator = {
  'Alpha Beta Pinene': {
    aromas: 'Sharp, piney',
    benefits: 'Anti-inflammatory, anti-bacterial, antibacterial, bronchodilator, aids memory',
  },
  'Alpha Bisabolol': {
    aromas: 'Sweet, fruity, nutty',
    benefits: 'Anti-inflammatory, antioxidant, pain relief, stress relief',
  },
  'Beta Caryophyllene': {
    aromas: 'Pepper, wood, spice',
    benefits: 'Anti-inflammatory, anti-anxiety, gastric protective, sleep aid, muscle relaxant',
  },
  Farnesene: {
    aromas: 'Sweet, wood, berry, apple',
    benefits: 'Anti-inflammatory, anti-fungal, anti-bacterial, sedative',
  },
  Guaiol: {
    aromas: 'Fresh, piney, wood, rose',
    benefits: 'Anti-inflammatory, anti-fungal, anti-parasitic, antioxidant',
  },
  Humulene: {
    aromas: 'Wood, earthy',
    benefits: 'Anticancer, anti-inflammatory, analgesic',
  },
  Limonene: {
    aromas: 'Lemon, orange',
    benefits: 'Anti-inflammatory, anti-depressant, anti-anxiety, gastric protectant',
  },
  Linalool: {
    aromas: 'Floral',
    benefits: 'Analgesic, anaesthetic, anti-convulsant, anti-anxiety, sedative, anticancer',
  },
  Myrcene: {
    aromas: 'Floral, earthy, citrus',
    benefits: 'Sedative, muscle relaxant, anti-inflammatory',
  },
  Nerolidol: {
    aromas: 'Wood, citrus, apple, rose',
    benefits: 'Antimicrobial, anti-parasitic, antioxidant, anti-fungal',
  },
  Ocimene: {
    aromas: 'Sweet, earthy, herbaceous, citrus',
    benefits: 'Uplifting, anti-convulsant, anti-inflammatory, anti-viral, anti-fungal',
  },
  Terpineol: {
    aromas: 'Lemon, pine, sweet, woody',
    benefits: 'Antibacterial, anticancer, antioxidant, sedative',
  },
  Terpinolene: {
    aromas: 'Pine, clove',
    benefits: 'Anti-inflammatory, antioxidant, sedative, stress relief',
  },
};

export default terpeneTranslator;
