export default function createEmailObj(emailString, template, dataObj) {
  return {
    to: emailString,
    toUids: null,
    cc: null,
    ccUids: null,
    bcc: null,
    bccUids: null,
    message: null,
    template: {
      name: template,
      data: dataObj || null,
    },
  };
}
