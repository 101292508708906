import React, { useState, useContext, useEffect } from 'react';
import { doc, updateDoc, deleteField } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db } from '../../../../firebase-config';
import TreatmentsTriedFormElementPresentation from '../presentation-elements/treatments-tried-form-element-presentation';
import { AuthContext } from '../../../../auth-context';
import Loading from '../../../layout/loading';
import { processFieldData } from '../../../../utils/formHelpers';

const TreatmentsTriedFormElement = (props) => {
  const { currentFormObject, setFormInputs, handleNext, formInputs, simpleView, formName } = props;

  const { user, userType } = useContext(AuthContext);

  const [inputError, setInputError] = useState('');
  const [options, setOptions] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [elementAdder, setElementAdder] = useState(false);
  const [editingExistingElement, setEditingExistingElement] = useState(false);
  const [editedObjStartKey, setEditedObjStartKey] = useState('');
  const [newTreatmentObj, setNewTreatmentObj] = useState();

  const { prepender, slimTextOne, slimTextTwo, radioOne, radioTwo, longTextOne, longTextTwo } =
    currentFormObject.fields;

  const clearError = () => {
    if (inputError !== '') setInputError('');
  };

  const handleElementAdder = () => {
    setElementAdder(true);
  };

  const handleEditElement = async (treatmentName) => {
    // Get the starting key to make sure I'm aware if it changes
    setEditedObjStartKey(treatmentName);
    setNewTreatmentObj(() => {
      const obj = {
        [slimTextOne]: treatmentName,
        [radioOne]: options[treatmentName][radioOne],
        [radioTwo]: options[treatmentName][radioTwo],
      };
      if (slimTextTwo !== undefined) obj[slimTextTwo] = options[treatmentName][slimTextTwo];
      if (longTextOne !== undefined) obj[longTextOne] = options[treatmentName][longTextOne];
      if (longTextTwo !== undefined) obj[longTextTwo] = options[treatmentName][longTextTwo];
      return obj;
    });
    setEditingExistingElement(true);
    setElementAdder(true);
  };

  const handleRadioOneChange = (value) => {
    const radioObj = { [radioOne]: value };
    if (!value && newTreatmentObj[longTextTwo] !== undefined) radioObj[longTextTwo] = '';
    setNewTreatmentObj((obj) => ({ ...obj, ...radioObj }));
  };

  const handleRadioTwoChange = (value) => {
    const radioObj = { [radioTwo]: value };
    if (value && newTreatmentObj[longTextTwo] !== undefined) radioObj[longTextTwo] = '';
    if (!value) radioObj[longTextOne] = '';
    setNewTreatmentObj((obj) => ({ ...obj, ...radioObj }));
  };

  const handleCancel = () => {
    setNewTreatmentObj(() => {
      const obj = { [slimTextOne]: '', [radioOne]: '', [radioTwo]: '', [longTextOne]: '' };
      if (slimTextTwo) obj[slimTextTwo] = '';
      if (longTextTwo) obj[longTextTwo] = '';
      return obj;
    });
    setInputError('');
    setElementAdder(false);
  };

  const handleRemoveElement = async () => {
    try {
      const treatmentRef = `${prepender}${editedObjStartKey}`;
      if (user) {
        await updateDoc(
          doc(db, 'patients', user?.uid, 'general', 'information'),
          processFieldData(treatmentRef),
          deleteField(),
        );
      }
      // Remove the item from the inputs array
      clearError();
      // Remove the item from the greater form inputs array
      // TODO: Use destructuring and conditional object modification:
      setFormInputs(() => {
        const newFormInputs = { ...formInputs };
        delete newFormInputs[treatmentRef + slimTextOne];
        delete newFormInputs[treatmentRef + radioOne];
        delete newFormInputs[treatmentRef + radioTwo];
        delete newFormInputs[treatmentRef + longTextOne];
        if (slimTextTwo !== undefined) delete newFormInputs[treatmentRef + slimTextTwo];
        if (longTextTwo !== undefined) delete newFormInputs[treatmentRef + longTextTwo];
        return newFormInputs;
      });
      setEditingExistingElement(false);
      setInputError('');
      setElementAdder(false);
    } catch (error) {
      sentryCaptureException(error, { extra: { form: formName, issueIn: 'Remove Tried Treatment' } });
    }
  };

  const handleSaveChanges = async () => {
    if (userType !== 'patient') {
      setEditingExistingElement(false);
      setElementAdder(false);
      return;
    }

    try {
      const treatmentRef = `${prepender}${editedObjStartKey}`;
      const obj = {
        [prepender + newTreatmentObj[slimTextOne] + slimTextOne]: true,
        [prepender + newTreatmentObj[slimTextOne] + radioOne]: newTreatmentObj[radioOne],
        [prepender + newTreatmentObj[slimTextOne] + radioTwo]: newTreatmentObj[radioTwo],
      };

      // Add in duration if necessary
      if (slimTextTwo !== undefined)
        obj[prepender + newTreatmentObj[slimTextOne] + slimTextTwo] = newTreatmentObj[slimTextTwo];
      // Add in side effects details if side effects equals true
      if (newTreatmentObj[radioTwo])
        obj[prepender + newTreatmentObj[slimTextOne] + longTextOne] = newTreatmentObj[longTextOne];
      // Add in extra details if necessary and effectiveness equals true and side effects equals false
      if (longTextTwo !== undefined && newTreatmentObj[radioOne] && !newTreatmentObj[radioTwo])
        obj[prepender + newTreatmentObj[slimTextOne] + longTextTwo] = newTreatmentObj[longTextTwo];
      // This means they've changed the name of the treatment
      if (editedObjStartKey !== newTreatmentObj[slimTextOne] && editingExistingElement) {
        if (options[newTreatmentObj[slimTextOne]]) {
          setInputError(`This ${slimTextOne.toLowerCase()} has already been entered.`);
          return;
        }
        // In this case the treatment name has changed so the reference to the old object needs to be deleted and the new one created
        setFormInputs(() => {
          const newFormInputs = { ...formInputs, ...obj };
          delete newFormInputs[treatmentRef + slimTextOne];
          delete newFormInputs[treatmentRef + slimTextTwo];
          delete newFormInputs[treatmentRef + radioOne];
          delete newFormInputs[treatmentRef + radioTwo];
          delete newFormInputs[treatmentRef + longTextOne];
          delete newFormInputs[treatmentRef + longTextTwo];
          return newFormInputs;
        });

        // delete the old object in the database
        await updateDoc(
          doc(db, 'patients', user?.uid, 'general', 'information'),
          processFieldData(treatmentRef),
          deleteField(),
        );

        const sanitizedObj = processFieldData(obj);
        await updateDoc(doc(db, 'patients', user.uid, 'general', 'information'), ...sanitizedObj);
      } else {
        setFormInputs(() => {
          const newFormInputs = { ...formInputs, ...obj };
          if (!newTreatmentObj[radioTwo]) delete newFormInputs[treatmentRef + longTextOne];
          if (longTextTwo === undefined || !newTreatmentObj[radioOne] || newTreatmentObj[radioTwo])
            delete newFormInputs[treatmentRef + longTextTwo];
          return newFormInputs;
        });
        if (!newTreatmentObj[radioTwo]) obj[prepender + newTreatmentObj[slimTextOne] + longTextOne] = deleteField();
        if (longTextTwo === undefined || !newTreatmentObj[radioOne] || newTreatmentObj[radioTwo])
          obj[prepender + newTreatmentObj[slimTextOne] + longTextTwo] = deleteField();
        const sanitizedObj = processFieldData(obj);
        await updateDoc(doc(db, 'patients', user.uid, 'general', 'information'), ...sanitizedObj);
      }
    } catch (error) {
      sentryCaptureException(error, { extra: { form: formName, issueIn: 'Treatments Tried Form' } });
    }

    setEditingExistingElement(false);
    setElementAdder(false);
  };

  const handleSlimOneChange = (value) => {
    clearError();
    if (value.includes('.')) {
      setInputError('Enter Treatment Name Only (No Full Stops)');
    } else {
      setNewTreatmentObj((obj) => ({ ...obj, [slimTextOne]: value }));
    }
  };

  const handleSlimTwoChange = (value) => {
    clearError();
    setNewTreatmentObj((obj) => ({ ...obj, [slimTextTwo]: value }));
  };

  const handleLongOneChange = (value) => {
    clearError();
    setNewTreatmentObj((obj) => ({ ...obj, [longTextOne]: value }));
  };

  const handleLongTwoChange = (value) => {
    clearError();
    setNewTreatmentObj((obj) => ({ ...obj, [longTextTwo]: value }));
  };

  useEffect(() => {
    setInputError('');
    const masterObj = {};
    Object.entries(formInputs).forEach((entry) => {
      const [key, value] = entry;
      // Find a relevant treatment element
      if (key.startsWith(prepender) && key.endsWith(slimTextOne) && value) {
        const obj = {};
        const shortenedKey = key.replace(slimTextOne, '');
        obj[slimTextOne] = true;
        obj[slimTextTwo] = formInputs[shortenedKey + slimTextTwo];
        obj[radioOne] = formInputs[shortenedKey + radioOne];
        obj[radioTwo] = formInputs[shortenedKey + radioTwo];
        obj[longTextOne] = formInputs[shortenedKey + longTextOne];
        obj[longTextTwo] = formInputs[shortenedKey + longTextTwo];
        masterObj[shortenedKey.replace(prepender, '')] = obj;
      }
    });
    setOptions(masterObj);
    setNewTreatmentObj(() => {
      const obj = { [slimTextOne]: '', [radioOne]: null, [radioTwo]: null, [longTextOne]: '' };
      if (slimTextTwo !== undefined) obj[slimTextTwo] = '';
      if (longTextTwo !== undefined) obj[longTextTwo] = '';
      return obj;
    });
    setDataLoaded(true);
  }, [
    currentFormObject,
    formInputs,
    prepender,
    slimTextOne,
    slimTextTwo,
    longTextOne,
    longTextTwo,
    radioOne,
    radioTwo,
  ]);

  if (dataLoaded) {
    return (
      <TreatmentsTriedFormElementPresentation
        currentFormObject={currentFormObject}
        handleSlimOneChange={handleSlimOneChange}
        handleSlimTwoChange={handleSlimTwoChange}
        handleLongOneChange={handleLongOneChange}
        handleLongTwoChange={handleLongTwoChange}
        handleRemoveElement={handleRemoveElement}
        options={options}
        inputError={inputError}
        handleEditElement={handleEditElement}
        handleRadioOneChange={handleRadioOneChange}
        handleRadioTwoChange={handleRadioTwoChange}
        handleCancel={handleCancel}
        elementAdder={elementAdder}
        handleElementAdder={handleElementAdder}
        prepender={prepender}
        slimTextOne={slimTextOne}
        slimTextTwo={slimTextTwo}
        longTextOne={longTextOne}
        longTextTwo={longTextTwo}
        radioOne={radioOne}
        radioTwo={radioTwo}
        editingExistingElement={editingExistingElement}
        newTreatmentObj={newTreatmentObj}
        handleSaveChanges={handleSaveChanges}
        handleNext={handleNext}
        simpleView={simpleView}
      />
    );
  }
  return <Loading />;
};

export default TreatmentsTriedFormElement;
