import React from 'react';
import { styled } from '@mui/material/styles';
import { Modal, Paper } from '@mui/material';

const PREFIX = 'ModalContainer';

const classes = {
  modal: `${PREFIX}-modal`,
};

const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',

  [`& .${classes.modal}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));

const ModalContainer = ({ isOpen, setIsOpen, children, ...props }) => (
  <StyledModal className={classes.modal} open={isOpen} onClose={() => setIsOpen(false)} {...props}>
    <Paper sx={{ p: 2, m: 1 }}>{children}</Paper>
  </StyledModal>
);

export default ModalContainer;
