import React from 'react';
import { Grid, Typography } from '@mui/material';

const OilInfo = () => (
  <Grid item>
    <Typography variant="h6">Taking medicinal cannabis oil</Typography>
    <br />
    <Typography variant="body1">
      Medicinal cannabis oils are simple and easy to use. Cannabis oils can be added to liquids or placed directly under
      the tongue and can be taken with or without food.
      <br />
      <br />
      Titration is important when using cannabis oil, especially for new patients. Oral cannabis takes 1-3 hours to have
      an effect and lasts for 6-8 hours. We suggest waiting at least 6 hours between doses. Dosing schedules differ for
      each patient and should be discussed with your doctor. Doses should be increased gradually over time and the
      effect fully assessed before continuing to increase. Note that consuming too much cannabis can result in
      discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default OilInfo;
