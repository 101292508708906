import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { SnackbarContent, Snackbar } from '@mui/material';

const PREFIX = 'CustomizableSnackbar';

const classes = {
  snackbar: `${PREFIX}-snackbar`,
  snackbarContent: `${PREFIX}-snackbarContent`,
};

const StyledSnackbar = styled(Snackbar)(() => ({
  [`&.${classes.snackbar}`]: {
    minWidth: '50px',
  },

  [`& .${classes.snackbarContent}`]: {
    backgroundColor: '#303F56',
    minWidth: '50px',
    justifyContent: 'center',
  },
}));

const CustomizableSnackbar = ({ message, snackbarOpen, setSnackbarOpen, autoHideDuration = 3000 }) => {
  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <StyledSnackbar
      open={snackbarOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      className={classes.snackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarContent className={classes.snackbarContent} message={<span>{message}</span>} />
    </StyledSnackbar>
  );
};

CustomizableSnackbar.propTypes = {
  message: PropTypes.string.isRequired,
  snackbarOpen: PropTypes.bool.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
  autoHideDuration: PropTypes.number,
};

export default CustomizableSnackbar;
