const adhdScaleObj = {
  formName: 'ADHD Scale',
  explainerText: 'Please answer the questions by choosing what best describes how you have felt over the past 6 months',
  options: ['Never', 'Rarely', 'Sometimes', 'Often', 'Always'],
  questions: [
    'How often do you make careless mistakes when you have to work on a boring or difficult project?',
    'How often do you have difficulty keeping your attention when you are doing boring or repetitive work?',
    'How often do you have difficulty concentrating on what people say to you, even when they are speaking to you directly?',
    'How often do you have trouble wrapping up the final details of a project, once the challenging parts have been done?',
    'How often do you have difficulty getting things in order when you have to do a task that requires organization?',
    'When you have a task that requires a lot of thought, how often do you avoid or delay getting started?',
    'How often do you misplace or have difficulty finding things at home or at work?',
    'How often are you distracted by activity or noise around you?',
    'How often do you have problems remembering appointments or obligations?',
    'How often do you fidget or squirm with your hands or feet when you have to sit down for a long time?',
    'How often do you feel overly active and compelled to do things, like you were driven by a motor?',
    'How often do you leave your seat in meetings or other situations in which you are expected to remain seated?',
    'How often do you feel restless or fidgety?',
    'How often do you have difficulty unwinding and relaxing when you have time to yourself?',
    'How often do you find yourself talking too much when you are in social situations?',
    'When you’re in a conversation, how often do you find yourself finishing the sentences of the people you are talking to, before they can finish them themselves?',
    'How often do you have difficulty waiting your turn in situations when turn taking is required?',
    'How often do you interrupt others when they are busy?',
  ],
};

const dass21Obj = {
  formName: 'Dass 21',
  explainerText:
    'The following questions relate to anxiety and depression. Please read each statement and indicate how much it applied to you over the past week.',
  options: ['Not at all', 'Some of the time', 'A good part of the time', 'Most of the time'],
  questions: [
    'I found it hard to wind down',
    'I was aware of dryness of my mouth',
    "I couldn't seem to experience any positive feeling at all",
    'I experienced breathing difficulty (eg, excessively rapid breathing, breathlessness in the absence of physical exertion)',
    'I found it difficult to work up the initiative to do things',
    'I tended to over-react to situations',
    'I experienced trembling (eg, in the hands)',
    'I felt that I was using a lot of nervous energy',
    'I was worried about situations in which I might panic and make a fool of myself',
    'I felt that I had nothing to look forward to',
    'I found myself getting agitated',
    'I found it difficult to relax',
    'I felt down-hearted and blue',
    'I was intolerant of anything that kept me from getting on with what I was doing',
    'I felt I was close to panic',
    'I was unable to become enthusiastic about anything',
    "I felt I wasn't worth much as a person",
    'I felt that I was rather touchy',
    'I was aware of the action of my heart in the absence of physical exertion (eg, sense of heart rate increase, heart missing a beat)',
    'I felt scared without any good reason',
    'I felt that life was meaningless',
  ],
};

const fatigueScaleObj = {
  formName: 'Fatigue Scale',
  explainerText:
    'This assessment is designed to test your fatigue severity. Choose the option that best fits each question',
  options: ['Strongly disagree', 'Disagree', 'Agree', 'Strongly agree'],
  questions: [
    'My motivation is lower when I am fatigued',
    'Exercise brings on my fatigue',
    'I am easily fatigued',
    'Fatigue interferes with my physical activity',
    'Fatigue causes frequent problems for me',
    'My fatigue prevents continued physical activity',
    'Fatigue interferes with carrying out certain duties and responsibilities',
    'Fatigue is among my most disabling symptoms',
    'Fatigue interferes with my work, family, or social life',
  ],
};

const painScaleObj = {
  formName: 'Pain Scale',
  explainerText:
    'This assessment is designed to test your pain severity. Thinking about the past week, how much do you agree with the following...',
  options: ['Strongly disagree', 'Disagree', 'Agree', 'Strongly agree'],
  questions: [
    'My mood is lower when I am in pain',
    'Exercise brings on my pain',
    'Pain interferes with my sleep',
    'Pain interferes with my physical activity',
    'Pain causes frequent problems for me',
    'My pain prevents continued physical activity',
    'Pain interferes with carrying out certain duties and responsibilities',
    'Pain is among my most disabling symptoms',
    'Pain interferes with my work, family, or social life',
  ],
};

const k10Obj = {
  formName: 'K10',
  explainerText:
    'The following questions relate to anxiety and depression. Please read each statement and pick the option that indicates how much the statement applied to you over the past week',
  options: ['None of the time', 'A little of the time', 'Some of the time', 'Most of the time', 'All of the time'],
  questions: [
    'In the past 4 weeks, about how often did you feel tired out for no good reason?',
    'In the past 4 weeks, about how often did you feel nervous?',
    'In the past 4 weeks, about how often did you feel so nervous that nothing could calm you down?',
    'In the past 4 weeks, about how often did you feel hopeless?',
    'In the past 4 weeks, about how often did you feel restless or fidgety?',
    'In the past 4 weeks, about how often did you feel so restless you could not sit still?',
    'In the past 4 weeks, about how often did you feel depressed?',
    'In the past 4 weeks, about how often did you feel that everything was an effort?',
    'In the past 4 weeks, about how often did you feel so sad that nothing could cheer you up?',
    'In the past 4 weeks, about how often did you feel worthless?',
  ],
};

const ptsdScaleObj = {
  formName: 'PTSD Scale',
  explainerText: 'Over the last month, how often have you experienced the following...',
  options: ['Not at all', 'A little bit', 'Somewhat', 'Quite a bit', 'A lot'],
  questions: [
    'Repeated, disturbing, and unwanted memories of the stressful experience?',
    'Repeated, disturbing dreams of the stressful experience?',
    'Suddenly feeling or acting as if the stressful experience were actually happening again (as if you were actually back there reliving it)?',
    'Feeling very upset when something reminded you of the stressful experience?',
    'Having strong physical reactions when something reminded you of the stressful experience (for example, heart pounding, trouble breathing, sweating)?',
    'Avoiding memories, thoughts, or feelings related to the stressful experience?',
    'Avoiding external reminders of the stressful experience (for example, people, places, conversations, activities, objects, or situations)?',
    'Trouble remembering important parts of the stressful experience?',
    'Having strong negative beliefs about yourself, other people, or the work (for example, having thoughts such as: I am bad, there is something seriously wrong with me, no one can be trusted, the world is completely dangerous)?',
    'Blaming yourself or someone else for the stressful experience or what happened after it?',
    'Having strong negative feelings such as fear, horror, anger, guilt, or shame?',
    'Loss of interest in activities that you used to enjoy?',
    'Feeling distant or cut off from other people?',
    'Trouble experiencing positive feelings (for example, being unable to feel happiness or have loving feelings for people close to you)?',
    'Irritable behaviour, angry outbursts, or acting aggressively?',
    'Taking too many risks or doing things that could cause you harm?',
    'Being “superalert” or watchful or on guard?',
    'Feeling jumpy or easily startled?',
    'Having difficulty concentrating?',
    'Trouble falling or staying asleep?',
  ],
};

export { adhdScaleObj, dass21Obj, fatigueScaleObj, k10Obj, ptsdScaleObj, painScaleObj };
