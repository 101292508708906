import React, { useContext } from 'react';
import { Typography } from '@mui/material';
import PatientFormView from '../form-views/patient-form-view';
import DoctorFormView from '../form-views/doctor-form-view';
import {
  basicInfoElements,
  loginElements,
  birthDateFormElements,
  pregnancyFormElement,
  finalFormElements,
  exitFormElement,
  pregnancyPlanningFormElement,
  heightAndWeightFormElements,
} from '../generic-form-objects/misc-objects';
import { createConditionElements, createTreatmentElements } from '../generic-form-objects/object-generators';
import { AuthContext } from '../../../auth-context';
import Loading from '../../layout/loading';

// TODO: Needs to double check with Joel about the formObjects or types used here.

const WeightLossForm = (props) => {
  const { patient, simpleView, history } = props;

  const { user, userType } = useContext(AuthContext);

  // As a rule, any conditions / medications that will appear across multiple forms should not be addressed through a checklist, to avoid double handling of sensitive questions

  const formObjects = [
    {
      headingText: 'Weight Loss by Candor',
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          To start, let&apos;s get some information about your health and lifestyle
        </Typography>
      ),
      elementType: 'warning',
      creationTest: () => true,
    },
    ...heightAndWeightFormElements(),
    exitFormElement(
      'because your BMI is below 27',
      (formInputs) =>
        formInputs['general health.weight'] /
          ((formInputs['general health.height'] / 100) * (formInputs['general health.height'] / 100)) <
        27,
    ),
    {
      headingText: 'What are your main reasons for wanting to lose weight?',
      fieldText: [
        'weight loss.reason.Improve Physical Appearance',
        'weight loss.reason.Improve Physical Health',
        'weight loss.reason.Improve Mental Health',
        'weight loss.reason.Feel Better',
        'weight loss.reason.Increase Energy',
        'weight loss.reason.Other',
      ],
      selectOne: true,
      elementType: 'checkbox',
      creationTest: () => true,
    },
    {
      headingText: 'What is your other reason for wanting to lose weight?',
      fieldText: 'weight loss.reason.Other details',
      elementType: 'basic',
      maxRows: 3,
      creationTest: (formInputs) => formInputs['weight loss.reason.Other'],
    },
    {
      headingText: 'How long has it been since you were last at your ideal weight?',
      fieldText: 'weight loss.time since ideal weight',
      options: ['<1 year', '1-3 years', 'More than 3 years', 'Never'],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'How would you rate your current diet?',
      fieldText: 'weight loss.current diet',
      options: ['Very healthy', 'Average', 'It could be better'],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'Do you exercise?',
      fieldText: 'weight loss.exercise',
      options: ['Yes, frequently', 'Occasionally', 'Rarely, or never'],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'Do any of the following apply to you?',
      fieldText: [
        'weight loss.eating habits.I frequently snack',
        'weight loss.eating habits.I regularly eat takeaway meals',
        'weight loss.eating habits.I dont eat enough fruit or vegetables',
        'weight loss.eating habits.I drink energy or soft drinks regularly',
      ],
      selectOne: false,
      elementType: 'checkbox',
      creationTest: () => true,
    },
    {
      headingText: 'What about any of the following?',
      fieldText: [
        'weight loss.lifestyle habits.I drink over 2 standard drinks per day',
        'weight loss.lifestyle habits.I often sleep less than 7 hours a night',
        'weight loss.lifestyle habits.I am under a lot of stress',
        'weight loss.lifestyle habits.I use recreational drugs',
      ],
      selectOne: false,
      elementType: 'checkbox',
      creationTest: () => true,
    },
    {
      headingText: 'Do you smoke or vape nicotine?',
      fieldText: 'general health.lifestyle.I smoke or vape nicotine',
      options: [true, false],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'Have you regularly experienced any of the following?',
      fieldText: [
        'weight loss.behaviours.Feeling that food dominates your life',
        'weight loss.behaviours.Eating in secret or hiding from others',
        'weight loss.behaviours.Feeling out of control with how much you eat',
        'weight loss.behaviours.Making yourself intentionally vomit after eating',
        'weight loss.behaviours.Severely restricting how much you eat or limiting which foods you eat',
      ],
      selectOne: false,
      elementType: 'checkbox',
      creationTest: () => true,
    },
    {
      headingText: 'Do any of the following apply to you?',
      fieldText: [
        'weight loss.effect.My weight interferes with my social life',
        'weight loss.effect.My weight impacts my ability to exercise',
        'weight loss.effect.My weight is impacting my mental health',
        'weight loss.effect.My weight is impacting my physical health',
      ],
      selectOne: false,
      elementType: 'checkbox',
      creationTest: () => true,
    },
    {
      headingText: 'Do you have a preference for treatment?',
      fieldText: 'weight loss.preference',
      options: ['Injectable medications', 'Tablet medications', 'I’m open to what my doctor suggests'],
      elementType: 'radio',
      creationTest: () => true,
    },
    ...loginElements(user),
    ...basicInfoElements(userType),
    ...birthDateFormElements(userType),
    pregnancyFormElement(),
    exitFormElement(
      'because you are pregnant / breastfeeding',
      (formInputs) => formInputs['general health.pregnant or breastfeeding'],
    ),
    pregnancyPlanningFormElement(),
    exitFormElement(
      'because you are planning on falling pregnant soon',
      (formInputs) => formInputs['general health.planning pregnancy soon'],
    ),
    {
      headingText: 'Have you tried to lose weight before?',
      fieldText: 'weight loss.previously attempts',
      options: [true, false],
      elementType: 'radio',
      creationTest: () => true,
    },
    {
      headingText: 'What have you tried?',
      fieldText: [
        'weight loss.previously attempted.Dieting',
        'weight loss.previously attempted.Dieticians',
        'weight loss.previously attempted.Fasting',
        'weight loss.previously attempted.Exercise',
        'weight loss.previously attempted.Meal replacement shakes',
        'weight loss.previously attempted.Non-prescription medications',
        'weight loss.previously attempted.Prescription medication',
        'weight loss.previously attempted.Surgery',
      ],
      selectOne: false,
      elementType: 'checkbox',
      creationTest: (formInputs) => formInputs['weight loss.previously attempts'],
    },
    ...createTreatmentElements({
      formName: 'weight loss',
      heading: 'What medication have you used?',
      fields: [
        {
          treatment: 'Semaglutide',
          extraText: 'eg. Ozempic',
        },
        {
          treatment: 'Liraglutide',
          extraText: 'eg. Saxenda',
        },
        {
          treatment: 'Tirzepatide',
          extraText: 'eg. Mounjaro',
        },
        {
          treatment: 'Naltrexone or Bupropion',
          extraText: 'eg. Contrave',
        },
        {
          treatment: 'Phentermine',
          extraText: 'eg. Duromine',
        },
      ],
      creationTest: (formInputs) => formInputs['weight loss.previously attempted.Prescription medication'],
    }),
    ...createConditionElements({
      heading: 'Which surgeries have you had',
      fields: ['Lap Band', 'Roux-en-y Gastric Bypass', 'Sleeve Gastrectomy', 'Gastric Balloon'],
      appenders: ['surgery', 'undergone'],
      creationTest: (formInputs) => formInputs['weight loss.previously attempted.Surgery'],
    }),
    {
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          To make sure what we suggest for you is safe we need to know more about your medical history.
          <br />
          <br />
          These next questions will help us determine if we have a safe treatment option for you.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: () => true,
    },
    ...createConditionElements({
      heading: 'Do suffer from any of the following?',
      fields: ['Type 1 Diabetes', 'Type 2 Diabetes', 'Liver Condition', 'Gallbladder Issues', 'Thyroid Condition'],
      appenders: ['condition', 'diagnosed'],
    }),
    exitFormElement('due to your Type 1 Diabetes', (formInputs) => formInputs['condition.Type 1 Diabetes.diagnosed']),
    exitFormElement(
      'due to your Thyroid Condition',
      (formInputs) => formInputs['condition.Thyroid Condition.diagnosed'],
    ),
    ...createConditionElements({
      heading: 'What about any of the following?',
      fields: [
        'Diabetic Retinopathy',
        'Kidney Issues or Renal Impairment',
        'Pancreatitis or Pancreas Issues',
        'Medullary Thyroid Carcinoma',
        'Multiple Endocrine Neoplasia',
        'Gastroparesis',
      ],
      appenders: ['condition', 'diagnosed'],
    }),
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Injectibles Unsuitable Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Due to your conditions you’re at increased risks from using injectible weight loss medications.
          <br />
          <br />
          However you may still be suitable for oral weight loss options.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        formInputs['condition.Diabetic Retinopathy.diagnosed'] ||
        formInputs['condition.Kidney Issues or Renal Impairment.diagnosed'] ||
        formInputs['condition.Pancreatitis or Pancreas Issues.diagnosed'] ||
        formInputs['condition.Medullary Thyroid Carcinoma.diagnosed'] ||
        formInputs['condition.Multiple Endocrine Neoplasia.diagnosed'] ||
        formInputs['condition.Gastroparesis.diagnosed'],
    },
    ...createConditionElements({
      heading: 'What about any of the following?',
      fields: [
        'Glaucoma',
        'Cardiovascular or Heart Condition',
        'Mood Disorders',
        'Suicidal Thoughts',
        'Hypoglycaemia or Low Blood Sugar',
      ],
      appenders: ['condition', 'diagnosed'],
    }),
    exitFormElement(
      'due to your conditions',
      (formInputs) =>
        formInputs['weight loss.Injectibles Unsuitable Warning'] &&
        (formInputs['condition.Diabetic Retinopathy.diagnosed'] ||
          formInputs['condition.Kidney Issues or Renal Impairment.diagnosed'] ||
          formInputs['condition.Pancreatitis or Pancreas Issues.diagnosed'] ||
          formInputs['condition.Medullary Thyroid Carcinoma.diagnosed'] ||
          formInputs['condition.Multiple Endocrine Neoplasia.diagnosed'] ||
          formInputs['condition.Gastroparesis.diagnosed']),
    ),
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Oral Treatment Unsuitable Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Due to your conditions you’re at increased risks from using oral weight loss medications.
          <br />
          <br />
          However you may still be suitable for injectible weight loss options.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        !formInputs['weight loss.Injectible Risk Warning'] &&
        (formInputs['condition.Glaucoma.diagnosed'] ||
          formInputs['condition.Cardiovascular or Heart Condition.diagnosed'] ||
          formInputs['condition.Mood Disorders.diagnosed']),
    },
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Oral Treatment Suicidal Thoughts Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Oral medications for weight loss may increase risk of suicidal thoughts
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) => formInputs['condition.Suicidal Thoughts.diagnosed'],
    },
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Hypoglycaemia or Low Blood Sugar Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Injectible medications may increase the risk of low blood sugar (hypoglycaemia).
          <br />
          <br />
          If you&apos;re taking medication that decreases blood sugar this risk is increased further.
          <br />
          <br />
          Please discuss this with your doctor during your consultation.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) => formInputs['condition.Hypoglycaemia or Low Blood Sugar.diagnosed'],
    },
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Oral Treatment Unsuitable Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Due to your conditions you’re at increased risks from using oral weight loss medications.
          <br />
          <br />
          However you may still be suitable for injectible weight loss options.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        formInputs['condition.Glaucoma.diagnosed'] ||
        formInputs['condition.Cardiovascular or Heart Condition.diagnosed'],
    },
    ...createConditionElements({
      heading: 'Are you currently using any of the following?',
      fields: ['Insulin', 'Diabetic Medications', 'Contraceptive Pill', 'MAOIs'],
      appenders: ['treatment', 'currently using'],
    }),
    exitFormElement(
      'due to your usage of MAOIs',
      (formInputs) =>
        formInputs['treatment.MAOIs.currently using'] && formInputs['weight loss.Injectibles Unsuitable Warning'],
    ),
    {
      headingText: 'Have you used MAOIs in the last 2 weeks?',
      fieldText: 'weight loss.used maois in the last two weeks',
      options: [true, false],
      elementType: 'radio',
      creationTest: (formInputs) => formInputs['treatment.MAOIs.currently using'],
    },
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Oral Treatment Unsuitable Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Due to your conditions you’re at increased risks from using oral weight loss medications.
          <br />
          <br />
          However you may still be suitable for injectible weight loss options.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        !formInputs['weight loss.Oral Treatment Unsuitable Warning'] && formInputs['treatment.MAOIs.currently using'],
    },
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Blood Sugar Medication Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Injectible medications may increase the risk of low blood sugar (hypoglycaemia).
          <br />
          <br />
          Because you are taking medications that decrease blood sugar this risk is increased further.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        formInputs['treatment.Insulin.currently using'] || formInputs['treatment.Diabetic Medications.currently using'],
    },
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Contraceptive Pill Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Weight loss medications may decrease the effectiveness of the contraceptive pill. Your doctor may recommend
          you use additional contraception while on weight loss treatments.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        formInputs['treatment.Insulin.currently using'] || formInputs['treatment.Diabetic Medications.currently using'],
    },
    {
      headingText: 'Do you have a family history of the following:',
      fieldText: [
        'general health.family history of Thyroid Tumours or Medullary Thyroid Carcinoma',
        'general health.family history of Multiple Endocrine Neoplasia',
        'general health.family history of Pancreatitis',
      ],
      labelText: ['Thyroid Tumours or Medullary Thyroid Carcinoma', 'Multiple Endocrine Neoplasia', 'Pancreatitis'],
      elementType: 'checkbox',
      creationTest: () => true,
    },
    exitFormElement(
      'due to your family history',
      (formInputs) =>
        formInputs['weight loss.Oral Treatment Unsuitable Warning'] &&
        (formInputs['general health.family history of Thyroid Tumours or Medullary Thyroid Carcinoma'] ||
          formInputs['general health.family history of Thyroid Tumours or Medullary Thyroid Carcinoma'] ||
          formInputs['general health.family history of Multiple Endocrine Neoplasia'] ||
          formInputs['general health.family history of Pancreatitis']),
    ),
    {
      headingText: 'Please Note',
      fieldText: 'weight loss.Injectible Risk Warning',
      options: ['Next'],
      subheadingText: (
        <Typography variant="body1" align="center" gutterBottom>
          Due to your family history you’re at increased risks from using injectible weight loss medications.
          <br />
          <br />
          However you may still be suitable for oral weight loss options.
        </Typography>
      ),
      elementType: 'warning',
      creationTest: (formInputs) =>
        !formInputs['weight loss.Injectible Risk Warning'] &&
        (formInputs['general health.family history of Thyroid Tumours or Medullary Thyroid Carcinoma'] ||
          formInputs['general health.family history of Thyroid Tumours or Medullary Thyroid Carcinoma'] ||
          formInputs['general health.family history of Multiple Endocrine Neoplasia'] ||
          formInputs['general health.family history of Pancreatitis']),
    },
    ...finalFormElements('weight loss'),
  ];

  const initialRejection = () => '';
  if (userType === 'patient' || userType === '') {
    return (
      <PatientFormView
        initialRejection={initialRejection}
        formName="weight loss"
        formObjects={formObjects}
        // TODO: Replace with actual stripePrice of weight loss form
        stripePrice="price_g4k9z3BpQHFnDMcEGAik"
      />
    );
  }
  if (userType === 'doctor' || userType === 'nurse' || userType === 'director') {
    return (
      <DoctorFormView
        patient={patient}
        history={history}
        simpleView={simpleView}
        initialRejection={initialRejection}
        formName="weight loss"
        formObjects={formObjects}
        // TODO: Replace with actual stripePrice of weight loss form
        stripePrice="price_g4k9z3BpQHFnDMcEGAik"
      />
    );
  }
  return <Loading />;
};

export default WeightLossForm;
