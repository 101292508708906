import React from 'react';
import { Grid, Typography } from '@mui/material';

const ValaciclovirLignocaineInformation = () => (
  <Grid item>
    <Typography variant="h6">About Valaciclovir</Typography>
    <br />
    <Typography variant="body1">
      Valaciclovir is an antiviral medication used to treat Herpes Simplex Virus (HSV). HSV 1 and HSV 2 cause cold sores
      and genital herpes.
      <br />
      <br />
      Valaciclovir prevents the virus from replicating and can help minimise symptoms or prevent attacks. It is
      important to know Valaciclovir does not cure HSV, but it does make outbreaks much less severe.
      <br />
      <br />
      Valaciclovir also helps decrease how likely you are to transmit HSV to other people. While it helps prevent
      transmission we still recommend you take precautions to avoid spreading HSV, in particular, avoiding intimate
      contact when you have an outbreak.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Dosage of Valaciclovir depends on if you are treating cold sores or genital herpes, and if you are treating an
      outbreak or aiming to prevent outbreaks. While the same medication is used for all the above, the timing and
      dosage varies. See your personalised prescription for this information.
      <br />
      <br />
      If you would like to change this please discuss with your Candor GP.
      <br />
      <br />
      If treating an outbreak it is most effective to take Valaciclovir as soon as you notice symptoms. This will
      prevent the virus from replicating much more effectively.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Valaciclovir is a commonly used medication and side effects are generally mild. However it is important to be
      aware of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Headache
      <br />
      - Vomiting
      <br />
      - Nausea
      <br />
      - Diarrhoea
      <br />
      <br />
      Infrequent (0.1 - 1%)
      <br />
      - Agitation
      <br />
      - Vertigo
      <br />
      - Confusion
      <br />
      - Dizziness
      <br />
      - Renal impairment
      <br />
      - Sore throat
      <br />
      - Arthralgia
      <br />
      - Constipation
      <br />
      - Rash
      <br />
      - Abdominal pain
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Seizures
      <br />
      - Anorexia
      <br />
      - Fatigue
      <br />
      - Hepatitis
      <br />
      - Neutropenia
      <br />
      - Leucopenia
      <br />
      - Anaphylaxis
      <br />
      - Stevens-Johnson syndrome
      <br />
      - Toxic epidermal necrolysis
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Valaciclovir including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Valaciclovir when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Possibly. There are better alternatives available when pregnant and it’s best to discuss these with your regular
      GP to ensure the safest treatment.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Valaciclovir when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
    <br />
    <br />
    <Typography variant="h6">About Lignocaine</Typography>
    <br />
    <Typography variant="body1">
      Lignocaine is a topical anaesthetic that can be used to decrease the pain and discomfort of genital herpes. This
      can be particularly useful for a first outbreak.
      <br />
      <br />
      Lignocaine does not treat herpes itself, but can improve symptoms so that it can be tolerated.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Lignocaine gel should be applied in a thin layer to genital lesions. Initially you may feel a tingling or
      increased discomfort but this should settle within a couple of minutes. The numbing effect may last up to 4 hours.
      <br />
      <br />
      Lignocaine can be reapplied as needed. We recommend using the lowest effective dose for the shortest amount of
      time.
      <br />
      <br />
      Make sure you wash your hands thoroughly after use.
      <br />
      <br />
      Do not use internally.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Lignocaine is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Application site reactions
      <br />
      - Erythema, itch, burning sensation
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Allergic reaction
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Lignocaine including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use topical Lignocaine when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Yes but we recommend avoidance in the first trimester. Please discuss with your Candor or regular GP prior to use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use topical Lignocaine when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default ValaciclovirLignocaineInformation;
