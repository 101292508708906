import { dass21Obj, adhdScaleObj, ptsdScaleObj, painScaleObj } from '../components/form/mini-forms/mini-form-objects';

const processMiniForms = (formInputs) => {
  let masterObj = {};
  const time = Date.now();
  const checkStr = 'medicinal cannabis.wanted for';

  const calculateScore = (miniFormObj, keysNeeded, stringKeysNeeded) => {
    const updateString = `${miniFormObj.formName} Scores.${time}`;
    const dataObj = {};
    let scoreRef = 'Score';
    // Perform sumbs on number key updates
    keysNeeded.forEach((key) => {
      dataObj[`${updateString}.${key}`] = 0;
    });
    for (let i = 0; i < miniFormObj.questions.length; i += 1) {
      if (miniFormObj.formName === 'Dass 21') {
        if ([1, 6, 8, 11, 12, 14, 18].includes(i + 1)) {
          scoreRef = 'Stress';
        } else if ([2, 4, 7, 9, 15, 19, 20].includes(i + 1)) {
          scoreRef = 'Anxiety';
        } else if ([3, 5, 10, 13, 16, 17, 21].includes(i + 1)) {
          scoreRef = 'Depression';
        }
      }
      if (miniFormObj.formName === 'ADHD Scale') {
        scoreRef = i <= 8 ? 'Part A' : 'Part B';
      }
      if (miniFormObj.formName === 'Pain Scale') {
        if (i <= 8) {
          scoreRef = 'Questions Score';
        }
      }
      dataObj[`${updateString}.${scoreRef}`] += miniFormObj.options.findIndex(
        (op) => op === formInputs[`${miniFormObj.formName}.${miniFormObj.questions[i]}`],
      );
    }
    // Handle String Key Updates
    if (stringKeysNeeded) {
      stringKeysNeeded.forEach((key) => {
        dataObj[`${updateString}.${key}`] = formInputs[`${miniFormObj.formName}.${key}`];
      });
    }

    return dataObj;
  };

  if (
    formInputs[`${checkStr} Migraines`] ||
    formInputs[`${checkStr} Multiple Sclerosis`] ||
    formInputs[`${checkStr} Fibromyalgia`] ||
    formInputs[`${checkStr} Chronic Pain`] ||
    formInputs[`${checkStr} Cancer Related Pain`] ||
    formInputs[`${checkStr} Neuropathic Pain`]
  ) {
    masterObj = {
      ...masterObj,
      ...calculateScore(
        painScaleObj,
        ['Questions Score'],
        [
          'How many days a week do you experience pain?',
          "How severe is your pain at it's worst?",
          "How severe is your pain at it's best?",
          'Are you ever pain free?',
        ],
      ),
    };
  }
  if (formInputs[`${checkStr} Anxiety`] || formInputs[`${checkStr} Depression`])
    masterObj = { ...masterObj, ...calculateScore(dass21Obj, ['Stress', 'Anxiety', 'Depression']) };
  if (formInputs[`${checkStr} ADHD`])
    masterObj = { ...masterObj, ...calculateScore(adhdScaleObj, ['Part A', 'Part B']) };
  if (formInputs[`${checkStr} PTSD`]) masterObj = { ...masterObj, ...calculateScore(ptsdScaleObj, ['Score']) };

  return masterObj;
};

const parseMiniForms = (medCan) => {
  const componentsArray = [];
  if (
    medCan['wanted for Migraines'] ||
    medCan['wanted for Multiple Sclerosis'] ||
    medCan['wanted for Fibromyalgia'] ||
    medCan['wanted for Chronic Pain'] ||
    medCan['wanted for Cancer Related Pain'] ||
    medCan['wanted for Neuropathic Pain']
  )
    componentsArray.push(painScaleObj);
  if (medCan['wanted for Anxiety'] || medCan['wanted for Depression']) componentsArray.push(dass21Obj);
  if (medCan['wanted for PTSD']) componentsArray.push(ptsdScaleObj);
  if (medCan['wanted for ADHD']) componentsArray.push(adhdScaleObj);
  return componentsArray;
};

const getConditions = (formInputs, prepender, appender) => {
  const array = [];
  Object.entries(formInputs).forEach((entry) => {
    const [key, value] = entry;
    if (key.startsWith(prepender) && key.endsWith(appender) && value) {
      const newKey = key.replace(prepender, '').replace(appender, '');
      array.unshift(newKey);
    }
  });
  return array;
};

const getTreatmentsTried = (formInputs) => {
  const treatmentArr = [];
  const treatmentsStr = 'medicinal cannabis.wanted for ';
  Object.entries(formInputs).forEach((entry) => {
    const treatmentsTried = [];
    const [key, value] = entry;
    if (key.startsWith(treatmentsStr) && value) {
      const newKey = key.replace(treatmentsStr, '');

      const inputFields = {
        prepender: `${newKey} treatments.`,
        slimTextOne: '.used',
        slimTextTwo: '.duration',
      };

      Object.entries(formInputs).forEach((masterObjEntry) => {
        const masterObj = {};
        const [masterObjKey, masterObjValue] = masterObjEntry;
        if (
          masterObjKey.startsWith(inputFields.prepender) &&
          masterObjKey.endsWith(inputFields.slimTextOne) &&
          masterObjValue
        ) {
          const obj = {};
          const shortenedKey = masterObjKey.replace(inputFields.slimTextOne, '');
          const treatmentKey = shortenedKey.replace(inputFields.prepender, '');
          obj[inputFields.slimTextTwo] = formInputs[shortenedKey + inputFields.slimTextTwo];
          masterObj[treatmentKey] = obj;
          const formattedData = `${treatmentKey} (${obj['.duration']})`;
          treatmentsTried.push(formattedData);
        }
      });
      if (treatmentsTried.length > 0) {
        treatmentArr.unshift(`${newKey} - ${treatmentsTried.join(', ')}`);
      }
    }
  });
  console.log('treatmentArr: ', treatmentArr);
  return treatmentArr.join(', ');
};

export { processMiniForms, parseMiniForms, getConditions, getTreatmentsTried };
