import React, { useState } from 'react';
import { Typography } from '@mui/material';

import PaymentFormElementPresentation from '../presentation-elements/payment-form-element-presentation';
import ExitFormElement from './exit-form-element';

const PaymentFormElement = (props) => {
  const { stripePrice, formName, consultPaid, handleNext } = props;

  const [modalOpen, setModalOpen] = useState(false);

  const handleInput = async () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  if (consultPaid || stripePrice === null) {
    const obj = {
      fieldText: (
        <>
          <Typography style={{ marginBottom: 30 }} variant="h5" align="center" gutterBottom>
            Form Complete
          </Typography>
          <Typography variant="body1" align="center">
            Your information has been submitted. You will receive an update via email shortly.
            <br />
            <br />
            If you need to change or update any information you can contact a Candor nurse through the Messages tab.
          </Typography>
        </>
      ),
    };
    return <ExitFormElement currentFormObject={obj} handleNext={handleNext} buttonText="Dashboard" />;
  }

  return (
    <PaymentFormElementPresentation
      handleModalClose={handleModalClose}
      modalOpen={modalOpen}
      handleInput={handleInput}
      stripePrice={stripePrice}
      formName={formName}
    />
  );
};

export default PaymentFormElement;
