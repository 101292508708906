import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button, TextField } from '@mui/material';
import { confirmPasswordReset, signInWithEmailAndPassword } from '@firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { auth } from '../../../firebase-config';
import SecondFactorLogin from '../second-factor-login';

const PREFIX = 'ResetPasswordInput';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  logo: `${PREFIX}-logo`,
  paper: `${PREFIX}-paper`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.input}`]: {
    margin: '20px auto',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto',
  },

  [`& .${classes.logo}`]: {
    margin: '20px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.paper}`]: {
    padding: '20px',
    width: '90%',
    maxWidth: '400px',
  },
}));

const ResetPasswordInput = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isMFARequired, setIsMFARequired] = useState(false);
  const [authError, setAuthError] = useState(null);

  const handlePasswordUpdateButton = (isMFA = false) => {
    if (isMFA) {
      // If the user is trying to update their password via 2fa, navigate to dashboard
      navigate('/auth-landing-page-success', { state: { operation: 'RESET_PASSWORD' } });
    }
    confirmPasswordReset(auth, location.state?.urlActionCode, newPassword)
      .then(() => {
        signInWithEmailAndPassword(auth, location.state?.email, newPassword)
          .then(() => {
            navigate('/auth-landing-page-success', { state: { operation: 'RESET_PASSWORD' } });
          })
          .catch((error) => {
            switch (error.code) {
              case 'auth/multi-factor-auth-required':
                setIsMFARequired(true);
                setAuthError(error);
                break;
              default:
                Sentry.captureMessage('Error: Logging in with password after confirming password reset', {
                  level: 'error',
                  extra: { errorCode: error.code, operation: 'RESET_PASSWORD' },
                });
            }
          });
      })
      .catch((error) => {
        Sentry.captureMessage('Error: Confirm password reset', {
          level: 'error',
          extra: { errorCode: error.code, operation: 'RESET_PASSWORD' },
        });
        setPasswordError(error);
      });
  };
  return (
    <StyledContainer maxWidth="md">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '70vh' }}
      >
        <Paper className={classes.paper}>
          {isMFARequired ? (
            <SecondFactorLogin forgotPasswordUpdate={handlePasswordUpdateButton} error={authError} />
          ) : (
            <>
              <Typography variant="h5" align="center" gutterBottom>
                New Password
              </Typography>
              <Typography variant="body1" align="center" gutterBottom>
                Please choose a new password
              </Typography>
              <TextField
                type="password"
                label="Password"
                variant="outlined"
                className={classes.input}
                required
                onChange={(e) => setNewPassword(e.target.value)}
                error={!(passwordError === '')}
                helperText={passwordError}
              />
              <br />
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => handlePasswordUpdateButton()}
              >
                Update & Login
              </Button>
            </>
          )}
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default ResetPasswordInput;
