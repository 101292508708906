import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Container, CircularProgress, Paper, Typography, Button } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PREFIX = 'ModalCard';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  gridItem: `${PREFIX}-gridItem`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
};

const StyledContainer = styled(Container)(() => ({
  height: '100%',
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },

  [`& .${classes.cancelButton}`]: {
    margin: '-25px -35px',
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
    height: 20,
  },
}));

const ModalCard = (props) => {
  const { text, subtext, handleModalClose, loadingComponent } = props;

  return (
    <StyledContainer>
      <Grid container className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid item className={classes.cancelButtonHolder}>
            <Button className={classes.cancelButton} onClick={handleModalClose}>
              <CancelRoundedIcon color="primary" />
            </Button>
          </Grid>
          <Grid container>
            {loadingComponent && (
              <Grid item xs={12} className={classes.gridItem}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} className={classes.gridItem}>
              <Typography variant="h5" align="center" gutterBottom>
                {text}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="center" gutterBottom>
                {subtext}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default ModalCard;
