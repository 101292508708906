import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { captureMessage as SentryCaptureMessage } from '@sentry/react';

import { auth } from '../../firebase-config';
import SecondFactorLogin from './second-factor-login';
import RequestPasswordReset from './request-password-reset';
import EmailAuthPresentation from './email-auth-presentation';
import { logSignUpEvent } from '../../analytics/logAnalyticsEvent';
import createPatientData from '../../functions/create-patient-data';

const INVALID_EMAIL = 'auth/invalid-email';

const EmailAuthCards = ({
  index = 0,
  formName = '',
  quickLogin = false,
  formContext = false,
  formInputs = undefined,
  handleModalClose = () => {},
}) => {
  const [passwordResetCard, setPasswordResetCard] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [hasAccount, setHasAccount] = useState(quickLogin);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isTwoFactorRequired, setIsTwoFactorRequired] = useState(false);
  const [isSignupLoading, setIsSignupLoading] = useState(false);
  const [authError, setAuthError] = useState(null);

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  };

  const toggleHasAccount = async () => {
    setHasAccount(!hasAccount);
    clearErrors();
  };

  const handleLogin = async () => {
    clearErrors();
    try {
      await signInWithEmailAndPassword(auth, email.trim(), password);
    } catch (error) {
      setAuthError(error);
      switch (error.code) {
        case 'auth/missing-password':
          setPasswordError('Password is missing. Please provide a password to login.');
          break;
        case 'auth/user-disabled':
          setEmailError('This account has been disabled. Please contact support.');
          break;
        case 'auth/multi-factor-auth-required':
          setIsTwoFactorRequired(true);
          setEmailError('This account requires multi-factor authentication. Please contact support.');
          break;
        case INVALID_EMAIL:
        case 'auth/user-not-found':
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
          setEmailError('Sorry, your login details are incorrect. Please try again.');
          break;
        default:
          setEmailError(`An unexpected error occured. Please contact support@candor.org with ${error.code}`);
      }
    }
  };

  const handleSignUp = async () => {
    setIsSignupLoading(true);
    clearErrors();
    const cleanseEmail = email.trim().toLowerCase();
    try {
      const cred = await createUserWithEmailAndPassword(auth, cleanseEmail, password);
      logSignUpEvent();

      if (formContext) {
        // This should be moved into the data handling function but it seems to effect writes
        await createPatientData({ userId: cred.user.uid, userEmail: cleanseEmail, formName, formInputs, index });
      } else {
        await createPatientData({ userId: cred.user.uid, userEmail: cleanseEmail });
      }
      await sendEmailVerification(cred.user);
      setIsSignupLoading(false);
    } catch (error) {
      switch (error.code) {
        case INVALID_EMAIL:
          setEmailError('This email is not valid');
          break;
        case 'auth/email-already-in-use':
          setEmailError('This email is already in use');
          break;
        case 'auth/weak-password':
          setPasswordError('The password must be at least 6 characters');
          break;
        case 'auth/operation-not-allowed':
          setPasswordError('This account has been disabled. Please contact support.');
          break;
        default:
          setEmailError('Unknown error. Please contact support');
      }
      setIsSignupLoading(false);
    }
  };

  const handleForgottenPasswordButton = () => {
    clearErrors();
    setPasswordResetCard(true);
  };

  const handleBackButton = () => {
    clearErrors();
    setPasswordResetCard(false);
  };

  const handlePasswordResetRequest = async (userEmail) => {
    const cleanedEmail = userEmail.trim().toLowerCase();
    try {
      await sendPasswordResetEmail(auth, cleanedEmail);
      setConfirmEmail('');
      setSnackbarOpen(true);
      clearErrors();
    } catch (error) {
      if (error.code === INVALID_EMAIL) {
        setEmailError('This email is not valid');
      } else {
        SentryCaptureMessage(error, {
          level: 'warning',
          extra: { issueIn: 'handlePasswordResetRequest' },
        });
        setEmailError('Unknown error. Please contact support');
      }
    }
  };

  const handleEmailChange = (e) => {
    setEmailError('');
    setConfirmEmail(e.target.value);
  };

  if (passwordResetCard) {
    return (
      <RequestPasswordReset
        confirmEmail={confirmEmail}
        handleEmailChange={handleEmailChange}
        handlePasswordResetRequest={handlePasswordResetRequest}
        emailError={emailError}
        setPasswordResetCard={setPasswordResetCard}
        handleBackButton={handleBackButton}
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    );
  }

  if (isTwoFactorRequired) {
    return <SecondFactorLogin error={authError} />;
  }

  return (
    <EmailAuthPresentation
      handleForgottenPasswordButton={handleForgottenPasswordButton}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      handleLogin={handleLogin}
      handleSignUp={handleSignUp}
      hasAccount={hasAccount}
      setHasAccount={setHasAccount}
      emailError={emailError}
      passwordError={passwordError}
      toggleHasAccount={toggleHasAccount}
      formContext={formContext}
      quickLogin={quickLogin}
      handleModalClose={handleModalClose}
      isSignupLoading={isSignupLoading}
    />
  );
};

// TODO: Rename this to use isFormContext instead
// Think of a better name as well, very confusing to use Context in the name
// TODO: Rename quickLogin to something more descriptive
// TODO: Rename index to something more descriptive
EmailAuthCards.propTypes = {
  index: PropTypes.number,
  formName: PropTypes.string,
  quickLogin: PropTypes.bool,
  formContext: PropTypes.bool,
  formInputs: PropTypes.shape({
    // TODO: FIXME: Set the correct shape for formInputs
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
  }),
  handleModalClose: PropTypes.func,
};

export default EmailAuthCards;
