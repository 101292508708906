import React from 'react';
import EnforceTwoFactor from '../account-details/misc-section/enforce-two-factor';
import UnverifiedRouter from './unverified-router';
import { useAccountStatus } from './useAccountStatus';
import { UnauthenticatedLayout, VerifiedUserLayout } from './layouts';
import Loading from '../layout/loading';

const MasterRouter = () => {
  const { user, isAuthLoading, is2faRequired, isAccountPending, handleLogout, isEmailVerified } = useAccountStatus();
  if (isAuthLoading) {
    return <Loading />;
  }

  if (!user) {
    return <UnauthenticatedLayout />;
  }
  // When the user is not verified, they will be redirected to the request verification page or form pages
  if (!user.emailVerified || !isEmailVerified) {
    return <UnverifiedRouter handleLogout={handleLogout} />;
  }

  if (is2faRequired) {
    return <EnforceTwoFactor handleLogout={handleLogout} />;
  }

  return <VerifiedUserLayout isAccountPending={isAccountPending} handleLogout={handleLogout} />;
};

export default MasterRouter;
