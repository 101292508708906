import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box, Divider, styled } from '@mui/material';

const StyledBox = styled(Box)({
  marginBottom: '10px',
  '@media (max-width: 600px)': {
    h6: {
      fontSize: '24px',
    },
  },
});

const FieldsBox = ({ label, value = 'N/A' }) => (
  <StyledBox mb={2}>
    <Typography textTransform="uppercase" variant="body1" fontWeight={300} fontSize="12px">
      {label}
    </Typography>
    <Typography variant="h6" fontWeight={700} fontSize="28px">
      {label === 'Price' && '$'}
      {value || 'N/A'}
    </Typography>
  </StyledBox>
);

const NicotineVapeInformation = ({ treatmentDetails }) => (
  <Grid item>
    <Divider />
    <br />
    <Grid container direction="column" alignItems="center" justifyContent="center">
      <Typography variant="h6" fontWeight="600" textAlign="center">
        {treatmentDetails.item}
      </Typography>
      <Grid container textAlign="center" spacing={2} mt={2}>
        <Grid item xs={6}>
          <FieldsBox label="Brand" value={treatmentDetails.brand} />
          <FieldsBox label="Type" value={treatmentDetails.type} />
          <FieldsBox label="Active Ingredients" value={treatmentDetails.ingredients} />
          <FieldsBox label="Nicotine (in mg)" value={treatmentDetails.nicotineMg} />
          <FieldsBox label="Strength (in %)" value={treatmentDetails.strength} />
        </Grid>
        <Grid item xs={6}>
          <FieldsBox label="Model" value={treatmentDetails.model} />
          <FieldsBox label="Price" value={treatmentDetails.price} />
          <FieldsBox label="Nicotine Type" value={treatmentDetails.nicotineType} />
          <FieldsBox label="Puffs" value={treatmentDetails.puffs} />
          <FieldsBox label="Amount (in ml)" value={treatmentDetails.amount} />
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        justifyContent="center"
        alignItems="start"
        textAlign="center"
        display="flex"
        flexDirection="column"
      >
        <Typography textTransform="uppercase" variant="body1" fontWeight={400}>
          Prescription Details:
        </Typography>
        {treatmentDetails.prescription.length === 0 ? (
          <Typography variant="h6">N/A</Typography>
        ) : (
          <Box ml={2} sx={{ width: '300px' }}>
            <ul style={{ paddingLeft: 0 }}>
              {treatmentDetails.prescription.map((item) => (
                <li key={item}>
                  <Typography variant="body1" fontWeight={600} textAlign="left">
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Grid>
    </Grid>
    <br />
    <Divider />
    <br />
    <Typography variant="h6">About Nicotine Vapes</Typography>
    <Typography variant="body1">
      <br />
      <strong>What are Nicotine Vapes?</strong> Nicotine vapes, also known as electronic cigarettes or e - cigarettes,
      are battery - operated devices that heat a liquid containing nicotine, flavorings, and other chemicals to create
      an aerosol that patients inhale. These devices come in various shapes and sizes, including vape pens and pod
      systems.
      <br />
    </Typography>
    <br />
    <Typography variant="h6">How Can Nicotine Vapes Help with Quitting Smoking?</Typography>
    <br />
    <Typography variant="body1">
      Nicotine vapes can potentially help you to quit smoking by providing nicotine without the harmful toxins found in
      traditional tobacco smoke. They simulate the sensation of smoking by delivering nicotine in a vapor form. This can
      help reduce withdrawal symptoms and cravings associated with quitting smoking.
    </Typography>
    <br />
    <Typography variant="h6">Things to Consider Before Using Nicotine Vapes:</Typography>
    <Box sx={{ fontFamily: 'Roboto', fontWeight: 400, fontSize: '1rem' }}>
      <ul>
        <li>
          <strong>Choose the Right Device:</strong> There are various types of nicotine vape devices available. Consider
          factors such as ease of use and nicotine strength when selecting a device that suits your preferences. Your
          Candor doctor can help you with this.
        </li>
        <br />
        <li>
          <strong>Understand Nicotine Levels:</strong> Nicotine levels in vape liquids vary, ranging from high to low
          concentrations. It is essential to choose the appropriate nicotine strength based on your current smoking
          habits and nicotine dependence. Starting with a higher nicotine level and gradually reducing it over time can
          be an effective approach for some individuals.
        </li>
        <br />
        <li>
          <strong>Be Mindful of Potential Risks:</strong> While nicotine vapes are generally considered less harmful
          than traditional cigarettes, they are not risk-free. Some potential risks associated with vaping include
          nicotine addiction, exposure to harmful chemicals, and adverse effects on lung health. It is essential to
          weigh the potential benefits and risks before using nicotine vapes for smoking cessation.
        </li>
        <br />
        The risks associated with long-term vaping are unknown. While vaping is less harmful than smoking it is unlikely
        to be totally harm free. Ideally, smokers who are vaping to quit should look to eventually stop vaping too.
      </ul>
      <Typography variant="h6">How to use:</Typography>
      <ul>
        <li>
          <strong>Technique:</strong> Unlike traditional cigarettes where you take quick, deep inhales, vaping requires
          a slower and gentler inhalation technique.
          <ul>
            <li>
              Take a slow, steady draw from the mouthpiece, allowing the vapor to linger in your mouth briefly before
              inhaling it into your lungs.
            </li>
            <li>This technique helps maximize nicotine absorption and flavor delivery.</li>
            <li>
              If the vape has a draw-activated mechanism, simply inhale from the mouthpiece to activate the device.
            </li>
            <li>
              If the device has a button, press the button while inhaling to activate the heating element and produce
              vapor.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <strong>Monitor Nicotine Consumption:</strong> Pay attention to your nicotine intake and adjust the nicotine
          strength of your e-liquid accordingly. If you find yourself experiencing nicotine cravings or withdrawal
          symptoms, consider using a higher nicotine concentration initially and gradually tapering down as you progress
          in your quit journey.
        </li>
        <br />
        <li>
          <strong>Avoid Chain Vaping:</strong> Chain vaping, or taking multiple puffs in rapid succession, can lead to
          nicotine overdose and unpleasant side effects such as dizziness, nausea, and headaches. Take breaks between
          puffs and pace yourself to avoid consuming excessive amounts of nicotine.
        </li>
        <br />
      </ul>

      <Typography variant="h6">Tips for Quitting Smoking Using Nicotine Vapes: </Typography>
      <ul>
        <li>
          <strong>Set Realistic Goals: </strong>Set achievable goals for reducing your smoking or quitting altogether.
          Gradually decrease the number of cigarettes smoked per day while incorporating nicotine vapes as a substitute.
        </li>
        <br />
        <li>
          <strong>Use Support Resources: </strong>Use support resources such as smoking cessation programs, counseling
          services, and online support groups to increase your chances of success.
        </li>
        <br />
        <li>
          <strong>Stay Persistent: </strong>Quitting smoking can be challenging, but persistence is key. Stay committed
          to your goal of quitting and remind yourself of the health benefits of becoming smoke-free.
        </li>
        <br />
        <li>
          <strong>Monitor Your Progress: </strong>
          Keep track of your progress by noting changes in your smoking habits, cravings, and overall well-being.
          Celebrate milestones and accomplishments along the way to stay motivated.
        </li>
        <br />
        <li>
          <strong>Seek Help if Needed: </strong>If you encounter difficulties or setbacks during your quit journey,
          don&apos;t hesitate to seek help from healthcare professionals or support networks. They can provide guidance,
          encouragement, and additional resources to assist you in overcoming challenges.
        </li>
      </ul>
    </Box>
  </Grid>
);

FieldsBox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FieldsBox.defaultProps = {
  value: 'N/A',
};

NicotineVapeInformation.propTypes = {
  treatmentDetails: PropTypes.shape({
    item: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    puffs: PropTypes.number,
    nicotineMg: PropTypes.number,
    brand: PropTypes.string,
    model: PropTypes.string,
    strength: PropTypes.number,
    nicotineType: PropTypes.string,
    amount: PropTypes.number,
    price: PropTypes.number,
    ingredients: PropTypes.string,
    prescription: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default NicotineVapeInformation;
