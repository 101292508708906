import React, { useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField as Input,
  Tooltip,
  Typography,
  Alert,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';

import MastercardImage from '../../../media/acceptedPayments/Mastercard_Primary.svg';
import VisaImage from '../../../media/acceptedPayments/Visa_Blue.svg';
import Selection from '../../catalogue/manage-product/SelectionField';

const SPACE_BETWEEN = 'space-between';

const YEAR_INCREMENT = 20;

const getYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const twentyYearsFromNow = currentYear + YEAR_INCREMENT; // Fetching 20 years from the current year
  return Array.from({ length: twentyYearsFromNow - currentYear }, (_, i) => String(i + currentYear));
};

const getMonthOptions = () => Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0'));

const StyledNumberDiv = styled('div')({
  height: '40px',
  width: '80%',
  '@media (max-width: 1315px)': { width: '70%' },
  '@media (max-width: 1100px)': { width: '65%' },
  '@media (max-width: 600px)': { width: 'auto' },
});

const TillPaymentForm = ({
  isSubmitting,
  handlePaymentFormSubmit,
  cardHolderName = '',
  month = '',
  year = '',
  handleInput,
  cardType = null,
  cardHolderNameError = '',
  monthError = '',
  yearError = '',
  cvvError = '',
  cardNumberError = '',
  errorMessage = '',
}) => {
  // If cardType is not set, show all payment logos from the accepted payments
  const showPaymentLogo = (type) => !cardType || cardType === type || !['visa', 'mastercard'].includes(cardType);

  const doesErrorExist = useMemo(
    () => cardHolderNameError || monthError || yearError || cvvError || cardNumberError,
    [cardHolderNameError, monthError, yearError, cvvError, cardNumberError],
  );

  return (
    <Grid container spacing={2} style={{ textAlign: 'left' }}>
      <Grid item xs={12} sm={12}>
        <Box>
          <InputLabel sx={{ py: 0.5, color: '#49454F' }}>Cardholder Name</InputLabel>
          <Input
            fullWidth
            id="name"
            name="name"
            value={cardHolderName}
            onChange={(e) => handleInput(e, 'cardHolderName')}
            placeholder="e.g John Doe"
            size="small"
            disabled={isSubmitting}
            error={cardHolderNameError?.length > 0}
            helperText={cardHolderNameError}
            sx={{ '& .Mui-error': { margin: '5px 0 0 0' } }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box>
          <InputLabel sx={{ py: 0.5, color: '#49454F' }}>Card Number</InputLabel>
          <Box sx={{ display: 'flex', justifyContent: SPACE_BETWEEN, width: '100%', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: SPACE_BETWEEN, width: '100%', alignItems: 'center' }}>
              <StyledNumberDiv id="number_div" />
              <Box
                sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginLeft: '10px', width: '20%' }}
              >
                {showPaymentLogo('mastercard') && (
                  <Box
                    component="img"
                    sx={{
                      height: '30px',
                      width: 'auto',
                      maxWidth: '35px',
                      '@media (max-width: 600px)': { maxWidth: '23px' },
                    }}
                    alt="Mastercard"
                    src={MastercardImage}
                  />
                )}
                {showPaymentLogo('visa') && (
                  <Box
                    component="img"
                    sx={{
                      height: '30px',
                      width: 'auto',
                      maxWidth: '35px',
                      '@media (max-width: 600px)': { maxWidth: '23px' },
                    }}
                    alt="Visa"
                    src={VisaImage}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {cardNumberError?.length > 0 && (
            <Typography variant="caption" color="error">
              {cardNumberError}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid
        container
        item
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
          justifyContent: SPACE_BETWEEN,
        }}
      >
        <Grid container sx={{ display: 'flex', justifyContent: SPACE_BETWEEN }}>
          <Grid item xs={12} md={8}>
            <FormControl sx={{ width: '100%' }}>
              <FormLabel sx={{ py: 1, color: '#49454F' }}>Expiry Date</FormLabel>
              <FormGroup row sx={{ width: '100%' }}>
                <Grid container spacing={1} sx={{ display: 'flex', justifyContent: SPACE_BETWEEN }}>
                  <Grid item xs={5} md={6}>
                    <Selection
                      size="small"
                      label="Month"
                      value={month}
                      onChange={(e) => handleInput(e, 'month')}
                      type="number"
                      disabled={isSubmitting}
                      error={monthError}
                    >
                      {getMonthOptions().map((monthItem) => (
                        <MenuItem key={monthItem} value={monthItem}>
                          {monthItem}
                        </MenuItem>
                      ))}
                    </Selection>
                  </Grid>
                  <Grid item xs={5} md={6}>
                    <Selection
                      size="small"
                      label="Year"
                      value={year}
                      onChange={(e) => handleInput(e, 'year')}
                      type="number"
                      disabled={isSubmitting}
                      error={yearError}
                    >
                      {getYearOptions().map((yearItem) => (
                        <MenuItem key={yearItem} value={yearItem}>
                          {yearItem}
                        </MenuItem>
                      ))}
                    </Selection>
                  </Grid>
                </Grid>
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4} md={3}>
            <Box>
              <Box sx={{ py: 1, display: 'flex', alignItems: 'center' }}>
                <InputLabel sx={{ color: '#49454F' }}>CVV</InputLabel>
                <Tooltip title="CVV is the 3 digit number at the back of your card" placement="top">
                  <HelpIcon size="small" color="primary" sx={{ fontSize: '16px', m: '0 5px' }} />
                </Tooltip>
              </Box>
              <div id="cvv_div" style={{ height: '40px' }} />
              {cvvError?.length > 0 && (
                <Typography variant="caption" color="error">
                  Invalid CVV
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} sx={{ display: errorMessage ? 'block' : 'none' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Grid>
      <Grid container item>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting || doesErrorExist}
          onClick={handlePaymentFormSubmit}
          sx={{
            width: '100%',
            mt: 2,
            py: 1.3,
            fontWeight: '700',
            display: 'flex',
            fontSize: '1rem',
          }}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Pay Now'}
        </Button>
      </Grid>
    </Grid>
  );
};

TillPaymentForm.propTypes = {
  cardType: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  handlePaymentFormSubmit: PropTypes.func.isRequired,
  cardHolderName: PropTypes.string,
  month: PropTypes.string,
  year: PropTypes.string,
  handleInput: PropTypes.func.isRequired,
  cardHolderNameError: PropTypes.string,
  monthError: PropTypes.string,
  yearError: PropTypes.string,
  cvvError: PropTypes.string,
  cardNumberError: PropTypes.string,
  errorMessage: PropTypes.string,
};

export default TillPaymentForm;
