import React, { useContext, useEffect } from 'react';

import PaymentContext from '../PaymentContext';
import CustomPayment from './CustomPayment';
import { PAYMENT_PROCESSORS, getPaymentProcessor } from '../paymentHelpers';

const { NOVATTI, TILL } = PAYMENT_PROCESSORS;

const CustomPaymentContainer = () => {
  const { paymentProcessor, setPaymentProcessor } = useContext(PaymentContext);

  useEffect(() => {
    const initialLoad = async () => {
      const tempPaymentProcessor = await getPaymentProcessor();
      setPaymentProcessor(tempPaymentProcessor);
    };
    initialLoad();
  }, [setPaymentProcessor]);

  if (paymentProcessor === NOVATTI) {
    return (
      <CustomPayment
        functionName="novattiCreateOrderCustom"
        paymentCollectionName="novattiOrders"
        iFrameUrlAddress="iframe_checkout"
      />
    );
  }

  if (paymentProcessor === TILL) {
    return <CustomPayment />;
  }

  return null;
};

export default CustomPaymentContainer;
