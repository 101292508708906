import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { getDoc, doc } from 'firebase/firestore';
import { Grid, Paper, Button, Divider, Typography, Box } from '@mui/material';

import { db } from '../../firebase-config';
import Loading from '../layout/loading';
import {
  CannabisInfoSheet,
  SlindaInformation,
  OzempicInformation,
  MounjaroInformation,
  MinoxidilInformation,
  TadalafilInformation,
  MelatoninInformation,
  AciclovirInformation,
  TretinoinInformation,
  DoxylamineInformation,
  SildenafilInformation,
  CetirizineInformation,
  LignocaineInformation,
  FinasterideInformation,
  FamciclovirInformation,
  DoxycyclineInformation,
  OlopatadineInformation,
  FluticasoneInformation,
  NicotineVapeInformation,
  ValaciclovirInformation,
  PromethazineInformation,
  DoxycyclineTretinoinInformation,
  FinasterideMinoxidilInformation,
  PhentermineTopiramateInformation,
  OlopatadineFluticasoneInformation,
  ValaciclovirLignocaineInformation,
  CombinedOralContraceptiveInformation,
  ProgesteroneOnlyContraceptiveInformation,
} from './information-sheets';

const PREFIX = 'InformationModal';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  image: `${PREFIX}-image`,
  backButtonGrid: `${PREFIX}-backButtonGrid`,
  backButton: `${PREFIX}-backButton`,
  button: `${PREFIX}-button`,
  heading: `${PREFIX}-heading`,
  link: `${PREFIX}-link`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.gridContainer}`]: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '0px 30px 30px 30px',
    margin: '40px 0px 0px 0px',
    width: '90%',
    display: 'block',
    height: '85vh',
    overflow: 'auto',
    '@media (max-width: 600px)': {
      padding: '0px 20px 20px 20px',
      width: '100%',
    },
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#2AAFBB',
      borderRadius: '100px',
    },
  },

  [`& .${classes.image}`]: {
    width: 310,
    maxWidth: '80%',
  },

  [`& .${classes.backButtonGrid}`]: {
    backgroundColor: '#fff',
    position: 'sticky',
    top: 0,
    width: '100%',
    paddingTop: 10,
  },

  [`& .${classes.backButton}`]: {
    display: 'block',
    padding: 5,
    marginBottom: 10,
  },

  [`& .${classes.button}`]: {
    display: 'block',
    width: 170,
    margin: '0px auto',
  },

  [`& .${classes.heading}`]: {
    marginTop: 20,
    textAlign: 'center',
  },

  [`& .${classes.link}`]: {
    marginTop: 20,
    fontWeight: 'bold',
    textAlign: 'left',
  },
}));

const InformationModal = (props) => {
  const { setModalOpen, selectedTreatments } = props;
  const [treatmentDataArray, setTreatmentDataArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const tempTreatmentDataArray = [];
      await Promise.all(
        selectedTreatments.map(async (treatment) => {
          const treatmentData = (await getDoc(doc(db, 'catalogue', treatment.replace('price_', '')))).data();
          tempTreatmentDataArray.push(treatmentData);
        }),
      );
      setTreatmentDataArray(tempTreatmentDataArray);
      setIsLoading(false);
    };

    setIsLoading(true);
    getData();
  }, [selectedTreatments]);

  return (
    <StyledGrid container className={classes.gridContainer}>
      <Paper className={classes.paper}>
        <Grid item className={classes.backButtonGrid}>
          <Box>
            <Button
              className={classes.backButton}
              variant="contained"
              color="primary"
              onClick={() => setModalOpen(false)}
            >
              Close
            </Button>
            <Divider
              sx={{
                opacity: '0.6',
              }}
            />
          </Box>
        </Grid>
        <Typography className={classes.heading} variant="h5">
          Information Sheet
        </Typography>
        <br />
        <Typography variant="body1">
          This information sheet provides general information regarding your selected treatment. Please ensure you read
          it thoroughly if you are considering using this treatment.
          <br />
          <br />
          If you purchase this treatment a more detailed medication safety booklet will be provided with your
          prescription. Please ensure you also read this thoroughly. If you have any questions about this information
          you can discuss them with your Candor GP.
          <br />
          <br />
          Please note that treatments purchased through the Candor portal will not be fulfilled by Candor directly.
          Candor acts as an authorised billing agent on behalf of the partner pharmacy fulfilling your order.
        </Typography>
        <br />
        {isLoading && <Loading />}
        {treatmentDataArray.map((treatmentData) => {
          if (!treatmentData) return null;
          if (treatmentData.form === 'medicinal cannabis') {
            return (
              <CannabisInfoSheet
                title={treatmentData.ingredients}
                treatmentDetails={treatmentData}
                key={`infoSheet ${treatmentData.stripePrice}`}
              />
            );
          }
          switch (treatmentData.ingredients) {
            // Weight Loss
            case 'Phentermine/Topiramate':
              return <PhentermineTopiramateInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Ozempic':
              return <OzempicInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Mounjaro':
              return <MounjaroInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            // Contraceptive Pill
            case 'Combined Oral Contraceptive':
              return <CombinedOralContraceptiveInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Aciclovir':
              return <AciclovirInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Cetirizine':
              return <CetirizineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Doxycycline':
              return <DoxycyclineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Doxycycline & Tretinoin':
              return <DoxycyclineTretinoinInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Doxylamine':
              return <DoxylamineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Famciclovir':
              return <FamciclovirInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Finasteride':
              return <FinasterideInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Finasteride & Minoxidil':
              return <FinasterideMinoxidilInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Fluticasone':
              return <FluticasoneInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Lignocaine':
              return <LignocaineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Melatonin':
              return <MelatoninInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Minoxidil':
              return <MinoxidilInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Olopatadine':
              return <OlopatadineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Olopatadine & Fluticasone':
              return <OlopatadineFluticasoneInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Progesterone Only Contraceptive':
              return <ProgesteroneOnlyContraceptiveInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Promethazine':
              return <PromethazineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Sildenafil':
              return <SildenafilInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Slinda':
              return <SlindaInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Tadalafil':
              return <TadalafilInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Tretinoin':
              return <TretinoinInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Valacyclovir':
              return <ValaciclovirInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Valacyclovir & Lignocaine':
              return <ValaciclovirLignocaineInformation key={`infoSheet ${treatmentData.stripePrice}`} />;
            case 'Nicotine':
              return (
                <NicotineVapeInformation
                  key={`infoSheet ${treatmentData.stripePrice}`}
                  treatmentDetails={treatmentData}
                />
              );
            default:
              return <Loading key={`infoSheet ${treatmentData.stripePrice}`} />;
          }
        })}
      </Paper>
    </StyledGrid>
  );
};

InformationModal.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  selectedTreatments: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default InformationModal;
