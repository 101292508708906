import React, { useContext, useState } from 'react';
import moment from 'moment';
import { doc, writeBatch, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { AuthContext } from '../../../../auth-context';
import createEmailObj from '../../../../functions/create-email-obj';
import SubmitFormElementPresentation from '../presentation-elements/submit-form-element-presentation';
import { getConditions, getTreatmentsTried, processMiniForms } from '../../../../functions/process-mini-forms';

const SubmitFormElement = (props) => {
  const { currentFormObject, handlePrev, handleNext, formInputs, formName, stripePrice } = props;

  const { user } = useContext(AuthContext);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSubmit = async () => {
    const date = Date.now();
    setSnackbarOpen(true);
    const generalObj = { lastCheckup: date };
    let infoObj = { 'basic info.first form completed': true, 'basic info.last checkup': Date.now() };
    const batch = writeBatch(db);
    const formRef = `forms.${formName}`;
    if (stripePrice !== null) {
      generalObj[`${formRef}`] = 'awaiting payment';
      generalObj.banner = { title: 'awaiting payment', stripePrice, formName };
      infoObj[`${formRef}.step`] = 'awaiting payment';
      if (formName === 'checkup') {
        infoObj = { ...infoObj, ...processMiniForms(formInputs) };
      }
    } else {
      // These are unpaid forms like mini checkups
      generalObj[`${formRef}`] = 'awaiting review';
      generalObj.banner = { title: 'awaiting review', formName };
      infoObj[`${formRef}.step`] = 'awaiting review';
      const fullName = `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`;
      const mailRef1 = doc(collection(db, 'mail'));
      batch.set(mailRef1, createEmailObj('nurse@candor.org', 'patient_checkup_completed', { patientName: fullName }));
    }
    if (formName === 'medicinal cannabis') {
      const summaryObj = {
        patientAge: moment().diff(formInputs['basic info.dob'], 'years'),
        allergyStatus:
          formInputs['medicinal cannabis.allergic to oils'] === true
            ? `${formInputs['medicinal cannabis.allergic to oils details']}`
            : '',
        medicationAllergies: `${formInputs['general health.medication allergies']}`,
        formFactorPreference: formInputs['medicinal cannabis.form factor preference']
          ? `, ${formInputs['medicinal cannabis.form factor preference']}`
          : '',
        optionPreference:
          formInputs['medicinal cannabis.option preference'].length > 1
            ? formInputs['medicinal cannabis.option preference']
            : '',
        prevRecreationalUse:
          formInputs['medicinal cannabis.previous recreational use'] === true
            ? 'Have used recreational cannabis'
            : 'No',
        recreationalUseFormat: formInputs['medicinal cannabis.recreational use format'],
        recreationalUseFrequency: formInputs['medicinal cannabis.recreational use frequency'],
        useBefore: formInputs['medicinal cannabis.used before']
          ? formInputs['medicinal cannabis.used before details']
          : 'No',
        medicalConditionsListed: getConditions(formInputs, 'condition.', '.diagnosed'),
        currentMedicationsOrTherapies: getConditions(formInputs, 'treatment.', '.currently using'),
        medicationsOrTherapiesTried: getTreatmentsTried(formInputs),
        therapyRequestOrTreatmentGoals: formInputs['medicinal cannabis.desired outcome'],
        needToDrive:
          formInputs['medicinal cannabis.lifestyle.I cannot avoid regular driving'] === true
            ? 'Cannot avoid regular driving'
            : 'No',
        lookAfterChildren:
          formInputs['medicinal cannabis.lifestyle.I am responsible for the care of young children'] === true
            ? 'Taking care of young children'
            : 'No',
        operatesHeavyMachinery:
          formInputs['medicinal cannabis.lifestyle.I operate heavy machinery'] === true
            ? 'Operates heavy machinery'
            : 'No',
        otherNotesForGP: formInputs['medicinal cannabis.other notes for gp']
          ? formInputs['medicinal cannabis.other notes for gp details']
          : 'No Notes',
      };
      batch.set(doc(collection(db, 'patients', user?.uid, 'activity')), {
        createdAt: date,
        generalData: false,
        author: 'System',
        text: `Patient submitted a  ${formName} form, here's the summary:
        Patient Age:  ${summaryObj.patientAge}
        Allergies: ${summaryObj.allergyStatus}, ${summaryObj.medicationAllergies}
        Cannabis Preferences: ${summaryObj.optionPreference} ${summaryObj.formFactorPreference}
        Recreational Cannabis Use: ${summaryObj.prevRecreationalUse}, ${summaryObj.recreationalUseFormat}, ${
          summaryObj.recreationalUseFrequency
        }
        Cannabis Use: ${
          formInputs['treatment.Medicinal Cannabis.currently using'] === true
            ? 'currently using'
            : 'have previously used'
        } ${summaryObj.useBefore}
        Medical Conditions Listed: ${summaryObj.medicalConditionsListed.join(', ')}
        Current Medications Or Therapies: ${summaryObj.currentMedicationsOrTherapies.join(', ')}
        Medications Or Therapies Tried: ${summaryObj.medicationsOrTherapiesTried}
        Therapy Request Or Treatment Goals: ${summaryObj.therapyRequestOrTreatmentGoals},
        Need to drive: ${summaryObj.needToDrive}
        Look after Children: ${summaryObj.lookAfterChildren}
        Operates Heavy Machinery: ${summaryObj.operatesHeavyMachinery}
        Other Notes For GP: ${summaryObj.otherNotesForGP}
        `,
      });
    }
    batch.update(doc(db, 'patients', user?.uid), generalObj);
    batch.update(doc(db, 'patients', user?.uid, 'general', 'information'), infoObj);
    await batch.commit();
    handleNext();
  };

  return (
    <SubmitFormElementPresentation
      stripePrice={stripePrice}
      formName={formName}
      snackbarOpen={snackbarOpen}
      setSnackbarOpen={setSnackbarOpen}
      formInputs={formInputs}
      currentFormObject={currentFormObject}
      handlePrev={handlePrev}
      handleNext={handleNext}
      handleSubmit={handleSubmit}
    />
  );
};

export default SubmitFormElement;
