import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import Loading from '../../layout/loading';
import { db } from '../../../firebase-config';
import formatDatabaseValues from '../../../functions/format-database-values';
import BasicFormElement from '../horizontal-form-elements/container-elements/basic-form-element';
import DateFormElement from '../horizontal-form-elements/container-elements/date-form-element';
import RadioFormElement from '../horizontal-form-elements/container-elements/radio-form-element';
import CheckboxFormElement from '../horizontal-form-elements/container-elements/checkbox-form-element';
import UploadFormElement from '../horizontal-form-elements/container-elements/upload-form-element';
import ExpandableFormElement from '../vertical-form-elements/container-elements/expandable-form-element';
import TreatmentsTriedFormElement from '../vertical-form-elements/container-elements/treatments-tried-form-element';
import SelecterFormElement from '../horizontal-form-elements/container-elements/selecter-form-element';

const DoctorFormView = ({ patient, formObjects, formName, preLoadedData, simpleView, history }) => {
  const isMounted = useRef(false);

  const [formInputs, setFormInputs] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const formComponents = formObjects;

  useEffect(() => {
    isMounted.current = true;

    // This converts the map objects in the database into strings on the general state object
    async function getData() {
      const tempGeneralDoc =
        formName === 'checkup' ? preLoadedData : await getDoc(doc(db, 'patients', patient, 'general', 'information'));
      const generalDoc = history ? history.data : tempGeneralDoc.data();
      const obj = formatDatabaseValues(generalDoc);
      setFormInputs((prevFormInputs) => ({ ...prevFormInputs, ...obj }));
      setDataLoaded(true);
    }

    if (isMounted.current) {
      getData();
    }

    return () => {
      isMounted.current = false;
    };
  }, [patient, formName, formObjects.length, preLoadedData, history]);

  if (!dataLoaded) {
    return <Loading />;
  }

  return formComponents.map((component) => {
    const key = uuidv4();

    if (component.creationTest(formInputs)) {
      switch (component.elementType) {
        case 'basic':
          return (
            <div key={key}>
              <BasicFormElement
                simpleView={simpleView}
                formName={formName}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'upload':
          return (
            <div key={key}>
              <UploadFormElement
                simpleView={simpleView}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'selecter':
          return (
            <div key={key}>
              <SelecterFormElement
                simpleView={simpleView}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'date':
          return (
            <div key={key}>
              <DateFormElement
                simpleView={simpleView}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'radio':
          return (
            <div key={key}>
              <RadioFormElement
                simpleView={simpleView}
                formComponents={formComponents}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'checkbox':
          return (
            <div key={key}>
              <CheckboxFormElement
                simpleView={simpleView}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'expandable':
          return (
            <div key={key}>
              <ExpandableFormElement
                simpleView={simpleView}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        case 'treatments tried':
          return (
            <div key={key}>
              <TreatmentsTriedFormElement
                simpleView={simpleView}
                currentFormObject={component}
                formInputs={formInputs}
                setFormInputs={setFormInputs}
              />
            </div>
          );
        default:
          return <div key={key} />;
      }
    }

    return <div key={key} />;
  });
};

export default DoctorFormView;
