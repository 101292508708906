import React, { useContext, useEffect, useState } from 'react';
import { doc, updateDoc, writeBatch, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import RadioFormElementPresentation from '../presentation-elements/radio-form-element-presentation';
import { AuthContext } from '../../../../auth-context';
import createEmailObj from '../../../../functions/create-email-obj';
import { isCandor } from '../../../../utils/roles';

const RadioFormElement = (props) => {
  const {
    currentFormObject,
    handlePrev,
    setFormInputs,
    handleNext,
    formInputs,
    formName,
    index,
    patientId,
    simpleView,
    isUpdateDetails,
  } = props;

  const { user, userType } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const [inputValue, setInputValue] = useState();

  const handleChange = (value) => {
    setInputValue(value);
  };

  const handleInput = async () => {
    setIsLoading(true);
    if (inputValue !== formInputs[currentFormObject.fieldText]) {
      const obj = { [currentFormObject.fieldText]: inputValue };
      setFormInputs({ ...formInputs, ...obj });
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        if (!isCandor(userType)) obj[`forms.${formName}.step`] = index;
        // Add sex to main user doc if necessary
        if (
          currentFormObject.notifyDoctor &&
          (formInputs[currentFormObject.fieldText] !== undefined || inputValue !== false)
        ) {
          // Let docotor know the patient changed their chart and add to their activity list
          const refTextArray = currentFormObject.fieldText.split('.');
          const verbArray = inputValue !== false ? ['added', 'to'] : ['removed', 'from'];
          const dataObj = {
            patientName: `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`,
            email: user.email,
            updatedData: `Patient added new info: [${refTextArray[0]}] to their ${refTextArray[0]} list`,
          };
          const batch = writeBatch(db);
          batch.update(doc(db, 'patients', userId, 'general', 'information'), obj);
          batch.set(doc(collection(db, 'patients', userId, 'activity')), {
            createdAt: Date.now(),
            generalData: false,
            author: 'System',
            text: `Patient ${verbArray[0]} ${refTextArray[1]} ${verbArray[1]} their ${refTextArray[0]} list`,
          });
          const mailRef = doc(collection(db, 'mail'));
          batch.set(mailRef, createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
          await batch.commit();
        } else if (currentFormObject.fieldText === 'basic info.sex') {
          // Add sex to main user doc if necessary
          const batch = writeBatch(db);
          batch.update(doc(db, 'patients', userId), { sex: inputValue });
          batch.update(doc(db, 'patients', userId, 'general', 'information'), obj);
          await batch.commit();
        } else {
          updateDoc(doc(db, 'patients', userId, 'general', 'information'), obj);
        }
      }
    }
    setIsLoading(false);
    handleNext();
  };

  useEffect(() => {
    setInputValue(formInputs[currentFormObject.fieldText]);
  }, [formInputs, currentFormObject.fieldText]);

  return (
    <RadioFormElementPresentation
      isLoading={isLoading}
      isUpdateDetails={isUpdateDetails}
      inputValue={inputValue}
      handleChange={handleChange}
      simpleView={simpleView}
      currentFormObject={currentFormObject}
      handlePrev={handlePrev}
      handleInput={handleInput}
      formInputs={formInputs}
    />
  );
};

export default RadioFormElement;
