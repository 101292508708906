export const isCandor = (userType) =>
  // return true if userType is nurse, doctor, director
  userType === 'support' || userType === 'nurse' || userType === 'doctor' || userType === 'director';

export const isPatient = (userType) =>
  // return true if userType is patient
  userType === 'patient';

export const isDoctor = (userType) =>
  // return true if userType is doctor
  userType === 'doctor';

export const isNurse = (userType) =>
  // return true if userType is nurse
  userType === 'nurse';

export const isDirector = (userType) =>
  // return true if userType is director
  userType === 'director';

export const isSupport = (userType) =>
  // return true if userType is support
  userType === 'support';

export const isPharmacist = (userType) =>
  // return true if userType is pharmacist
  userType === 'pharmacist';

export const isSupplier = (userType) =>
  // return true if userType is supplier
  userType === 'supplier';
