import React, { useContext, useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import SelecterFormElementPresentation from '../presentation-elements/selecter-form-element-presentation';
import { AuthContext } from '../../../../auth-context';

const SelecterFormElement = (props) => {
  const { currentFormObject, handlePrev, setFormInputs, handleNext, formInputs, formName, index, simpleView } = props;

  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (value) => {
    setFormInputs({ ...formInputs, [currentFormObject.fieldText]: value });
  };

  const handleInput = async () => {
    setIsLoading(true);
    if (user)
      updateDoc(doc(db, 'patients', user.uid, 'general', 'information'), {
        [currentFormObject.fieldText]: formInputs[currentFormObject.fieldText],
        [`forms.${formName}.step`]: index,
      });
    handleNext();
    setIsLoading(false);
  };

  return (
    <SelecterFormElementPresentation
      isLoading={isLoading}
      simpleView={simpleView}
      currentFormObject={currentFormObject}
      inputValue={formInputs[currentFormObject.fieldText]}
      handlePrev={handlePrev}
      handleInput={handleInput}
      handleChange={handleChange}
    />
  );
};

export default SelecterFormElement;
