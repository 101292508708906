import React from 'react';
import { Grid, Typography } from '@mui/material';

const DoxycyclineInformation = () => (
  <Grid item>
    <Typography variant="h6">About Doxycycline</Typography>
    <br />
    <Typography variant="body1">
      Doxycycline is an oral antibiotic that can be used to treat acne. It belongs to a group of antibiotics called
      tetracyclines and is taken as a daily medication, usually for 6 weeks to 6 months. Doxycycline is generally used
      for moderate-severe acne or acne that affects the body as well as the face.
      <br />
      <br />
      Doxycycline acts to decrease Cutibacterium acnes, which is a bacteria that contributes to acne as well as
      decreasing inflammation in the skin so is helpful for pustules and redness. It is less useful for blocked pores
      and blackheads and doesn’t help to prevent the effects of ageing on the skin. If you do have concerns about these
      conditions, get in touch with your Candor GP, we can help! Often Doxycycline is combined with topical treatments
      to get the best effects for you when managing your acne.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Doxycycline is an oral tablet taken once a day. Generally 50mg is the starting dose and this is used for a minimum
      of 6 weeks and a maximum of 6 months. After 6 weeks if you’re not seeing the effects you’d like, talk to your
      Candor GP about the possibility of increasing your dose or combining it with another treatment.
      <br />
      <br />
      Doxycycline should be taken with a full glass of water and some food or milk to decrease stomach upset, and it is
      recommended you remain upright (do not lie down) for an hour after taking. This is to stop the medication causing
      heartburn and gastroesophageal side effects.
      <br />
      <br />
      Do not take antacids, iron, calcium or zinc supplements within 2 hours of taking Doxycycline as they may interfere
      with its absorption.
      <br />
      <br />
      Doxycycline increases your likelihood of sunburn. It is important to protect treated areas from sunlight with
      protective clothing or broad-spectrum sunscreen and avoid sunlamps and tanning beds.
      <br />
      <br />
      To prevent bacterial resistance Doxycycline is often combined with topical treatments and used for the minimum
      time possible to achieve the desired effects.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Doxycycline is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (Greater than 1%):
      <br />
      <br />
      - Nausea
      <br />
      - Vomiting
      <br />
      - Diarrhoea
      <br />
      - Epigastric burning
      <br />
      - Tooth discolouration
      <br />
      - Enamel dysplasia
      <br />
      - Photosensitivity
      <br />
      <br />
      Infrequent (0.1% to 1%)
      <br />
      <br />
      - Rash
      <br />
      - Stomatitis
      <br />
      - Fungal overgrowth
      <br />
      <br />
      Infrequent (Less than 0.1%)
      <br />
      <br />
      - Photo-onycholysis and nail discolouration
      <br />
      - Oesophageal ulcers <br />
      - Hepatitis
      <br />
      - Fatty liver degeneration (with high doses, especially in pregnancy)
      <br />
      - Benign intracranial hypertension
      <br />
      - Allergic reactions including anaphylaxis
      <br />
      - Toxic epidermal necrolysis
      <br />
      - Worsening of systemic lupus erythematosus
      <br />
      - Serum sickness-like reactions
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Doxycycline including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Doxycycline when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      While likely safe in the first few months, Candor does not prescribe Doxycycline in pregnancy. After 18 weeks
      there is an increased risk of changes to bone growth and teeth development to the fetus. We do not recommend use
      while pregnant.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Doxycycline when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      Short courses of 7-10 days are considered safe to use while breastfeeding, however this is not likely to be
      sufficient to improve acne. Candor does not prescribe Doxycycline to breastfeeding women.
    </Typography>
  </Grid>
);

export default DoxycyclineInformation;
