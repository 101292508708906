import React from 'react';
import { Grid, Typography } from '@mui/material';

const MelatoninInformation = () => (
  <Grid item>
    <Typography variant="h6">Melatonin</Typography>
    <br />
    <Typography variant="body1">
      Melatonin is a hormone released at night by a gland in the brain called the pineal gland. It has impacts on the
      sleep-wake cycle and is involved in regulating circadian rhythms. Taking melatonin as a supplement can help you to
      fall asleep faster and stay asleep longer. It is used in patients with insomnia to improve sleep length and
      quality.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Melatonin is best taken as part of a winding down routing prior to sleep. At Candor we recommend reading our
      information on sleep hygiene to maximise your benefit of a good rest. Melatonin should be taken 1-2 hours before
      bedtime.
      <br />
      <br />
      Melatonin can be taken as a regular medication for up to 3 months, or on an as needed basis. It can be helpful to
      take Melatonin for 2-3 weeks to get in a good sleeping pattern before trying to sleep without it.
      <br />
      <br />
      Tablets should be taken whole, not crushed or chewed.
      <br />
      <br />
      Do not drink alcohol while using Melatonin.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Melatonin is commonly used and side effects are generally mild. However it is important to be aware of any
      potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Back pain
      <br />
      - Arthralgia
      <br />
      - Tiredness
      <br />
      <br />
      Infrequent (0.1 - 1%):
      <br />
      - Weakness
      <br />
      - Irritability
      <br />
      - Nightmares
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Melatonin including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Melatonin when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. Taking Melatonin while pregnant can affect fetal and placental development.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Melatonin when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">No. Melatonin is not recommended while breastfeeding.</Typography>
  </Grid>
);

export default MelatoninInformation;
