import React, { useContext, useState, useEffect } from 'react';
import { doc, writeBatch } from 'firebase/firestore';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import { db } from '../../../../firebase-config';
import ShippingFormElementPresentation from '../presentation-elements/shipping-form-element-presentation';
import { AuthContext } from '../../../../auth-context';
import { validateInternationalPhoneNumbers } from '../../../../utils/validations';
import { extractNumericCharacters } from '../../../../utils/constants';

const ShippingFormElement = (props) => {
  const { currentFormObject, setFormInputs, handleNext, formInputs, formName, patientId } = props;

  const { user, userType } = useContext(AuthContext);
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [shippingData, setShippingData] = useState({
    phone: '',
    postcode: '',
    state: '',
    'street address': '',
    suburb: '',
  });

  const handleChange = (value, key) => {
    setIsPhoneError(false);
    setShippingData((pd) => ({ ...pd, ...{ [key]: value } }));
  };

  const isShippingFieldsValid = () => {
    const isPhoneNumberValid = validateInternationalPhoneNumbers(shippingData.phone);
    setIsPhoneError(!isPhoneNumberValid);
    if (!isPhoneNumberValid) return false;
    return true;
  };

  useEffect(() => {
    const {
      'basic info.shipping.phone': phone,
      'basic info.shipping.postcode': postcode,
      'basic info.shipping.state': state,
      'basic info.shipping.street address': streetAddress,
      'basic info.shipping.suburb': suburb,
    } = formInputs;

    setShippingData((previousData) => ({
      ...previousData,
      phone: extractNumericCharacters(phone),
      postcode,
      state,
      'street address': streetAddress,
      suburb,
    }));
  }, [currentFormObject, formInputs, formName]);

  const formatShippingDetails = () =>
    Object.entries(shippingData).reduce(
      (shippingDetailsObj, [key, value]) => ({
        ...shippingDetailsObj,
        // if key is 'phone', add + at the start of the value
        [`basic info.shipping.${key}`]: key === 'phone' ? `+${value}` : value,
      }),
      {},
    );

  const handleInput = async (e) => {
    e.preventDefault();
    if (!user || !isShippingFieldsValid()) {
      return;
    }
    try {
      const dataUpdate = formatShippingDetails();
      const batch = writeBatch(db);
      const userId = userType === 'patient' ? user.uid : patientId;
      batch.update(doc(db, 'patients', userId), { shipping: shippingData });
      batch.update(doc(db, 'patients', userId, 'general', 'information'), dataUpdate);
      await batch.commit();
      setFormInputs((pd) => ({ ...pd, ...dataUpdate }));
      handleNext();
    } catch (error) {
      Sentry.captureException(error, { extra: { shipping: shippingData } });
    }
  };

  return (
    <ShippingFormElementPresentation
      currentFormObject={currentFormObject}
      handleInput={handleInput}
      handleChange={handleChange}
      shippingData={shippingData}
      isPhoneError={isPhoneError}
    />
  );
};

ShippingFormElement.propTypes = {
  setFormInputs: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  formName: PropTypes.string.isRequired,
  patientId: PropTypes.string,
};

ShippingFormElement.defaultProps = {
  patientId: undefined,
};

export default ShippingFormElement;
