// Import React, firebase functions, the AuthContext var and the component to be returned
import React, { useContext, useState, useEffect } from 'react';
import { doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import DateFormElementPresentation from '../presentation-elements/date-form-element-presentation';
import { AuthContext } from '../../../../auth-context';
import { isCandor } from '../../../../utils/roles';

// TODO: What is this magic number? What should it be called?
const MAGIC_NUMBER = 1900;
// Function that checks whether the final date string is valid (yyyy-mm-dd)
const validateDate = (dateParam) => {
  const dateArray = dateParam.split('-');
  const year = dateArray[0];
  const month = dateArray[1];
  const day = dateArray[2];
  return (
    parseInt(month, 10) < 13 &&
    parseInt(month, 10) > 0 &&
    parseInt(day, 10) > 0 &&
    parseInt(day, 10) < 32 &&
    parseInt(year, 10) > MAGIC_NUMBER
  );
};

// Component to render
const DateFormElement = (props) => {
  const {
    currentFormObject,
    handlePrev,
    setFormInputs,
    handleNext,
    formInputs,
    formName,
    index,
    patientId,
    simpleView,
    isUpdateDetails,
  } = props;

  const { user, userType } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  // States to handle user input and helper variables:

  // The valueState holds the user's inputs

  const [valueState, setValueState] = useState({ year: '', month: '', day: '' });
  const [throwError, setThrowError] = useState(false);

  // Checks whether the user has entered enough characters to proceed
  const isInputValidLength =
    valueState.year.length === 4 && valueState.month.length === 2 && valueState.day.length === 2;

  // Function to handle the user's input, on every change
  const handleTextInput = (value, field) => {
    // Checks if the input value is only comprised of integers, or its length is zero
    if (/^\d+$/.test(value) || value.length === 0) {
      switch (field) {
        case 'year':
          if (value.length <= 4) {
            setValueState({ ...valueState, year: value });
            setFormInputs({
              ...formInputs,
              [currentFormObject.fieldText]: `${value}-${valueState.month}-${valueState.day}`,
            });
          }
          break;
        case 'month':
          if (value.length <= 2) {
            setValueState({ ...valueState, [field]: value });
            setFormInputs({
              ...formInputs,
              [currentFormObject.fieldText]: `${valueState.year}-${value}-${valueState.day}`,
            });
          }
          break;
        case 'day':
          if (value.length <= 2) {
            setValueState({ ...valueState, [field]: value });
            setFormInputs({
              ...formInputs,
              [currentFormObject.fieldText]: `${valueState.year}-${valueState.month}-${value}`,
            });
          }
          break;
        default:
          // The default should never be reached
          break;
      }
    }
  };

  // Handles sending data to firebase databse (form submission)
  const handleInput = async () => {
    setIsLoading(true);
    const date = `${valueState.year}-${valueState.month}-${valueState.day}`;
    setFormInputs({ ...formInputs, [currentFormObject.fieldText]: date });
    const obj = { [currentFormObject.fieldText]: date };
    if (validateDate(date)) {
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        if (!isCandor(userType)) obj[`forms.${formName}.step`] = index;
        if (currentFormObject.fieldText === 'basic info.dob') {
          const batch = writeBatch(db);
          batch.update(doc(db, 'patients', userId), { dob: date });
          batch.update(doc(db, 'patients', userId, 'general', 'information'), obj);
          batch.commit();
        } else {
          updateDoc(doc(db, 'patients', userId, 'general', 'information'), obj);
        }
      }
      handleNext();
    } else {
      setThrowError(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const prevDOB = formInputs[currentFormObject.fieldText]
      ? formInputs[currentFormObject.fieldText].split('-')
      : ['', '', ''];
    setValueState({ year: prevDOB[0] || '', month: prevDOB[1] || '', day: prevDOB[2] || '' });
  }, [currentFormObject, formInputs, formName]);

  return (
    <DateFormElementPresentation
      isLoading={isLoading}
      isUpdateDetails={isUpdateDetails}
      simpleView={simpleView}
      isInputValidLength={isInputValidLength}
      throwError={throwError}
      handleTextInput={handleTextInput}
      currentFormObject={currentFormObject}
      inputValue={valueState}
      handlePrev={handlePrev}
      handleInput={handleInput}
    />
  );
};

export default DateFormElement;
