import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, onSnapshot, query, where } from 'firebase/firestore';

import { auth, db } from './firebase-config';
import { updateUserScopeInSentry } from './utils/sentryHelpers';
import { getUserRoleFromAuthToken } from './utils/authHelpers';

const DEFAULT_USER_ROLE = '';
const DEFAULT_NOTIFICATIONS_COUNT = {
  doctor: 0,
  patient: 0,
  nurse: 0,
  pharmacist: 0,
  support: 0,
};

export const AuthContext = React.createContext({ user: null, userType: DEFAULT_USER_ROLE, isAuthLoading: true });

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({ user: null, userType: DEFAULT_USER_ROLE, isAuthLoading: true });

  const [messageOrder, setMessageOrder] = useState(null);
  const [notifications, setNotifications] = useState(DEFAULT_NOTIFICATIONS_COUNT);
  const [conversation, setConversation] = useState(null);
  const [accountDetailsPage, setAccountDetailsPage] = useState('account-info');
  const [medicalPage, setMedicalPage] = useState('medical-info');
  const [cataloguePage, setCataloguePage] = useState('');
  const [catalogues, setCatalogues] = useState([]);

  const [emailVerificationDetails, setEmailVerificationDetails] = useState({
    oldEmail: '',
    newEmail: '',
    isEmailVerified: true,
  });

  const value = React.useMemo(
    () => ({
      user: userInfo.user,
      userType: userInfo.userType,
      isAuthLoading: userInfo.isAuthLoading,
      messageOrder,
      setMessageOrder,
      accountDetailsPage,
      setAccountDetailsPage,
      cataloguePage,
      setCataloguePage,
      medicalPage,
      setMedicalPage,
      conversation,
      setConversation,
      notifications,
      setNotifications,
      emailVerificationDetails,
      setEmailVerificationDetails,
      catalogues,
      setCatalogues,
    }),
    [
      userInfo.user,
      userInfo.userType,
      userInfo.isAuthLoading,
      cataloguePage,
      setCataloguePage,
      messageOrder,
      setMessageOrder,
      accountDetailsPage,
      setAccountDetailsPage,
      medicalPage,
      setMedicalPage,
      conversation,
      setConversation,
      notifications,
      setNotifications,
      emailVerificationDetails,
      setEmailVerificationDetails,
      catalogues,
      setCatalogues,
    ],
  );

  // TODO: refactor and improve, same logic is duplicated in other places
  const handleUserId = useCallback(() => {
    if (userInfo.userType === 'support' || userInfo.userType === 'nurse' || userInfo.userType === 'doctor') {
      return userInfo.userType;
    }
    return userInfo.user?.uid;
  }, [userInfo.user?.uid, userInfo.userType]);

  // TODO: Fix this, overly complicated and it shouldn't be in the AuthContext.
  // This should be in a separate context or a hook.
  const handleNotification = useCallback(async () => {
    try {
      let unsubscribe = null;
      if (userInfo.user && userInfo.userType) {
        const participant = userInfo.userType === 'patient' ? 'participant1' : 'participant2';
        const q = query(
          collection(db, 'conversations'),
          where(`${participant}.id`, '==', handleUserId()),
          where(`${participant}.unreadNotifications`, '==', true),
        );

        unsubscribe = onSnapshot(q, (snapshot) => {
          const payload = {
            doctor: 0,
            patient: 0,
            nurse: 0,
            pharmacist: 0,
            support: 0,
          };

          const chats = snapshot.docs.map((item) => ({ id: item.id, ...item.data() }));
          chats.forEach((item) => {
            const recipientData = handleUserId() === item.participant1.id ? item.participant2 : item.participant1;
            const senderData = handleUserId() === item.participant1.id ? item.participant1 : item.participant2;

            if (recipientData && senderData) {
              if (senderData?.email === userInfo.user?.email && userInfo.userType === 'doctor') {
                payload[recipientData.role] += senderData.notifications;
              }
              if (userInfo.userType !== 'doctor') {
                payload[recipientData.role] += senderData.notifications;
              }
            }
          });

          delete payload.undefined;
          setNotifications(payload);
        });
        return () => {
          unsubscribe();
        };
      }
    } catch (error) {
      Sentry.captureMessage('Error obtaining conversations in AuthContext', {
        level: 'debug',
        extra: { userId: userInfo.user?.uid, userType: userInfo.userType },
      });
      Sentry.captureException(error, { extra: { userId: userInfo.user?.uid, userType: userInfo.userType } });
    }
    return () => {}; // resolve eslint error
  }, [userInfo.user, userInfo.userType, handleUserId]);

  useEffect(() => {
    handleNotification();
  }, [handleNotification]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (!currentUser) {
        setUserInfo({ user: null, userType: DEFAULT_USER_ROLE, isAuthLoading: false });
        updateUserScopeInSentry();
        return; // Exit early if no user is authenticated
      }

      try {
        const tokenResult = await currentUser.getIdTokenResult();
        const userRole = getUserRoleFromAuthToken(tokenResult.claims);

        setUserInfo({ user: currentUser, userType: userRole, isAuthLoading: false });
        updateUserScopeInSentry({ userId: currentUser.uid, userType: userRole });
      } catch (error) {
        Sentry.captureMessage("Error obtaining a User's Firebase Auth Token", {
          level: 'debug',
          extra: { userId: currentUser?.uid },
        });
        Sentry.captureException(error, { extra: { userId: currentUser?.uid } });
        setUserInfo((prevState) => ({ ...prevState, isAuthLoading: false }));
      }
    });

    // Don't forget to cleanup to avoid memory leaks
    return () => {
      unsubscribe();
    };
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
