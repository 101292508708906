import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { signOut } from 'firebase/auth';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { Toolbar, Button, AppBar, Badge } from '@mui/material';

import Sidebar from './sidebar';
import { auth } from '../../firebase-config';
import { AuthContext } from '../../auth-context';

const PREFIX = 'Navbar';

const classes = {
  link: `${PREFIX}-link`,
  appBar: `${PREFIX}-appBar`,
  browserBar: `${PREFIX}-browserBar`,
  nativeBar: `${PREFIX}-nativeBar`,
  hamburger: `${PREFIX}-hamburger`,
  hamburgerLink: `${PREFIX}-hamburgerLink`,
  logo: `${PREFIX}-logo`,
  selected: `${PREFIX}-selected`,
  navBarNotif: `${PREFIX}-navBarNotif`,
};

const StyledAppBar = styled(AppBar)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
  },

  [`&.${classes.appBar}`]: {
    background: '#F6F9FC',
    marginBottom: '0px',
    height: '4dvh',
    '@media (max-width: 1000px)': {
      height: '8dvh',
    },
  },

  [`& .${classes.browserBar}`]: {
    marginLeft: 'auto',
    '@media (max-width: 1000px)': {
      display: 'none',
    },
    height: '80px',
  },

  [`& .${classes.nativeBar}`]: {
    marginRight: 'auto',
    '@media (min-width: 1001px)': {
      display: 'none',
    },
    height: '60px',
  },

  [`& .${classes.hamburger}`]: {
    color: '#2AAFBB',
  },

  [`& .${classes.hamburgerLink}`]: {
    color: '#0E6D81',
    textDecoration: 'none',
  },

  [`& .${classes.logo}`]: {
    width: '150px',
    position: 'absolute',
    left: 13,
    top: 13,
    '@media (max-width: 1001px)': {
      left: 8,
      top: 8,
    },
  },

  [`& .${classes.selected}`]: {
    borderBottom: '2px solid #0E6D81',
    textDecoration: 'none',
    color: '#0E6D81',
    width: 'auto',
  },

  [`& .${classes.navBarNotif}`]: {
    top: '8px',
    right: '-2px',
    width: '8px',
    height: '8px',
  },
}));

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { userNotifications } = useContext(AuthContext);

  const messagesNotifCount = Object.values(userNotifications?.messages ?? {}).reduce((acc, val) => acc + val, 0);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoutClose = () => {
    setAnchorEl(null);
    signOut(auth);
  };

  return (
    <StyledAppBar elevation={0} position="static" className={classes.appBar}>
      <Toolbar className={classes.nativeBar}>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <Badge
            color="secondary"
            variant="dot"
            invisible={!messagesNotifCount}
            slotProps={{ badge: { className: classes.navBarNotif } }}
          >
            <MenuRoundedIcon fontSize="large" className={classes.hamburger} />
          </Badge>
        </Button>
        <Sidebar open={Boolean(anchorEl)} onClose={handleClose} handleLogoutClose={handleLogoutClose} from="nav" />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
