import React from 'react';
import { Grid, Typography } from '@mui/material';

const OlopatadineFluticasoneInformation = () => (
  <Grid item>
    <Typography variant="h6">About Olopatadine Eye Drops</Typography>
    <br />
    <Typography variant="body1">
      Your Candor GP has prescribed you Olopatadine 0.1% eye drops. Please ensure you read the following information
      thoroughly. A medication safety information sheet will be provided with your prescription - please ensure you also
      read this thoroughly. If you have any questions please get in contact with us.
      <br />
      <br />
      Olopatadine eye drops are antihistamines that are used to help treat allergic conjunctivitis and ocular symptoms
      associated with allergic rhinitis (hayfever). They act to reduce the effects of histamine which in turn decreases
      itch, watery eyes and redness. Olopatadine also stabilises mast cells which can prevent allergy symptoms from
      occurring.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Dosage: one drop to each eye twice a day as needed.
      <br />
      <br />
      Wash hands thoroughly and shake bottle before use. Holding the bottle upside down, tilt your head back and gently
      pull the lower eyelid down. Release one drop onto the eye and close your eye. While your eye is closed, place your
      index finger against the inside corner of your eye towards your nose to prevent the medication draining through
      the tear duct. This will help reduce any unpleasant taste you may experience. Wipe any spillage away with a
      tissue. Repeat on the other side as needed.
      <br />
      <br />
      It is important to be careful not to touch the eyelids or surrounding skin with the dropper tip to avoid
      contaminating the bottle and solution.
      <br />
      <br />
      Caution is advised for contact lens wearers. The preservative in the solution may be absorbed by soft contact
      lenses. If you wear contact lenses it is recommended you wait at least 15 minutes after use to reinsert them.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Olopatadine is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      <br />
      - Stinging
      <br />
      - Mild eye irritation
      <br />
      - Headache
      <br />
      - Hyperaemia
      <br />
      - Keratitis
      <br />
      - Dry eyes
      <br />
      - Unpleasant taste
      <br />
      <br />
      It is normal to experience a slight burning sensation shortly after using the eye drops. If this persists or is
      very uncomfortable, seek medical advice.
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Olopatadine including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Olopatadine when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Yes, however we recommend discussing with your Candor GP prior to use to ensure the benefits outweigh the risks.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Olopatadine when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
    <br />
    <Typography variant="h6">About Fluticasone</Typography>
    <br />
    <Typography variant="body1">
      Fluticasone is an intranasal corticosteroid that is used to help treat allergic rhinitis (hayfever),
      rhinosinusitis and nasal polyps. It acts as an anti-inflammatory, decreases mucus production and constricts blood
      vessels in the nasal mucosa. They may also be helpful in decreasing watery and itchy eyes associated with allergic
      rhinitis.
      <br />
      <br />
      While intranasal corticosteroids can be used on an as-needed basis, they work best to treat seasonal allergic
      rhinitis when used daily.
      <br />
      <br />
      Fluticasone is available in different forms (propionate and furoate) and it is not recommended to use both
      interchangeably.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      For occasional allergy symptoms - 2 sprays into each nostril once daily. Note that intranasal corticosteroids do
      not have an immediate effect on symptoms and it may be several hours before any improvement is noted.
      <br />
      <br />
      For seasonal allergic rhinitis - initially 2 sprays into each nostril once daily; reduce to 1 spray into each
      nostril once daily when symptoms are controlled.
      <br />
      <br />
      To achieve greatest effectiveness and prevent side effects it is important to use your nasal spray correctly. Use
      your right hand to aim the spray into your left nostril, towards your left ear. Use your left hand to aim the
      spray into your right nostril, towards your right ear. Avoid spraying directly into the center of your nose (the
      septum) and this may cause bleeding.
      <br />
      <br />
      Seasonal allergic rhinitis is generally treated for a minimum of 4 weeks. A higher dose is used initially until
      symptoms are controlled and then a maintenance dose is used following.
      <br />
      <br />
      If you know your allergies are likely to begin at a certain time of year, it may be beneficial to start treatment
      with Fluticasone 1-2 weeks prior to this time to prevent symptoms occurring.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Fluticasone is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (greater than 1%):
      <br />
      <br />
      - Nasal stinging
      <br />
      - Itching
      <br />
      - Nose bleed
      <br />
      - Sneezing
      <br />
      - Sore throat
      <br />
      - Dry mouth
      <br />
      - Cough
      <br />
      <br />
      Rare (less than 0.1%):
      <br />
      <br />
      Nasal septal perforation
      <br />
      Glaucoma
      <br />
      Cataracts
      <br />
      Allergic reactions
      <br />
      <br />
      Note that while rare, long-term use of high-dose intranasal corticosteroids can increase the risk of glaucoma and
      cataracts. Candor recommends annual check ups with your optometrist and an urgent review if you notice any visual
      changes.
      <br />
      <br />
      Intranasal corticosteroids should not be used if you have a bleeding disorder or have had recent nasal surgery or
      trauma.
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Fluticasone including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Fluticasone when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      Possibly. There are better alternatives available when pregnant and it’s best to discuss these with your regular
      GP to ensure the safest treatment.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Fluticasone when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default OlopatadineFluticasoneInformation;
