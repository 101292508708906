import React from 'react';
import { Grid, Typography } from '@mui/material';

const CetirizineInformation = () => (
  <Grid item>
    <Typography variant="h6">About Cetirizine</Typography>
    <br />
    <Typography variant="body1">
      Cetirizine is an antihistamine used to help treat allergic rhinitis. It is a non sedating antihistamine.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Cetirizine is used once a day as required.
      <br />
      <br />
      It is recommended to avoid alcohol while using Cetirizine.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Cetirizine is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Common (more than 1%):
      <br />
      - Headache
      <br />
      - Drowsiness
      <br />
      - Nausea
      <br />
      - Fatigue
      <br />
      - Dry mouth
      <br />
      <br />
      Infrequent (0.1 - 1%)
      <br />
      - Liver impairment
      <br />
      - Weight gain
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Rash
      <br />
      - Hypersensitivity
      <br />
      - Hepatitis
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice.
      <br />
      <br />
      Please note further detailed information on Cetirizine including usage and side effects will be provided in a
      medication safety information sheet with your prescription. It is important that you read this thoroughly before
      use.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Cetirizine when pregnant?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
    <br />
    <Typography variant="h6">Can I use Cetirizine when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">Yes.</Typography>
  </Grid>
);

export default CetirizineInformation;
