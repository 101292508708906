import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase-config';
import MedicareCardFormElementPresentation from '../presentation-elements/medicare-card-form-element-presentation';
import { AuthContext } from '../../../../auth-context';

// TODO: What is this magic number? What should it be called? I think it's the max length of the medicare number maybe?
const MEDICARE_MAGIC_NUMBER = 9;

const MedicareCardFormElement = (props) => {
  const { currentFormObject, setFormInputs, handleNext, formInputs, formName, isUpdateDetails, patientId } = props;

  const { user, userType } = useContext(AuthContext);
  const [medicareCardData, setMedicareCardData] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [irnError, setIrnError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [validMedicareFields, setValidMedicareFields] = useState(false);

  const handleChange = (value, key) => {
    setMedicareCardData((pd) => ({ ...pd, ...{ [key]: value } }));
  };

  const isNumeric = (value) => /^-?\d+$/.test(value);
  useEffect(() => {
    setMedicareCardData((pd) => ({
      ...pd,
      ...{
        [`${'firstName'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.firstName`],
        [`${'lastName'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.lastName`],
        [`${'number'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.number`],
        [`${'irn'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.irn`],
        [`${'expiry'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.expiry`],
        [`${'using'}`]: true,
      },
    }));
  }, [currentFormObject, formInputs, formName]);

  useEffect(() => {
    if (!medicareCardData) {
      return;
    }

    if (
      medicareCardData.firstName !== undefined &&
      medicareCardData.firstName.length > 0 &&
      medicareCardData.firstName.trim().length <= 0
    ) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (
      medicareCardData.lastName !== undefined &&
      medicareCardData.lastName.length > 0 &&
      medicareCardData.lastName.trim().length <= 0
    ) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (
      medicareCardData.number !== undefined &&
      medicareCardData.number !== '' &&
      !(
        medicareCardData.number?.replace(/\s+/g, '').length > MEDICARE_MAGIC_NUMBER &&
        isNumeric(medicareCardData?.number?.replace(/\s+/g, '').substring(0, 10))
      )
    ) {
      setNumberError(true);
    } else {
      setNumberError(false);
    }
    if (
      medicareCardData.irn !== undefined &&
      medicareCardData.irn &&
      !(parseInt(medicareCardData.irn, 10) > 1 || parseInt(medicareCardData.irn, 10) < MEDICARE_MAGIC_NUMBER)
    ) {
      setIrnError(true);
    } else {
      setIrnError(false);
    }
    if (
      moment(medicareCardData.expiry).format('YYYY-MM-01') < moment().format('YYYY-MM-01') ||
      medicareCardData.expiry === 'Invalid date'
    ) {
      setExpiryError(true);
    } else {
      setExpiryError(false);
    }

    // if all fields are valid
    if (
      medicareCardData.firstName?.trim().length > 0 &&
      medicareCardData.lastName?.trim().length > 0 &&
      medicareCardData.number?.replace(/\s+/g, '') !== '' &&
      medicareCardData.number?.replace(/\s+/g, '').length === 10 &&
      medicareCardData.irn?.trim().length >= 0 &&
      parseInt(medicareCardData.irn, 10) >= 1 &&
      parseInt(medicareCardData.irn, 10) <= MEDICARE_MAGIC_NUMBER &&
      medicareCardData.expiry &&
      medicareCardData.expiry !== null &&
      medicareCardData.expiry !== 'Invalid date' &&
      !(moment(medicareCardData.expiry).format('YYYY-MM-01') < moment().format('YYYY-MM-01'))
    ) {
      setValidMedicareFields(true);
    } else {
      setValidMedicareFields(false);
    }
  }, [medicareCardData, formInputs]);

  const formatMedicareCardDetails = (type) => {
    const medicareCardDetailsObj = {};
    const cardType = type === 'withSpace' ? 'concession cards' : 'concessionCards';
    Object.entries(medicareCardData).forEach((entry) => {
      const [key, value] = entry;
      if (value !== undefined) {
        if (key === 'firstName' || key === 'lastName') {
          medicareCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value.trim();
        } else if (key === 'number') {
          medicareCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value.replace(
            /\s+/g,
            '',
          );
        } else {
          medicareCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value;
        }
      }
    });
    return medicareCardDetailsObj;
  };

  const handleInput = () => {
    const dataUpdate = formatMedicareCardDetails('withSpace');
    const formattedMedicareCardData = formatMedicareCardDetails();
    if (validMedicareFields) {
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        updateDoc(doc(db, 'patients', userId), { ...formattedMedicareCardData });
        updateDoc(doc(db, 'patients', userId, 'general', 'information'), { ...dataUpdate });
      }
      setFormInputs((pd) => ({ ...pd, ...dataUpdate }));
    }
    handleNext();
  };

  const handleExpiryDate = (date) => {
    if (!date) handleChange(null, 'expiry');
    else handleChange(moment(date).format('YYYY-MM-DD'), 'expiry');
  };

  return (
    <MedicareCardFormElementPresentation
      isUpdateDetails={isUpdateDetails}
      currentFormObject={currentFormObject}
      handleInput={handleInput}
      handleChange={handleChange}
      medicareCardData={medicareCardData}
      setNumberError={setNumberError}
      handleExpiryDate={handleExpiryDate}
      numberError={numberError}
      irnError={irnError}
      expiryError={expiryError}
      setExpiryError={setExpiryError}
      validMedicareFields={validMedicareFields}
      firstNameError={firstNameError}
      lastNameError={lastNameError}
    />
  );
};

export default MedicareCardFormElement;
