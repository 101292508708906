import React from 'react';
import { Grid, Typography } from '@mui/material';

const CartridgeInfo = () => (
  <Grid item>
    <Typography variant="h6">Using medicinal cannabis cartridges</Typography>
    <br />
    <Typography variant="body1">
      Medicinal cannabis cartridge medications are designed for inhalation. Many patients prefer medicinal cannabis
      cartridges due to the rapid onset of action when compared to edible oils.
      <br />
      <br />
      Each cartridge delivers a metered dosage per inhalation. It is recommended to start with 1 second of inhalation
      and wait 15 minutes to observe the effects before increasing your dosage.
      <br />
      <br />
      When cannabis is inhaled, cannabinoids are absorbed from the lungs into the bloodstream within minutes. In the
      bloodstream, the cannabinoids are transported to the brain. Maximum concentrations of cannabinoids in the brain
      are achieved in less than 15 minutes.
      <br />
      <br />
      Dosing schedules differ for each patient and should be discussed with your doctor. Doses should be increased
      gradually over time and the effect fully assessed before continuing to increase. Note that consuming too much
      cannabis can result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default CartridgeInfo;
