import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Link, Button, TextField, Alert, Collapse } from '@mui/material';
import PropTypes from 'prop-types';

import CustomizableSnackbar from '../layout/snackbar';

const PREFIX = 'RequestPasswordReset';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  logo: `${PREFIX}-logo`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(() => ({
  [`& .${classes.input}`]: {
    margin: '20px auto',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto',
  },

  [`& .${classes.logo}`]: {
    margin: '20px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.paper}`]: {
    padding: '20px',
    width: '90%',
    maxWidth: '400px',
  },
}));

const RequestPasswordReset = (props) => {
  const {
    handlePasswordResetRequest,
    confirmEmail,
    handleEmailChange,
    emailError,
    handleBackButton,
    snackbarOpen,
    setSnackbarOpen,
  } = props;

  return (
    <Root>
      <Container maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '70vh' }}
        >
          <Paper className={classes.paper} align="center">
            <Typography variant="h5" align="center" gutterBottom>
              Reset Password
            </Typography>
            <br />
            <Typography variant="body1" align="center" gutterBottom>
              Enter your email address and we&#39;ll email a link to reset your password
            </Typography>
            <TextField
              type="text"
              label="Email"
              variant="outlined"
              className={classes.input}
              value={confirmEmail || ''}
              onChange={(e) => handleEmailChange(e)}
              error={!(emailError === '')}
              helperText={emailError}
              fullWidth
            />
            <Collapse in={snackbarOpen}>
              <Alert severity="success">Password reset link sent</Alert>
            </Collapse>
            <br />
            <Button
              className={classes.button}
              onClick={() => {
                handlePasswordResetRequest(confirmEmail);
              }}
              variant="contained"
              color="primary"
            >
              Send Reset Link
            </Button>
            <Grid item align="center">
              <Button variant="text" color="primary" onClick={handleBackButton}>
                Back
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Container>
      <CustomizableSnackbar
        message="Password reset link sent"
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </Root>
  );
};

RequestPasswordReset.propTypes = {
  handlePasswordResetRequest: PropTypes.func.isRequired,
  confirmEmail: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  handleBackButton: PropTypes.func.isRequired,
  snackbarOpen: PropTypes.bool.isRequired,
  setSnackbarOpen: PropTypes.func.isRequired,
};

export default RequestPasswordReset;
