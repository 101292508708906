import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'DeniedComponent';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`
};

const StyledContainer = styled(Container)({
  [`& .${classes.gridContainer}`]: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '60vh',
  },
  [`& .${classes.paper}`]: {
    padding: '20px',
    width: '90%',
    maxWidth: '400px',
  },
});

const DeniedComponent = (props) => {
  const navigate = useNavigate();


  const { heading, fieldText, buttonHref, buttonText } = props;

  return (
    <StyledContainer maxWidth="sm">
      <Grid container className={classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid item md={12} align="center">
            <Typography variant="h5" align="center" gutterBottom>
              {heading}
            </Typography>
            <br />
            <Typography variant="body1" align="center">
              {fieldText}
            </Typography>
            <br />
            <Button variant="contained" color="primary" onClick={() => navigate(buttonHref)}>
              {buttonText}
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default DeniedComponent;
