import React from 'react';
import { TextField } from '@mui/material';
import { usePlacesWidget } from 'react-google-autocomplete';

const GoogleAddressAutocomplete = ({ handleChange, setIsUsingGoogleAddress, ...props }) => {
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [hasFocused, setHasFocused] = React.useState(false);

  const { ref } = usePlacesWidget({
    apiKey: import.meta.env.VITE_APP_FIREBASE_CONFIG_API_KEY,
    onPlaceSelected: (address) => {
      setSelectedAddress(address);
      const streetNumber = address?.address_components?.find((component) =>
        component.types.includes('street_number'),
      )?.long_name;
      const streetName = address?.address_components?.find((component) => component.types.includes('route'))?.long_name;
      const suburb = address?.address_components?.find((component) => component.types.includes('locality'))?.long_name;
      const state = address?.address_components?.find((component) =>
        component.types.includes('administrative_area_level_1'),
      )?.short_name;
      const postcode = address?.address_components?.find((component) =>
        component.types.includes('postal_code'),
      )?.long_name;
      handleChange(`${streetNumber} ${streetName}`, 'street address');
      handleChange(suburb, 'suburb');
      handleChange(state, 'state');
      handleChange(postcode, 'postcode');
      setIsUsingGoogleAddress(false);
    },
    options: {
      types: [],
      componentRestrictions: { country: 'au' },
    },
  });

  const resetAddress = () => {
    if (selectedAddress) return;
    setSelectedAddress(null);
    handleChange(null, 'street address');
    handleChange(null, 'suburb');
    handleChange(null, 'state');
    handleChange(null, 'postcode');
  };

  return (
    <TextField
      inputRef={ref}
      variant="outlined"
      label="Start Typing Address"
      onFocus={resetAddress}
      onBlur={() => setHasFocused(true)}
      error={hasFocused && !selectedAddress}
      fullWidth
      {...props}
    />
  );
};

export default GoogleAddressAutocomplete;
