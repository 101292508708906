/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import { initializeApp, getApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore, connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';

const recaptchaPublicKey = import.meta.env.VITE_APP_RECAPTCHA_PUBLIC_KEY;

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_CONFIG_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_CONFIG_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
};

// Initialize Firebase & Firebase services
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);
// To be used again once all of the areas with the undefined properties are fixed
// const db = getFirestore(firebaseApp);
// temporarily using this to lessen the noise in the sentry
const db = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true });
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp, 'australia-southeast1');

// Uncomment this for emulator
// const functions = getFunctions(getApp(), 'australia-southeast1');
// if (location.hostname === 'localhost') {
//   connectFirestoreEmulator(db, 'localhost', 8080);
//   connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
//   connectFunctionsEmulator(functions, 'localhost', 5001);
//   connectStorageEmulator(storage, 'localhost', 9199);
// }

// QUESTION: Is there a better way to do this?
if (window?.location?.hostname === 'localhost') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  window.__fbApp = firebaseApp;
  window.__fbAuth = auth;
  window.__firestoreDB = db;
}

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(recaptchaPublicKey),
  isTokenAutoRefreshEnabled: true,
});

export { firebaseApp, appCheck, analytics, db, auth, functions, storage };
