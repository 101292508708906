import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';

import MasterRouter from './components/navigation/master-router';
import ErrorFallback from './components/layout/error-fallback';

const SENTRY_ERROR_BOUNDARY_TAG_VALUE = 'ALL_ROUTES';

const renderErrorFallback = ({ error, eventId, componentStack, resetError }) => (
  <ErrorFallback error={error} eventId={eventId} componentStack={componentStack || ''} resetError={resetError} />
);

const ErrorHandler = () => (
  <SentryErrorBoundary
    fallback={renderErrorFallback}
    beforeCapture={(scope) => {
      scope.setTag('errorBoundary', SENTRY_ERROR_BOUNDARY_TAG_VALUE);
    }}
    onReset={() => {
      // NOTE: This feels like a hack, can we improve this by doing something different?
      // Why do we force a user back to the landing webflow home page? This redirects to candormedical.com
      window.location.assign('https://www.candor.org');
    }}
  >
    <BrowserRouter>
      <MasterRouter />
    </BrowserRouter>
  </SentryErrorBoundary>
);

export default ErrorHandler;
