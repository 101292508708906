import { addDoc, collection, doc, getDocs, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { db } from '../firebase-config';

export const handleDefaultText = (recipientRole) => {
  if (recipientRole === 'nurse') {
    return 'Automated Message: You can use this chat to message the practice nurse with any medical questions. Please note this is not a live chat and our team will respond to you as soon as they are able';
  }
  if (recipientRole === 'support') {
    return 'Automated Message: You can use this chat to message the Candor support team for technical support and help with your account';
  }
  if (recipientRole === 'pharmacist') {
    return 'Automated Message: You can use this chat to message this pharmacy with any questions relating to your treatment or your order';
  }
  if (recipientRole === 'doctor') {
    return 'Automated Message: You can use this chat to message your Candor medical practitioner. This chat will only be active while you and your practitioner decide on a treatment option. After this, medical questions can be asked in the practice nurse chat';
  }
  return '';
};

export const createConversation = async (participantDetails1, participantDetails2) => {
  try {
    const date = Date.now();
    const payload = {
      participant1: {
        id: participantDetails1.id,
        email: participantDetails1.email,
        name: participantDetails1.name,
        notifications: participantDetails1.notifications,
        role: participantDetails1.role,
      },
      participant2: {
        id: participantDetails2.id,
        email: participantDetails2.email,
        name: participantDetails2.name,
        notifications: participantDetails2.notifications,
        role: participantDetails2.role,
      },
      reference: `${participantDetails1.id}:${participantDetails2.id}`,
      updated: date,
      online: !(participantDetails2.role === 'nurse' || participantDetails2.role === 'doctor'),
    };

    await setDoc(doc(db, 'conversations', `${participantDetails1.id}:${participantDetails2.id}`), payload);
    await addDoc(collection(db, 'conversations', `${participantDetails1.id}:${participantDetails2.id}`, 'messages'), {
      from: 'candor',
      read: date,
      sent: date,
      text: handleDefaultText(participantDetails2.role),
    });
  } catch (error) {
    console.log(error);
  }
};
