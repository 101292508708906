import React, { useMemo, useState } from 'react';
import { NAME } from './constants';
import { PatientsContext } from './PatientsContext';

const PatientsProvider = ({ children }) => {
  const [patients, setPatients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState(NAME);
  const [selectedDoctor, setSelectedDoctor] = useState('');
  const [dobValue, setDobValue] = useState(null);

  const patientContextValue = useMemo(
    () => ({
      searchType,
      searchValue,
      setSearchValue,
      setSearchType,
      selectedDoctor,
      setSelectedDoctor,
      setPatients,
      patients,
      dobValue,
      setDobValue,
    }),
    [
      searchType,
      searchValue,
      setSearchValue,
      setSearchType,
      selectedDoctor,
      setSelectedDoctor,
      setPatients,
      patients,
      dobValue,
      setDobValue,
    ],
  );
  return <PatientsContext.Provider value={patientContextValue}>{children}</PatientsContext.Provider>;
};

export default PatientsProvider;
