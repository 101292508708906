/**
 * @typedef {Object} Constraints
 * @property {number} MIN - Minimum allowed value
 * @property {number} MAX - Maximum allowed value
 * @property {string} UNIT - Units of measurement in KG or CM
 */

/**
 * @typedef {Object} MeasurementConfig
 * @property {Constraints} WEIGHT - Weight constraints in kg
 * @property {Constraints} HEIGHT - Height constraints in cm
 */

/** @type {MeasurementConfig} */
export const MEASUREMENT_CONSTRAINTS = {
  WEIGHT: {
    MIN: 20, // kg - extremely underweight or medical condition
    MAX: 650, // kg - extremely overweight but possible
    UNIT: 'KG',
  },
  HEIGHT: {
    MIN: 50, // cm -  shortest verified adult human 54.6cm
    MAX: 272, // cm - tallest recorded adult human ~272cm
    UNIT: 'CM',
  },
};

const createRangeError = ({ type, min, max, unit }) => ({
  range: `${type} must be between ${min} - ${max} ${unit}`,
  tooLow: `${type} cannot be less than ${min} ${unit}`,
  tooHigh: `${type} cannot be more than ${max} ${unit}`,
});

export const MEASUREMENT_ERROR = {
  FORMAT: {
    EMPTY: 'This field is required',
    NOT_NUMERIC: 'Please enter whole numbers only',
    INVALID: 'Invalid measurement format',
  },
  weight: createRangeError({
    type: 'Weight',
    min: MEASUREMENT_CONSTRAINTS.WEIGHT.MIN,
    max: MEASUREMENT_CONSTRAINTS.WEIGHT.MAX,
    unit: MEASUREMENT_CONSTRAINTS.WEIGHT.UNIT,
  }),
  height: createRangeError({
    type: 'Height',
    min: MEASUREMENT_CONSTRAINTS.HEIGHT.MIN,
    max: MEASUREMENT_CONSTRAINTS.HEIGHT.MAX,
    unit: MEASUREMENT_CONSTRAINTS.HEIGHT.UNIT,
  }),
};

/**
 * Validates if string contains only digits
 * @param {string | number} value - Value to check
 * @returns {boolean}
 */
const isWholeNumber = (value) => /^\d*$/.test(`${value}`);

/**
 * Checks if value is within range
 * @param {Object} params
 * @param {number} params.value - Value to check
 * @param {number} params.min - Minimum allowed
 * @param {number} params.max - Maximum allowed
 * @returns {boolean}
 */
const isInRange = ({ value, min, max }) => value >= min && value <= max;

/**
 * Gets measurement constraints
 * @param {Object} params
 * @param {string} params.type - Measurement type (weight/height)
 * @returns {Constraints|null}
 */
const getConstraints = ({ type }) => MEASUREMENT_CONSTRAINTS[type.toUpperCase()] ?? null;

/**
 * Validates an Adult Humans measurement value to be within a physiologically valid range
 * @param {Object} params
 * @param {string|number} params.value - Value to validate
 * @param {string} params.measurementType - Measurement type (weight in kg or height in cm)
 * @returns {boolean}
 */
export const isValidMeasurement = ({ value, measurementType: type }) => {
  if (!value || !type || !isWholeNumber(value)) {
    return false;
  }

  const constraints = getConstraints({ type });

  if (!constraints) {
    return false;
  }

  return isInRange({
    value: Number(value),
    min: constraints.MIN,
    max: constraints.MAX,
  });
};
