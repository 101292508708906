import { doc, collection, writeBatch } from 'firebase/firestore';
import { captureException as SentryCaptureException } from '@sentry/react';

import { db } from '../firebase-config';
import { createConversation } from './create-conversations';
import { populatePatientSearchTokens } from '../components/patient-management/PatientsPage/patientSearchTokenQuery';

const NURSE_DEFAULT_CONVERSATION = {
  id: 'nurse',
  email: 'nurse@candor.org',
  name: 'Practice Nurse',
  notifications: 0,
  role: 'nurse',
};

const SUPPORT_DEFAULT_CONVERSATION = {
  id: 'support',
  email: 'support@candor.org',
  name: 'Candor Support',
  notifications: 0,
  role: 'support',
};

const DOCTOR_DEFAULT_CONVERSATION = {
  id: 'doctor',
  email: 'doctor@candor.org',
  name: 'Candor Medical Practitioner',
  notifications: 0,
  role: 'doctor',
};

// DEFAULT DOC for the patients/${userId}/general sub collection
const DEFUALT_PATIENT_GENERAL_INFORMATION_DOC = {
  'basic info': {
    'first form completed': false,
    'first treatment assigned': false,
  },
  'general health': {},
  condition: {},
  treatment: {},
  surgery: {},
  'checkups required': [],
  forms: {},
};

// Default doc created in /patients/ collection for userId
const DEFAULT_PATIENT_DOC = {
  acceptedAgreements: {},
  identification: 'unverified',
  email: '',
  marketingEmails: true,
  shipping: {},
  forms: {},
  // catalogueItems.[healthCategory]: [productId]
  catalogueItems: {},
  prescriptions: {},
  preferredPharmacy: {},
  doctor: '',
  // NOTE: reference is the userId/patientId it's redundant... or should be called id as it's the DocID
  reference: '',
};

const createPatientData = async ({
  userId = '',
  userEmail = '',
  formName = '',
  formInputs = undefined,
  index = -1,
}) => {
  const date = Date.now();
  const batch = writeBatch(db);

  const newUser = {
    ...DEFAULT_PATIENT_DOC,
    reference: userId,
    email: userEmail,
    forms: {
      ...(formName ? { [formName]: 'in progress' } : {}),
    },
    catalogueItems: {
      ...(formName ? { [formName]: [] } : {}),
    },
  };

  // This is the questionaire a Patient fills out when they check eligibility for treatment
  const newPatientIntakeFormData = {
    ...DEFUALT_PATIENT_GENERAL_INFORMATION_DOC,
    forms: {
      ...DEFUALT_PATIENT_GENERAL_INFORMATION_DOC.forms,
      ...(formName
        ? {
            [formName]: {
              step: (index || 0) + 1,
              started: date,
            },
          }
        : {}),
    },
  };

  try {
    batch.set(doc(db, 'patients', userId), newUser);
    // POTENTIAL BUG: Some user's don't seem to have backups which causes verifyBeforeUpdateEmail to fail
    // as we attempt to update a specific doc that doesn't exist.
    batch.set(doc(db, 'backups', userId), { email: userEmail }, { merge: true });
    batch.set(doc(db, 'patients', userId, 'general', 'information'), newPatientIntakeFormData);

    if (typeof formInputs === 'object') {
      batch.update(doc(db, 'patients', userId, 'general', 'information'), formInputs);
    }

    batch.set(doc(collection(db, 'patients', userId, 'activity')), {
      createdAt: date,
      generalData: true,
      author: 'System',
      text: 'Patient account created.',
    });

    await batch.commit();
    await populatePatientSearchTokens(userId);

    const newPatientConversation = {
      id: userId,
      email: userEmail,
      name: '',
      notifications: 1,
      role: 'patient',
    };

    createConversation(newPatientConversation, SUPPORT_DEFAULT_CONVERSATION);
    createConversation(newPatientConversation, NURSE_DEFAULT_CONVERSATION);
    createConversation(newPatientConversation, DOCTOR_DEFAULT_CONVERSATION);
  } catch (error) {
    SentryCaptureException(error, {
      extra: { issueIn: 'createPatientData', userId, userEmail, formName, formInputs, index },
    });
    // TODO: Fix tech debt -> Report to end user that something went wrong...
  }
};

export default createPatientData;
