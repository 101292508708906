import React from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import GlobalStyles from '@mui/material/GlobalStyles';
import ErrorHandler from './error-handler';
import { AuthProvider } from './auth-context';
import { UserDataProvider } from './UserDataContext';

const theme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
  },
  palette: {
    primary: {
      main: '#2aafbb',
      contrastText: '#fff',
    },
    secondary: {
      main: '#EB8382',
      contrastText: '#fff',
    },
    text: {
      primary: '#303F56',
    },
  },
});

const App = () => (
  <StyledEngineProvider injectFirst>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles
            styles={{
              '@keyframes mui-auto-fill': { from: { display: 'block' } },
              '@keyframes mui-auto-fill-cancel': { from: { display: 'block' } },
            }}
          />
          <AuthProvider>
            <UserDataProvider>
              <ErrorHandler />
            </UserDataProvider>
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  </StyledEngineProvider>
);

export default App;
