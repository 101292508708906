import React from 'react';
import { Grid, Typography } from '@mui/material';

const PessariesInfo = () => (
  <Grid item>
    <Typography variant="h6">Using medicinal cannabis suppositories</Typography>
    <br />
    <Typography variant="body1">
      Medicinal cannabis suppositories can provide localized relief and treatment effects for men and women with bowel
      disorders. Suppositories may be used intermittently as needed, or as a regular medication.
    </Typography>
    <br />
    <Typography variant="h6">How to use a suppository</Typography>
    <br />
    <Typography variant="body1">
      1. Empty bowels prior to use if needed.
      <br />
      2. Wash your hands thoroughly before handling the suppository.
      <br />
      3. Remove the suppository from its packaging.
      <br />
      4. Lie down on your side with your top knee bent
      <br />
      5. Insert the suppository into the rectum, past the sphincter (approx 3cm) using your index finger. Lubricant
      should be used to assist.
      <br />
      6. It is advisable to remain lying down for 10 minutes after inserting the suppository to aid absorption and
      prevent leakage
      <br />
      7. Wash your hands again after insertion.
      <br />
      <br />
      Possible irritation or discomfort may occur. If irritation occurs please discontinue use.
      <br />
      <br />
      Titration is important, especially for new patients. Dosing schedules differ for each patient and should be
      discussed with your doctor. Doses should be increased gradually over time and the effect fully assessed before
      continuing to increase. Note that consuming too much cannabis can result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default PessariesInfo;
