import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';

import routeLoader from './route-loader';
import WeightLossForm from '../form/standard-forms/weight-loss-form';
// prettier-ignore
const AuthLandingPageContainer = lazy(() => routeLoader(() => import('../login/auth-landing-pages/auth-landing-page-container')));
const RequestVerification = lazy(() => routeLoader(() => import('../login/auth-landing-pages/request-verification')));
const HairLossForm = lazy(() => routeLoader(() => import('../form/standard-forms/hair-loss-form')));
const SleepForm = lazy(() => routeLoader(() => import('../form/standard-forms/sleep-form')));
const ColdSoreForm = lazy(() => routeLoader(() => import('../form/standard-forms/cold-sores-form')));
const HerpesForm = lazy(() => routeLoader(() => import('../form/standard-forms/herpes-form')));
const ContraceptivePillForm = lazy(() => routeLoader(() => import('../form/standard-forms/contraceptive-pill-form')));
const PaymentSuccess = lazy(() => routeLoader(() => import('../landing-pages/payment-success')));
const AcneForm = lazy(() => routeLoader(() => import('../form/standard-forms/acne-form')));
const AgeingForm = lazy(() => routeLoader(() => import('../form/standard-forms/ageing-form')));
const AllergiesForm = lazy(() => routeLoader(() => import('../form/standard-forms/allergies-form')));
// prettier-ignore
const ErectileDysfunctionForm = lazy(() => routeLoader(() => import('../form/standard-forms/erectile-dysfunction-form')));
const MedicinalCannabisForm = lazy(() => routeLoader(() => import('../form/standard-forms/medicinal-cannabis-form')));
const Navbar = lazy(() => routeLoader(() => import('./navbar')));

const UnverifiedRouter = (props) => {
  const { handleLogout } = props;

  return (
    <Routes>
      <Route
        path="/medicinal-cannabis-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <MedicinalCannabisForm />
          </>
        }
      />
      <Route
        path="/hair-loss-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <HairLossForm />
          </>
        }
      />
      <Route
        path="/weight-loss-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <WeightLossForm />
          </>
        }
      />
      <Route
        path="/sleep-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <SleepForm />
          </>
        }
      />
      <Route
        path="/cold-sores-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <ColdSoreForm />
          </>
        }
      />
      <Route
        path="/herpes-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <HerpesForm />
          </>
        }
      />
      <Route
        path="/acne-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <AcneForm />
          </>
        }
      />
      <Route
        path="/ageing-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <AgeingForm />
          </>
        }
      />
      <Route
        path="/contraceptive-pill-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <ContraceptivePillForm />
          </>
        }
      />
      <Route
        path="/allergies-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <AllergiesForm />
          </>
        }
      />
      <Route
        path="/erectile-dysfunction-form"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <ErectileDysfunctionForm />
          </>
        }
      />
      <Route
        path="/payment-success"
        element={
          <>
            <Navbar handleLogout={handleLogout} />
            <PaymentSuccess />
          </>
        }
      />
      <Route path="/auth-landing-page" element={<AuthLandingPageContainer />} />
      <Route path="/request-verification" element={<RequestVerification />} />
      <Route path="/*" element={<RequestVerification />} />
    </Routes>
  );
};

UnverifiedRouter.propTypes = {
  handleLogout: PropTypes.func.isRequired,
};

export default UnverifiedRouter;
