import { FieldPath } from 'firebase/firestore';

export const createFieldPath = (key) => new FieldPath(...key.split('.'));

// NOTE: if input is a string, return a FieldPath object, used to delete an invalid field path in firebase
// if input is an object, return an array of FieldPath objects and their corresponding values
// used to sanitize the fields of the treatments tried and expandable form elements

// Example acceptable object input: { "acceptedAgreements.Phentermine/Topiramate": 1727943929925 }
// This will return an array of FieldPath objects and their corresponding values so you can spread them into a firestore update function
// Example output: [ FieldPath('acceptedAgreements.Phentermine/Topiramate'), 1727943929925 ]
export const processFieldData = (input) => {
  switch (typeof input) {
    case 'string':
      return createFieldPath(input);
    case 'object':
      if (input === null) {
        throw new Error('Invalid input type. Expected a non-null object.');
      }
      return Object.entries(input).flatMap(([key, value]) => {
        const fieldPath = createFieldPath(key);
        return [fieldPath, value];
      });
    default:
      throw new Error('Invalid input type. Expected a string or an object.');
  }
};
