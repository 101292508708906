function convertBirthdayText(birthday) {
  const birthdayArray = birthday.split('-');
  const birthdayDate = new Date(birthdayArray[0], birthdayArray[1] - 1, birthdayArray[2]);
  // Convert the date to the format dd/mm/yyyy
  const birthdayText = `${birthdayDate.getDate()}/${birthdayDate.getMonth() + 1}/${birthdayDate.getFullYear()}`;
  return birthdayText;
}

function convertMilisecondsToDate(miliseconds, format) {
  const date = new Date(Number(miliseconds));
  let [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
  year -= 2000;
  month += 1;
  if (month.toString().length === 1) month = `0${month}`;
  if (day.toString().length === 1) day = `0${day}`;
  let dateString = format === 'datebox' ? `20${year}-${month}-${day}` : `${day}/${month}/${year}`;
  if (format === 'minutes') {
    // Add hours and minutes to the end of dateString
    const hours = date.getHours();
    const minutes = date.getMinutes();
    dateString = `${dateString} - ${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
  }
  return dateString;
}

function enforceAgeLimits(birthday, upperLimit = 999) {
  const optimizedBirthday = birthday?.replace(/-|\./g, '/');
  // set date based on birthday at 01:00:00 hours GMT+0100 (CET)
  const myBirthday = new Date(optimizedBirthday);
  // set current day on 01:00:00 hours GMT+0100 (CET)
  const currentDate = `${new Date().toJSON().slice(0, 10)} 01:00:00`;
  // calculate age comparing current date and birthday
  const myAge = Math.floor((Date.now(currentDate) - myBirthday) / 31557600000);
  if (myAge < 18) {
    return 'under limit';
  }
  if (myAge >= upperLimit) {
    return 'over limit';
  }
  return 'within limit';
}

export { convertBirthdayText, convertMilisecondsToDate, enforceAgeLimits };
