import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { AuthContext } from '../../../../auth-context';
import HealthCardFormElementPresentation from '../presentation-elements/health-card-form-element-presentation';

const HealthCardFormElement = (props) => {
  const { isUpdateDetails, currentFormObject, setFormInputs, handleNext, formInputs, formName, index, patientId } =
    props;

  const { user, userType } = useContext(AuthContext);
  const [healthCardData, setHealthCardData] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [address1Error, setAddress1Error] = useState(false);
  const [address2Error, setAddress2Error] = useState(false);
  const [crnError, setCrnError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [validHealthCardFields, setValidHealthCareFields] = useState(false);

  const handleChange = (value, key) => {
    setHealthCardData((pd) => ({ ...pd, ...{ [key]: value } }));
  };
  const isNumeric = (value) => /^-?\d+$/.test(value);
  useEffect(() => {
    setHealthCardData((pd) => ({
      ...pd,
      ...{
        [`${'firstName'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.firstName`],
        [`${'lastName'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.lastName`],
        [`${'address1'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.address1`],
        [`${'address2'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.address2`],
        [`${'crn'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.crn`],
        [`${'startDate'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.startDate`],
        [`${'expiry'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.expiry`],
        [`${'using'}`]: true,
      },
    }));
  }, [currentFormObject, formInputs, formName]);

  useEffect(() => {
    if (!healthCardData) return;

    if (
      healthCardData.firstName !== undefined &&
      healthCardData.firstName.length > 0 &&
      healthCardData.firstName.trim().length <= 0
    ) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (
      healthCardData.lastName !== undefined &&
      healthCardData.lastName.length > 0 &&
      healthCardData.lastName.trim().length <= 0
    ) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (
      healthCardData.address1 !== undefined &&
      healthCardData.address1.length > 0 &&
      healthCardData.address1.trim().length <= 0
    ) {
      setAddress1Error(true);
    } else {
      setAddress1Error(false);
    }
    if (
      healthCardData.address2 !== undefined &&
      healthCardData.address2.length > 0 &&
      healthCardData.address2.trim().length <= 0
    ) {
      setAddress2Error(true);
    } else {
      setAddress2Error(false);
    }

    if (
      healthCardData.crn !== undefined &&
      healthCardData.crn !== '' &&
      !(
        healthCardData.crn.length > 9 &&
        isNumeric(healthCardData.crn.substring(0, 9)) === true &&
        healthCardData.crn.slice(-1).toLowerCase() !== healthCardData.crn.slice(-1).toUpperCase()
      )
    ) {
      setCrnError(true);
    } else {
      setCrnError(false);
    }
    if (moment(healthCardData.expiry) < moment() || healthCardData.expiry === 'Invalid date') {
      setExpiryError(true);
    } else {
      setExpiryError(false);
    }
    if (moment(healthCardData.startDate) > moment() || healthCardData.startDate === 'Invalid date') {
      setStartDateError(true);
    } else {
      setStartDateError(false);
    }

    // if all fields are valid
    if (
      healthCardData.firstName?.trim().length > 0 &&
      healthCardData.lastName?.trim().length > 0 &&
      healthCardData.address1?.trim().length > 0 &&
      healthCardData.address2?.trim().length > 0 &&
      healthCardData.crn?.trim().length > 0 &&
      !(
        healthCardData.crn !== '' &&
        !(
          healthCardData.crn.length > 9 &&
          isNumeric(healthCardData.crn.substring(0, 9)) === true &&
          healthCardData.crn.slice(-1).toLowerCase() !== healthCardData.crn.slice(-1).toUpperCase()
        )
      ) &&
      healthCardData.expiry &&
      healthCardData.startDate !== null &&
      healthCardData.startDate !== 'Invalid date' &&
      !(moment(healthCardData.expiry) < moment()) &&
      healthCardData.expiry &&
      healthCardData.expiry !== null &&
      healthCardData.expiry !== 'Invalid date' &&
      !(moment(healthCardData.startDate) > moment())
    ) {
      setValidHealthCareFields(true);
    } else {
      setValidHealthCareFields(false);
    }
  }, [healthCardData, formInputs]);

  const formatHealthCardDetails = (type) => {
    const healthCardDetailsObj = {};
    const cardType = type === 'withSpace' ? 'concession cards' : 'concessionCards';
    Object.entries(healthCardData || {}).forEach((entry) => {
      const [key, value] = entry;
      if (value !== undefined) {
        if (key === 'firstName' || key === 'lastName' || key === 'address1' || key === 'address2') {
          healthCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value.trim();
        } else if (key === 'crn') {
          healthCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value.replace(
            /\s+/g,
            '',
          );
        } else {
          healthCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value;
        }
      }
    });
    return healthCardDetailsObj;
  };

  const handleInput = () => {
    const dataUpdate = formatHealthCardDetails('withSpace');
    const formattedHealthCardData = formatHealthCardDetails();
    if (validHealthCardFields) {
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        updateDoc(doc(db, 'patients', userId), { ...formattedHealthCardData });
        updateDoc(doc(db, 'patients', userId, 'general', 'information'), { ...dataUpdate });
      }
      setFormInputs((pd) => ({ ...pd, ...dataUpdate }));
    }
    handleNext();
  };

  const handleDate = (date, type) => {
    if (!date) handleChange(null, type);
    else handleChange(moment(date).format('YYYY-MM-DD'), type);
  };
  return (
    <HealthCardFormElementPresentation
      isUpdateDetails={isUpdateDetails}
      currentFormObject={currentFormObject}
      handleInput={handleInput}
      handleChange={handleChange}
      healthCardData={healthCardData}
      handleDate={handleDate}
      address1Error={address1Error}
      address2Error={address2Error}
      crnError={crnError}
      expiryError={expiryError}
      setExpiryError={setExpiryError}
      validHealthCardFields={validHealthCardFields}
      firstNameError={firstNameError}
      lastNameError={lastNameError}
      setCrnError={setCrnError}
      startDateError={startDateError}
    />
  );
};

export default HealthCardFormElement;
