import React from 'react';
import { NAME } from './constants';

export const PatientsContext = React.createContext({
  searchType: NAME,
  searchValue: '',
  selectedDoctor: '',
  dobValue: null,
  setDobValue: () => {},
  setSearchValue: () => {},
  setSearchType: () => {},
  setSelectedDoctor: () => {},
  setPatients: () => {},
  patients: [],
});
