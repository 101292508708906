import React from 'react';
import { Grid, Typography } from '@mui/material';

const TeaInfo = () => (
  <Grid item>
    <Typography variant="h6">Using medicinal cannabis teas</Typography>
    <br />
    <Typography variant="body1">
      Each re-sealable pouch includes a measuring spoon. To prepare, steep 0.75g of cannabis tea (1 scoop) in 250ml of
      hot water for 10- 15 minutes, using a strainer or a device suitable for loose leaf tea. Patients can add hot water
      and reuse the leaves several times within the first few hours of brewing, as many cannabinoids remain in the
      herbal material. Each cup delivers about 5mg of acidic cannabinoids. For best results, use this gentle medicine
      for at least 2 weeks to assess its effectiveness.
      <br />
      <br />
      Cannabis tea can be used as required to manage acute symptoms of nausea or regularly to help with background
      symptoms of inflammation or immune dysregulation. If used as a regular medication it can be used 2-3 times daily.
      <br />
      <br />
      Cannabis tea can be used regularly by itself or as an adjunct to other cannabis based therapies. It is safe to use
      at high doses and is more effective if used consistently. Adding milk or other fats to the tea will result in
      higher amounts of THC being present in the solution.
      <br />
      <br />
      Titration is important, especially for new patients. Dosing schedules differ for each patient and should be
      discussed with your doctor. Doses should be increased gradually over time and the effect fully assessed before
      continuing to increase. Note that consuming too much cannabis can result in discomfort and should be avoided.
    </Typography>
    <br />
  </Grid>
);

export default TeaInfo;
