import { getCurrentScope as SentryGetCurrentScope } from '@sentry/react';

export const DEFAULT_SENTRY_USER_TYPE = 'UNKNOWN';

export const updateUserScopeInSentry = ({ userId = '', userType = DEFAULT_SENTRY_USER_TYPE } = {}) => {
  SentryGetCurrentScope().setUser(
    userId
      ? {
          id: userId,
          userType,
        }
      : null,
  );

  SentryGetCurrentScope().setTags({ userType });
};
