import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Grid, Container, Paper, Typography, Button, CircularProgress, Modal } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const PREFIX = 'ModalCardOptions';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  gridItem: `${PREFIX}-gridItem`,
  cancelButton: `${PREFIX}-cancelButton`,
  cancelButtonHolder: `${PREFIX}-cancelButtonHolder`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
};

const StyledModal = styled(Modal)(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 40,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    maxWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.gridItem}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
  },

  [`& .${classes.cancelButton}`]: {
    margin: '-35px -45px',
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  [`& .${classes.cancelButtonHolder}`]: {
    position: 'relative',
    height: 20,
  },

  [`& .${classes.buttonContainer}`]: {
    margin: '20px 0px 20px 0px',
  },

  [`& .${classes.button}`]: {
    width: '100%',
    height: '100%',
  },
}));

const ModalCardOptions = (props) => {
  const { text, subtext, isOpen, onClose, handleProceed, loadingComponent } = props;

  return (
    <StyledModal data-testid="root-modal-card-options" open={isOpen} onClose={onClose}>
      <Container>
        <Grid container className={classes.gridContainer}>
          <Paper className={classes.paper}>
            <Grid item className={classes.cancelButtonHolder}>
              <Button className={classes.cancelButton} onClick={onClose} disabled={!!loadingComponent}>
                <CancelRoundedIcon color="primary" />
              </Button>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.gridItem}>
                <Typography variant="h5" align="center" gutterBottom>
                  {text}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" align="center" gutterBottom>
                  {subtext}
                </Typography>
              </Grid>
              {loadingComponent && (
                <Grid item xs={12} className={classes.gridItem}>
                  <CircularProgress />
                </Grid>
              )}
              {!loadingComponent && (
                <Grid container spacing={2} className={classes.buttonContainer}>
                  <Grid item xs={6}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={handleProceed}>
                      OK
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="outlined" color="primary" className={classes.button} onClick={onClose}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </StyledModal>
  );
};

ModalCardOptions.propTypes = {
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleProceed: PropTypes.func.isRequired,
  loadingComponent: PropTypes.bool,
};

ModalCardOptions.defaultProps = {
  subtext: '',
  loadingComponent: false,
};

export default ModalCardOptions;
