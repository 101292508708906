import React from 'react';
import { Grid, Typography } from '@mui/material';

const FinasterideInformation = () => (
  <Grid item>
    <Typography variant="h6">About Finasteride</Typography>
    <br />
    <Typography variant="body1">
      Finasteride works by preventing the conversion of testosterone to dihydrotestosterone. Dihydrotestosterone is
      associated with a type of hair loss called androgenic alopecia. Androgenic alopecia is a form of hair loss that
      has a genetic component and results in different patterns of hair loss. Using Finasteride prevents hair loss and
      may help to regain previously lost hair.
    </Typography>
    <br />
    <Typography variant="h6">How to use</Typography>
    <br />
    <Typography variant="body1">
      Finasteride is a once daily medication. Each dose is 1mg. Continuous use of Finasteride is required to maintain
      hair. A response is usually seen in 3-4 months and maximum response is seen by 1-2 years. Generally not all hair
      lost is recovered and Finasteride typically works better to prevent hair loss than to regrow it. If minimal
      response is seen by 12 months, talk to your Candor GP and reconsider use of Finasteride.
    </Typography>
    <br />
    <Typography variant="h6">Side effects</Typography>
    <br />
    <Typography variant="body1">
      Finasteride is a commonly used medication and side effects are generally mild. However it is important to be aware
      of any potential adverse effects.
      <br />
      <br />
      Infrequent (0.1 - 1%)
      <br />
      - Impotence
      <br />
      - Decreased libido
      <br />
      - Decreased ejaculation volume
      <br />
      - Ejaculation disorders
      <br />
      <br />
      Rare (less than 0.1%)
      <br />
      - Breast tenderness/enlargement (gynaecomastia)
      <br />
      - Poor semen quality
      <br />
      - Infertility
      <br />
      - Depression
      <br />
      - Allergic reaction
      <br />
      <br />
      If you experience mild side effects please discuss these with your Candor GP. If you are concerned about them or
      experience any serious side effects please see your regular GP or present to the emergency department. Candor is
      unable to provide emergency medical advice. Please note further detailed information on Finasteride including
      usage and side effects will be provided in a medication safety information sheet with your prescription. It is
      important that you read this thoroughly before use.
    </Typography>
    <br />
    <Typography variant="h6">Precautions - Prostate Cancer</Typography>
    <br />
    <Typography variant="body1">
      There is some concern that using Finasteride makes it more difficult to detect prostate cancer. Finasteride
      reduces PSA (prostate specific antigen) concentration by an average of 42% after 48 weeks. It is unclear how long
      after stopping finasteride PSA returns to normal, however this is likely to be within 6 months. PSA is used as a
      marker for prostate cancer, therefore it is important to let your regular GP know you are taking this medication
      as it can influence your results.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Finasteride when pregnant?</Typography>
    <br />
    <Typography variant="body1">
      No. Finasteride should not be taken by pregnant women as it may cause fetal harm. Finasteride is not used for hair
      loss in women and Candor does not prescribe Finasteride to women. As a precaution it is recommended that pregnant
      women do not handle crushed or broken tablets although this is unlikely to result in enough absorption to cause
      harm.
    </Typography>
    <br />
    <Typography variant="h6">Can I use Finasteride when breastfeeding?</Typography>
    <br />
    <Typography variant="body1">
      Finasteride is not used for hair loss in women and Candor does not prescribe Finasteride to women. It is not known
      if Finasteride is excreted in breastmilk and is not recommended to be used.
    </Typography>
  </Grid>
);

export default FinasterideInformation;
