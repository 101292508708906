import { logEvent } from 'firebase/analytics';
import * as Sentry from '@sentry/react';

import { analytics as fireBaseAnalytics } from '../firebase-config';

export const logAnalyticsEvent = ({ eventName = '', eventParams }) => {
  if (!eventName || !eventParams) {
    throw new Error('[logAnalyticsEvent] - eventName and eventParams are required!');
  }
  // NOTE: Unsure if we should be logging this. Might lead to double logging if GTM picks up the event
  // from Google analytics as well as this one?
  // Push the event to Google Tag Manager
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: `${eventName}`,
    ...eventParams,
  });

  // Log the event to Google Analytics
  logEvent(fireBaseAnalytics, eventName, eventParams);
};

export const logPurchaseEvent = ({ userId = '', catalogId = '', value = 0, itemsPurchased = [] }) => {
  if (!userId || !catalogId || !value || !itemsPurchased.length) {
    Sentry.captureMessage(
      '[logPurchaseEvent] - Will not logPurchaseEvent - userId, catalogId, value and itemNames are required!',
      {
        level: 'warning',
        extra: { eventParams: { userId, catalogId, value, itemsPurchasedLength: itemsPurchased.length } },
      },
    );
    return;
  }

  const { totalPrice, items } = itemsPurchased.reduce(
    (all, { itemId = 'MISSING', itemName = 'MISSING', price = 0 }) => ({
      totalPrice: all.totalPrice + price,
      items: [
        ...all.items,
        {
          item_id: itemId,
          item_name: itemName,
          price,
        },
      ],
    }),
    { totalPrice: 0, items: [] },
  );

  if (totalPrice !== value) {
    Sentry.captureMessage('[logPurchaseEvent] - totalPrice !== value', {
      level: 'debug',
      extra: { eventParams: { userId, catalogId, value, totalPrice, items } },
    });
  }

  const purchaseParams = {
    transaction_id: `${userId}_${catalogId}`,
    value,
    currency: 'AUD',
    items,
  };

  logAnalyticsEvent({ eventName: 'purchase', eventParams: purchaseParams });
};

export const logSignUpEvent = () => {
  const signUpParams = { method: 'password' };

  logAnalyticsEvent({ eventName: 'sign_up', eventParams: signUpParams });
};
