import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { doc, updateDoc } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { AuthContext } from '../../../../auth-context';
import VeteranCardFormElementPresentation from '../presentation-elements/veteran-card-form-element-presentation';

const VeteranCardFormElement = (props) => {
  const { isUpdateDetails, currentFormObject, setFormInputs, handleNext, formInputs, formName, index, patientId } =
    props;

  const { user, userType } = useContext(AuthContext);
  const [veteranCardData, setVeteranCardData] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [fileNumberError, setFileNumberError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [validVeteranFields, setValidVeteranFields] = useState(false);

  const handleChange = (value, key) => {
    setVeteranCardData((pd) => ({ ...pd, ...{ [key]: value } }));
  };

  useEffect(() => {
    setVeteranCardData((pd) => ({
      ...pd,
      ...{
        [`${'firstName'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.firstName`],
        [`${'lastName'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.lastName`],
        [`${'fileNumber'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.fileNumber`],
        [`${'expiry'}`]: formInputs[`concession cards.${currentFormObject.concessionCardType}.expiry`],
        [`${'using'}`]: true,
      },
    }));
  }, [currentFormObject, formInputs, formName]);

  useEffect(() => {
    if (!veteranCardData) return;

    if (
      veteranCardData.firstName !== undefined &&
      veteranCardData.firstName.length > 0 &&
      veteranCardData.firstName.trim().length <= 0
    ) {
      setFirstNameError(true);
    } else {
      setFirstNameError(false);
    }
    if (
      veteranCardData.lastName !== undefined &&
      veteranCardData.lastName.length > 0 &&
      veteranCardData.lastName.trim().length <= 0
    ) {
      setLastNameError(true);
    } else {
      setLastNameError(false);
    }
    if (
      veteranCardData.fileNumber !== undefined &&
      veteranCardData.fileNumber.length > 0 &&
      veteranCardData.fileNumber.trim().length <= 0
    ) {
      setFileNumberError(true);
    } else {
      setFileNumberError(false);
    }
    if (
      moment(veteranCardData.expiry).format('YYYY-MM-01') < moment().format('YYYY-MM-01') ||
      veteranCardData.expiry === 'Invalid date'
    ) {
      setExpiryError(true);
    } else {
      setExpiryError(false);
    }

    // if all fields are valid
    if (
      veteranCardData.firstName?.trim().length > 0 &&
      veteranCardData.lastName?.trim().length > 0 &&
      veteranCardData.fileNumber?.trim().length > 0 &&
      !(veteranCardData.fileNumber.length < 8) &&
      veteranCardData.expiry &&
      veteranCardData.expiry !== null &&
      veteranCardData.expiry !== 'Invalid date' &&
      !(moment(veteranCardData.expiry).format('YYYY-MM-01') < moment().format('YYYY-MM-01'))
    ) {
      setValidVeteranFields(true);
    } else {
      setValidVeteranFields(false);
    }
  }, [veteranCardData, formInputs]);

  const formatVeteranCardDetails = (type) => {
    const veteranCardDetailsObj = {};
    const cardType = type === 'withSpace' ? 'concession cards' : 'concessionCards';
    Object.entries(veteranCardData || {}).forEach((entry) => {
      const [key, value] = entry;
      if (value !== undefined) {
        if (key === 'firstName' || key === 'lastName') {
          veteranCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value.trim();
        } else if (key === 'fileNumber') {
          veteranCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value.replace(
            /\s+/g,
            '',
          );
        } else {
          veteranCardDetailsObj[`${cardType}.${currentFormObject.concessionCardType}.${key}`] = value;
        }
      }
    });
    return veteranCardDetailsObj;
  };

  const handleInput = () => {
    const dataUpdate = formatVeteranCardDetails('withSpace');
    const fortmattedVeteranCardData = formatVeteranCardDetails();
    if (validVeteranFields) {
      if (user) {
        const userId = userType === 'patient' ? user.uid : patientId;
        updateDoc(doc(db, 'patients', userId), { ...fortmattedVeteranCardData });
        updateDoc(doc(db, 'patients', userId, 'general', 'information'), { ...dataUpdate });
      }
      setFormInputs((pd) => ({ ...pd, ...dataUpdate }));
    }
    handleNext();
  };

  const handleExpiryDate = (date) => {
    if (!date) handleChange(null, 'expiry');
    else handleChange(moment(date).format('YYYY-MM-01'), 'expiry');
  };
  return (
    <VeteranCardFormElementPresentation
      isUpdateDetails={isUpdateDetails}
      currentFormObject={currentFormObject}
      handleInput={handleInput}
      handleChange={handleChange}
      veteranCardData={veteranCardData}
      setFileNumberError={setFileNumberError}
      handleExpiryDate={handleExpiryDate}
      fileNumberError={fileNumberError}
      expiryError={expiryError}
      setExpiryError={setExpiryError}
      validVeteranFields={validVeteranFields}
      firstNameError={firstNameError}
      lastNameError={lastNameError}
    />
  );
};

export default VeteranCardFormElement;
