import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button, TextField } from '@mui/material';
import { sendPasswordResetEmail } from '@firebase/auth';
import { useNavigate } from 'react-router-dom';

import CustomizableSnackbar from '../../layout/snackbar';
import { auth } from '../../../firebase-config';
import { AuthContext } from '../../../auth-context';

const PREFIX = 'ResetPasswordLinkError';

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  logo: `${PREFIX}-logo`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(() => ({
  [`& .${classes.input}`]: {
    minHeight: '4rem',
    margin: '0.5rem 0',
  },

  [`& .${classes.button}`]: {
    margin: '0.25rem 0',
    alignContent: 'bottom',
  },

  [`& .${classes.logo}`]: {
    margin: '0.5rem 0',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.paper}`]: {
    display: 'grid',
    padding: '1.25rem',
    width: '90%',
    maxWidth: '512px',
  },
}));

const ResetPasswordLinkError = () => {
  const [accountEmail, setAccountEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [emailError, setEmailError] = useState('');
  const { user, setAccountDetailsPage } = useContext(AuthContext);
  const navigate = useNavigate();

  const handlePasswordResetRequest = () => {
    sendPasswordResetEmail(auth, accountEmail)
      .then(() => {
        setEmailError('');
        setAccountEmail('');
        setSnackbarOpen(true);
      })
      .catch(() => {
        setEmailError('Incorrect email');
      });
  };

  const handleEmailChange = (email) => {
    setAccountEmail(email);
    setEmailError('');
  };

  const redirectToSecurityDetails = () => {
    navigate('/account-details');
    setAccountDetailsPage('security');
  };

  return (
    <Root>
      <Container maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '70vh' }}
        >
          <Paper className={classes.paper}>
            <Typography variant="h5" align="center" gutterBottom>
              Reset Password Link Expired
            </Typography>
            {!user ? (
              <>
                <Typography variant="subtitle1" align="center" gutterBottom>
                  The link to reset your password has expired.
                </Typography>
                <Typography variant="body1" align="center">
                  To receive a new reset password link, please enter and confirm your email address.
                </Typography>
                <TextField
                  id="resetPasswordLinkErrorEmail"
                  type="text"
                  label="Email"
                  variant="outlined"
                  className={classes.input}
                  value={accountEmail || ''}
                  onChange={(e) => handleEmailChange(e.target.value)}
                  error={emailError !== ''}
                  helperText={emailError}
                />
                <Button
                  className={classes.button}
                  onClick={() => {
                    handlePasswordResetRequest(accountEmail);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Send Reset Link
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1" align="center" gutterBottom>
                  You can reset your password from the security page.
                </Typography>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={redirectToSecurityDetails}
                >
                  Security Page
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Container>
      <CustomizableSnackbar
        message="Password reset link sent"
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
      />
    </Root>
  );
};

export default ResetPasswordLinkError;
