const scriptPriceTranslator = {
  'medicinal cannabis': 'price_1LBH0jJKbdNtmYd0eL5OnJ77',
  herpes: 'price_1LBFxXJKbdNtmYd0IW1NSCqF',
  'cold sores': 'price_1LBFplJKbdNtmYd0PpmDZ7Ia',
  sleep: 'price_1LBFxlJKbdNtmYd0qKaymV10',
  acne: 'price_1LBDBIJKbdNtmYd0VPLqLYg3',
  // FIX-ME: This should be temporary hack, should add the catalogue in firestore
  // Will screw up analytics and other things when purchasing in the future
  skincare: 'price_1LBDBIJKbdNtmYd0VPLqLYg3',
  ageing: 'price_1LBFoWJKbdNtmYd0Zgm0yNGR',
  allergies: 'price_1LBFvjJKbdNtmYd0FT8DiWvJ',
  'erectile dysfunction': 'price_1LBFw2JKbdNtmYd0w0fXqhax',
  'contraceptive pill': 'price_1LBFtLJKbdNtmYd0NZmYWdex',
  // TODO: Add nicotine script admin fee to the catalogue in firestore
  // nicotine: 'MISSING_ADD_ME_INTO_DATABASE_PLEASE',
};

const consultPriceTranslator = {
  'hair loss': 'price_1KQUZgJKbdNtmYd0NQogHuLX',
  'cold sores': 'price_1KQUacJKbdNtmYd0gOHOgYf6',
  herpes: 'price_1KQUZUJKbdNtmYd0w15h8SDk',
  sleep: 'price_1KQUOJJKbdNtmYd0uRuqkyao',
  acne: 'price_1KRrJgJKbdNtmYd0rynmQgqf',
  // FIX-ME: skincare points to acne. This will screw up analytics
  // and reports when purchasing in the future. Create it's own catalogue in firestore or delete
  skincare: 'price_1KRrJgJKbdNtmYd0rynmQgqf',
  ageing: 'price_1KQUavJKbdNtmYd0z2abIpix',
  allergies: 'price_1KQUamJKbdNtmYd0bZaubz2d',
  'contraceptive pill': 'price_1KgmCAJKbdNtmYd0qXQ1thkY',
  'erectile dysfunction': 'price_1KQUZvJKbdNtmYd0ALCUtsyX',
  'medicinal cannabis': 'price_1LRxYwJKbdNtmYd05AqZn0uz',
  checkup: 'price_1LCuihJKbdNtmYd0iPRikLDf',
  'weight loss': 'price_g4k9z3BpQHFnDMcEGAik',
};

const bookingPriceTranslator = {
  'contraceptive pill': 'price_zS5jI8QdVTrAjlaKe2Pg',
  'erectile dysfunction': 'price_XkfcLmBnAvTWUfUhRqiq',
  'medicinal cannabis': 'price_iqANjnY9VxNjTgqT6rNk',
  'hair loss': 'price_7JJPE61q6QNM684CVnT5',
  'cold sores': 'price_yLHHxjUra6e1aPQIQVll',
  herpes: 'price_tAaquCgC5qufH7SEboJo',
  sleep: 'price_XZMCW5rE6XFNh7YzwNwk',
  acne: 'price_UXt4DLYMplIIFTUqWgOd',
  skincare: 'price_I7eBBRnykScVeikORXhH',
  ageing: 'price_VqtMxe8ytAK4joxMOnGV',
  allergies: 'price_offnALfTtTfEE29tqfig',
  nicotine: 'price_S1Hr8EJmiDM3cZEvWKsR',
  checkup: 'price_soIohkT2joYnNoHqaT5x',
};

export { scriptPriceTranslator, consultPriceTranslator, bookingPriceTranslator };
