import { useContext, useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db, auth } from '../../firebase-config';
import { AuthContext } from '../../auth-context';
import { updateEmailInDB } from '../../utils/authHelpers';

// TODO: FUTURE: This is temp until we enforce this for staging as well.
const PROD_PROJECT_ID = 'candor-react';
// FUTURE: Migrate our db away from using these 3 fields, move to a single field for
// userType/accountType/userRole and not different values and status e.g "PENDING", "APPROVED", "SUSPENDED"
const USER_ACCOUNTS_NEEDING_APPROVAL = ['isSupplier', 'isDoctor', 'isPharmacy'];
const USER_TYPES_WITH_MANDATORY_MFA = ['doctor', 'support', 'nurse', 'director'];
/**
 * Checks if 2FA/MFA is mandatory for the given user type and current user status.
 * @param {Object} params - The parameters object.
 * @param {string} params.userType - The type of the user.
 * @param {Object} params.currentAuthUser - The current user object from Firebase Auth.
 * @param {string} params.projectId - The Firebase project ID from environment variables.
 * @returns {boolean} True if 2FA is mandatory, false otherwise.
 */
const is2faMandatory = ({ userType, currentAuthUser, projectId }) =>
  !!userType &&
  USER_TYPES_WITH_MANDATORY_MFA.includes(userType) &&
  !currentAuthUser?.reloadUserInfo?.mfaInfo &&
  projectId === PROD_PROJECT_ID;

/**
 * Checks if the user's account is currently pending approval.
 * This data should come from the 'patients' collection.
 * Only specific users can be in a pending approval state like Doctors, Pharamacists, or Suppliers
 * TODO: QUESTION: Currently unsure why we're checking for offline??? Someone please explain/update this comment.
 * FUTURE: FIX-ME: type "offline" should be moved to constants once it's clear what it's purpose is.
 * @param {Object} params - The parameters object.
 * @param {Object} params.userData - The user data in firestore to check.
 * @param {string[]} params.fieldNamesToCheck - List of the fieldNames to check for user account roles that require approval.
 * @returns {boolean} True if the user account is pending approval, false otherwise.
 */
const isAccountPendingApproval = ({ userData = {}, fieldNamesToCheck = USER_ACCOUNTS_NEEDING_APPROVAL }) =>
  fieldNamesToCheck.some((role) => !!userData?.[role]) && userData?.type === 'offline';

export const useAccountStatus = () => {
  const navigate = useNavigate();
  const { user, userType, isAuthLoading, emailVerificationDetails, setEmailVerificationDetails } =
    useContext(AuthContext);
  const [isAccountPending, setIsAccountPending] = useState(false);
  const [is2faRequired, setIs2faRequired] = useState(false);
  const { isEmailVerified } = emailVerificationDetails;

  // TODO: FIXME: There are bugs here, look at the effects dependencies, this needs to be cleaned up
  useEffect(() => {
    const fetchAndEvaluateUserStatus = async () => {
      if (!user) {
        return;
      }

      try {
        const userDocRef = doc(db, 'patients', user?.uid);
        const userDoc = await getDoc(userDocRef);
        const userData = userDoc.data();
        if (!userData) {
          return;
        }

        // This is the solution for unauthenticated email recovery and unauthenticated email change since they can't update the userDoc email when they are not logged in
        // NOTE: this will also sync the auth user email with the firestore user patient email
        if (user?.email !== userData.email) {
          await updateEmailInDB({
            existingEmail: userData.email,
            newEmail: user?.email,
            operation: isAccountPending ? 'VERIFY_AND_CHANGE_EMAIL' : 'RECOVER_EMAIL',
            user,
            userType,
            setEmailVerificationDetails,
            navigate,
          });
        }
        setIsAccountPending(isAccountPendingApproval({ userData }));
        setIs2faRequired(
          is2faMandatory({
            userType,
            currentAuthUser: user,
            projectId: import.meta.env.VITE_APP_FIREBASE_CONFIG_PROJECT_ID,
          }),
        );
      } catch (error) {
        sentryCaptureException(error, {
          extra: { is2faRequired, isAuthLoading, isEmailVerified, isAccountPending },
        });
      }
    };
    fetchAndEvaluateUserStatus();
  }, [user, userType]);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (user.emailVerified && isAccountPending) {
      navigate('/pending-account');
    }
  }, [user, isAccountPending, navigate]);

  const handleLogout = () => {
    signOut(auth).catch((error) => {
      sentryCaptureException(error, { extra: { issueIn: 'handleLogout', userType, isAccountPending } });
    });
  };

  return {
    user,
    isAuthLoading,
    isAccountPending,
    is2faRequired,
    handleLogout,
    isEmailVerified,
  };
};
